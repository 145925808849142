import React, { Fragment } from "react";
import { NumberWithThousandSeparator, LeaseAmountPerMonthFormate } from "../../helper/project_helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Col, Row } from 'react-bootstrap';
import { withRouter, Link } from "react-router-dom";
import { isEmpty, handleDragStart, handleContextMenu, createSeoFriendlyUrl } from "../../helper/project_helper";
import CheckGreen from "../../components/WebComponent/CheckGreen";
import RedClose from "../../components/WebComponent/RedClose";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LPLoader from "../../components/SkeletonLoder/LPLoader";
import 'react-lazy-load-image-component/src/effects/blur.css';
import CryptoJS from 'crypto-js';

import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";

const NewIncentiveFinderByRequirements = ({
  incentives = [],
  loading = true,
  notFound = false,
  disabled = false,
  onSavingClick,
  expandedRows,
  history,
  compareVehicles,
  handleCompareCheck,
  getCompareValue,
  valueCompareCheck,
  onChangeCompareCheck,
  tooltipText,
  quizFilter,
  quizFilterState,
  shortBy,
  onChangeShort,
  onChangeEligible,
  eligible,
  is0Apr,
  onChangeIs0Apr,
  stateEVIncentiveFinderFilters,
  msg
}) => {

  const filters = stateEVIncentiveFinderFilters?.data;
  console.log('filters', filters);
  

  const handleRowClick = (rowId) => {
    onSavingClick((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return (
    <Fragment>
      <div className="filter-sicky">
        <span className="pt-4 pb-2 d-flex align-items-center font-w4 font-color" style={{ background: "#fcfcfc" }}>Already know which vehicle you want? Search for the full list of incentives with&nbsp;
          <Link className="text-dark btn-incentive-finder font-wx px-0 green-hover-text" to={'/incentivefinder'}>Siffra Incentive Finder
          </Link>.</span>
        <div className="d-flex align-items-center justify-content-between" style={{ background: "#fcfcfc" }}>
          <div className="d-flex">
            {filters?.is_fed_tex_credit_available === true &&
              <Form className="landing-check">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  // label="Only show vehicles eligible for Federal Tax Credit"
                  label="Federal Tax Credit"
                  className="form-check w-100  d-flex align-items-center toggle-switch"
                  onChange={(x) => onChangeEligible(x.target.checked)}
                  checked={eligible}
                  disabled={loading}
                />
              </Form>
            }
            {filters?.zero_apr === true &&
            <Form className={"landing-check " + (filters?.is_fed_tex_credit_available === true ? "ms-5" : "")}>
              <Form.Check
                type="switch"
                id="custom-switch"
                // label="Only show vehicles eligible for Federal Tax Credit"
                label="0% APR"
                className="form-check w-100 d-flex align-items-center toggle-switch"
                onChange={(x) => onChangeIs0Apr(x.target.checked)}
                checked={is0Apr}
                disabled={loading}
              />
            </Form>}
          </div>
          <div className="mb-2 sort-by">
            <label className="mb-1 font-midium font-w text-detaile">Sort By</label>
            <select
              className={"form-select short-select filter_select overflow-hidden border-w " + (isEmpty(shortBy) ? 'blank-select-color' : 'filter_select_bold')}
              value={shortBy || ''}
              id="short-select"
              style={{ width: "232px" }}
              onChange={(x) => onChangeShort(x.target.value)}
            >
              <option value="1">Most Affordable</option>
              <option value="2">Most Sold</option>
              <option value="3">Range</option>
              <option value="4">Base Price</option>
              <option value="5">Potential Savings</option>
              {/* <option value="6">Potential Savings - Low to High</option> */}
            </select>
          </div>
        </div>
        <div className="NewThead">
          <div className="table-gray d-flex align-items-center table-header px-3">
            {/* <td className="py-2 sticky-table-header width-75">Make</td> */}
            <div style={{ width: "23%" }}
              className={
                incentives
                  ? "py-4 table-header header-width"
                  : "fix-width py-2 table-header"
              }
            >
              Vehicle
            </div>
            <div style={{ width: "14%" }} className="py-4 table-header ">Model Year</div>
            <div style={{ width: "14%" }} className="py-4 table-header">
              Segment
            </div>
            <div style={{ width: "14%" }} className="py-4 table-header">
              Range
            </div>
            <div style={{ width: "14%" }} className="py-4 table-header d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span>Base Price</span>
                {!isEmpty(tooltipText?.[1]) &&
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip className="custom-tooltip base-price-tooltip mt-2">
                        <div className="tc-text">
                          {tooltipText?.[1]}
                        </div>
                      </Tooltip>
                    }
                  >
                    <i className="d-flex align-items-center justify-content-center ms-1 svg-icon">
                      <svg className="overflow-visible" width="12" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 11.25C5.10761 11.25 3.77226 10.6969 2.78769 9.71231C1.80312 8.72775 1.25 7.39239 1.25 6C1.25 4.60761 1.80312 3.27226 2.78769 2.28769C3.77226 1.30312 5.10761 0.75 6.5 0.75C7.89239 0.75 9.22775 1.30312 10.2123 2.28769C11.1969 3.27226 11.75 4.60761 11.75 6C11.75 7.39239 11.1969 8.72775 10.2123 9.71231C9.22775 10.6969 7.89239 11.25 6.5 11.25ZM6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0C4.9087 0 3.38258 0.632141 2.25736 1.75736C1.13214 2.88258 0.5 4.4087 0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C3.38258 11.3679 4.9087 12 6.5 12Z" fill="white" />
                        <path d="M7.1975 4.941L5.48 5.15625L5.4185 5.44125L5.756 5.5035C5.9765 5.556 6.02 5.6355 5.972 5.85525L5.4185 8.45625C5.273 9.129 5.49725 9.4455 6.0245 9.4455C6.43325 9.4455 6.908 9.2565 7.12325 8.997L7.18925 8.685C7.03925 8.817 6.82025 8.8695 6.67475 8.8695C6.4685 8.8695 6.3935 8.72475 6.44675 8.46975L7.1975 4.941ZM7.25 3.375C7.25 3.57391 7.17098 3.76468 7.03033 3.90533C6.88968 4.04598 6.69891 4.125 6.5 4.125C6.30109 4.125 6.11032 4.04598 5.96967 3.90533C5.82902 3.76468 5.75 3.57391 5.75 3.375C5.75 3.17609 5.82902 2.98532 5.96967 2.84467C6.11032 2.70402 6.30109 2.625 6.5 2.625C6.69891 2.625 6.88968 2.70402 7.03033 2.84467C7.17098 2.98532 7.25 3.17609 7.25 3.375Z" fill="white" />
                      </svg>
                    </i>

                  </OverlayTrigger>
                }
              </div>
            </div>
            <div style={{ width: "17%" }} className="py-4 table-header text-center requirement_columns">
              <div className="d-flex align-items-center">
                <span style={{ lineHeight: "1.3", }}>
                  Federal Tax Credit
                </span>
                {!isEmpty(tooltipText?.[3]) &&
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip className="custom-tooltip base-price-tooltip mt-2">
                        <div className="tc-text">
                          {tooltipText?.[3]}
                        </div>
                      </Tooltip>
                    }
                  >
                    <i className="d-flex align-items-center justify-content-center svg-icon ms-1">
                      <svg className="overflow-visible" width="12" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 11.25C5.10761 11.25 3.77226 10.6969 2.78769 9.71231C1.80312 8.72775 1.25 7.39239 1.25 6C1.25 4.60761 1.80312 3.27226 2.78769 2.28769C3.77226 1.30312 5.10761 0.75 6.5 0.75C7.89239 0.75 9.22775 1.30312 10.2123 2.28769C11.1969 3.27226 11.75 4.60761 11.75 6C11.75 7.39239 11.1969 8.72775 10.2123 9.71231C9.22775 10.6969 7.89239 11.25 6.5 11.25ZM6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0C4.9087 0 3.38258 0.632141 2.25736 1.75736C1.13214 2.88258 0.5 4.4087 0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C3.38258 11.3679 4.9087 12 6.5 12Z" fill="white" />
                        <path d="M7.1975 4.941L5.48 5.15625L5.4185 5.44125L5.756 5.5035C5.9765 5.556 6.02 5.6355 5.972 5.85525L5.4185 8.45625C5.273 9.129 5.49725 9.4455 6.0245 9.4455C6.43325 9.4455 6.908 9.2565 7.12325 8.997L7.18925 8.685C7.03925 8.817 6.82025 8.8695 6.67475 8.8695C6.4685 8.8695 6.3935 8.72475 6.44675 8.46975L7.1975 4.941ZM7.25 3.375C7.25 3.57391 7.17098 3.76468 7.03033 3.90533C6.88968 4.04598 6.69891 4.125 6.5 4.125C6.30109 4.125 6.11032 4.04598 5.96967 3.90533C5.82902 3.76468 5.75 3.57391 5.75 3.375C5.75 3.17609 5.82902 2.98532 5.96967 2.84467C6.11032 2.70402 6.30109 2.625 6.5 2.625C6.69891 2.625 6.88968 2.70402 7.03033 2.84467C7.17098 2.98532 7.25 3.17609 7.25 3.375Z" fill="white" />
                      </svg>
                    </i>
                  </OverlayTrigger>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? <LPLoader /> :
        incentives?.length > 0 ?
          <div className={"table-responsive height-100 overflow-x"}>
            <div className="table-striped w-100 table-hover fixed-table">
              <div className="NewTbody">
                {!loading && incentives && (
                  incentives?.map((item_val, item_key) => {
                    if ("maker" in item_val) {
                      return (
                        <div className="heading-tr" key={item_key}>
                          <div className="py-2 pe-4 ps-1 d-flex align-items-center font-large font-color font-wxl" style={{ height: "42px" }}>
                            <div className="" style={{ marginRight: "16px" }}>
                              <img
                                className="manufacturer-logo-link"
                                src={process.env.REACT_APP_VEHICLE_LOGO_BASE_URL + item_val?.manufacturer_logo_link}
                                // alt={item_val?.maker}
                                alt=""
                                onContextMenu={handleContextMenu}
                                onDragStart={handleDragStart}
                              />
                            </div>
                            {item_val?.maker}{" "}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <Fragment key={item_key}>
                          <div className="bottom-border-2x">
                            <div className={"d-flex align-items-center justify-content-between main-animation"} style={{ background: '#fff', padding: "12px 0px 12px 0", overflow: "visible" }}>
                              <div className="px-4"><span className="font-large font-w font-color cursor-pointer green-hover-text" onClick={() =>
                                handleRowClick(
                                  `hidden_model_row_${item_key}_${item_val?.model}_${item_val?.type}`
                                )
                              }>{item_val?.model}</span></div>
                              <div className="py-2 px-4">
                                {/* {item_val?.segment} */}
                                {item_val?.seating !== null && (
                                  <Fragment>
                                    {" "}
                                    {expandedRows[`hidden_model_row_${item_key}_${item_val?.model}_${item_val?.type}`] ? (
                                      <FontAwesomeIcon
                                        icon={faChevronUp}
                                        onClick={() =>
                                          handleRowClick(
                                            `hidden_model_row_${item_key}_${item_val?.model}_${item_val?.type}`
                                          )
                                        }
                                        className="cursor-pointer"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faChevronDown}
                                        onClick={() =>
                                          handleRowClick(
                                            `hidden_model_row_${item_key}_${item_val?.model}_${item_val?.type}`
                                          )
                                        }
                                        className="cursor-pointer"
                                      />
                                    )}
                                  </Fragment>
                                )}
                              </div>
                            </div>

                            {/* seating */}
                            {/* {expandedRows[`hidden_model_row_${item_key}_${item_val?.model}_${item_val?.type}`] && */}
                            <div className={expandedRows[`hidden_model_row_${item_key}_${item_val?.model}_${item_val?.type}`] ? "" : "d-none"}>

                              <MultipleTrim item_val={item_val?.trim_list} expandedRows={expandedRows} onExpCol={(trimKey) =>
                                handleRowClick(trimKey)
                              } history={history}
                                handleCompareCheck={handleCompareCheck}
                                compareVehicles={compareVehicles}
                                quizFilter={quizFilter}
                                valueCompareCheck={valueCompareCheck}
                                onChangeCompareCheck={onChangeCompareCheck}
                                getCompareValue={getCompareValue}
                                tooltipText={tooltipText}
                                quizFilterState={quizFilterState}

                              />
                            </div>
                            {/* } */}

                          </div>
                        </Fragment>
                      );
                    }
                  })
                )}
              </div>
            </div>
          </div> :
          !loading &&
          <div className={"bottom-border d-flex align-items-center justify-content-between main-animation"} style={{ background: '#fff', height: "64px", overflow: "visible" }}>
            <div className="px-4"><span className="font-large font-w warning-msg-red">{msg}</span></div>
          </div>}
    </Fragment>
  );
};

const MultipleTrim = ({
  item_val,
  expandedRows,
  onExpCol,
  history,
  handleCompareCheck,
  compareVehicles,
  getCompareValue,
  quizFilter,
  valueCompareCheck,
  onChangeCompareCheck,
  tooltipText,
  quizFilterState

}) => {
  return (
    item_val?.map((trim_item_val, trim_item_key) => {
      return (
        <Fragment key={trim_item_key}>
          <div >
            <div className="ps-5 d-flex align-items-center justify-content-between pt-3" style={{ background: "#fff" }}>
              <div className="d-flex">
                <p className="m-0 font-midium font-w cursor-pointer green-hover-text me-3" onClick={() => {

                  if (expandedRows[`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`]) {
                    history.push('/detail/' + CryptoJS.MD5(trim_item_val?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(trim_item_val?.make) + "/" + createSeoFriendlyUrl(trim_item_val?.model) + "/" + trim_item_val?.model_year + "/" + createSeoFriendlyUrl(trim_item_val?.trim) + "?type=" + trim_item_val?.type.toLowerCase() + (trim_item_val?.type.toLowerCase() === 'lease' ? `&term=${trim_item_val?.finance_term}&mileage=${trim_item_val?.apr}&down=${trim_item_val?.down_payment}` : `&term=${trim_item_val?.finance_term}&down=${trim_item_val?.down_payment}`), true);
                  } else {
                    onExpCol(`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`)
                  }
                }
                }>{trim_item_val?.trim.trim() !== '' ? trim_item_val?.trim : trim_item_val?.model}</p>
                {trim_item_val?.is_new_launch &&
                  <label className="landing-lable">New</label>}
              </div>
              <Link className="btn-learn-more font-small btn-pdd-set text-decoration-none d-flex align-items-center me-3" to={'/detail/' + CryptoJS.MD5(trim_item_val?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(trim_item_val?.make) + "/" + createSeoFriendlyUrl(trim_item_val?.model) + "/" + trim_item_val?.model_year + "/" + createSeoFriendlyUrl(trim_item_val?.trim) + "?type=" + trim_item_val?.type.toLowerCase() + (trim_item_val?.type.toLowerCase() === 'lease' ? `&term=${trim_item_val?.finance_term}&mileage=${trim_item_val?.apr}&down=${trim_item_val?.down_payment}` : `&term=${trim_item_val?.finance_term}&down=${trim_item_val?.down_payment}`)}>
                Vehicle Detail Page
                <svg className="ms-1" width="15px" height="15px" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
              </Link>
            </div>
            <div className={"main-animation pt-1 pb-3 d-flex align-items-center " + (!expandedRows[`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`] && "")} style={{ background: '#fff' }} >

              <div className="ps-5 v-model-req" style={{ width: "24%" }}>
                <div>
                  {/* {trim_item_val.model} */}

                </div>
                <Form>
                  <div className="">
                    <Form.Group className="form-checkbox d-flex align-items-center">
                      <Form.Label className="car-lease m-0 me-2 font-small">Compare</Form.Label>
                      <Form.Check
                        value={`${trim_item_val?.vehicle_id}|${trim_item_val?.make}|${trim_item_val?.model}|${trim_item_val?.model_year}|${trim_item_val?.trim}|${trim_item_val?.type}`}
                        checked={getCompareValue(`${trim_item_val?.vehicle_id}|${trim_item_val?.type}`)}
                        onChange={handleCompareCheck}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </div>
              <div className="d-flex align-items-end fill-width">
                <div className="font-midium font-color font-wxl Model-Year-req" style={{ width: "15%" }}>
                  {trim_item_val?.model_year}
                </div>
                <div className="font-midium font-color font-wxl Segment-req" style={{ width: "15%" }}>
                  {trim_item_val?.segment_name}
                  {trim_item_val?.seating !== null && (
                    <Fragment>
                      {" "}
                      <span> </span>
                    </Fragment>
                  )}
                </div>
                <div className="nonewrep font-midium font-color font-wxl renge-req" style={{ width: "15%" }}>
                  {trim_item_val.range_low === 0 && trim_item_val.range_high === 0
                    ? "TBA"
                    : trim_item_val.range_low === trim_item_val.range_high
                      ? trim_item_val.range_high
                      : trim_item_val.range_low + "-" + trim_item_val.range_high}
                  &nbsp;{trim_item_val.range_low !== 0 && trim_item_val.range_high !== 0 && "mi"}
                </div>
                <div className="font-midium font-color font-wxl base-req" style={{ width: "15%" }}>
                  $
                  {trim_item_val.base_msrp
                    ? NumberWithThousandSeparator(trim_item_val.base_msrp)
                    : 0}
                </div>
                <div className="Federal-req" style={{ minWidth: "17%" }}>
                  {trim_item_val?.fed_tax_credit_amount > 0 ? (
                    // Inline checkGreen SVG
                    <CheckGreen height={32} width={32} />
                  ) : (
                    // Inline closeDanger SVG
                    <RedClose />
                  )}
                </div>
                <div className="text-center">
                  <div className="d-flex align-items-center justify-content-center">

                    {expandedRows[`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`] ?
                      (<i className="ms-1 mt-1" onClick={() =>
                        onExpCol(`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`)
                      }><svg className="minus cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="#1E1E1F" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-6 10v2h12v-2z" /></svg></i>) :

                      (<i className="ms-1 mt-1" onClick={() =>
                        onExpCol(`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`)
                      }><svg className="plus cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="#1E1E1F" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-1 5v5h-5v2h5v5h2v-5h5v-2h-5v-5z" /></svg></i>)
                    }

                  </div>
                </div>
              </div>
            </div>
            {/* {expandedRows[`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`] && ( */}
            <div className={"d-flex data-bg main-animation " + (expandedRows[`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`] ? "" : "d-none")}>
              <div rowSpan={2} className="py-2 nonewrep model-td text-center" style={{ width: "31%" }}>
                <div className="evf-img">
                  {/* <LazyLoadImage
                        alt=""
                        effect="opacity"
                        src={process.env.REACT_APP_SMALL_VEHICLE_BASE_URL + trim_item_val?.vehicle_image_link?.replace(/\+/g, '%2B')}
                      /> */}
                  <img
                    alt=""
                    src={process.env.REACT_APP_SMALL_VEHICLE_BASE_URL + trim_item_val?.vehicle_image_link?.replace(/\+/g, '%2B')}
                  />

                </div>
              </div>
              <div className="width-fill ">
                <Row>
                  <Col lg={5}>
                    <div className={""}>
                      <div>
                        <div className="py-2 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <p className="car-postential font-wxl font-large">
                              {trim_item_val?.type === 'finance' &&
                                `Financing Option`}
                              {trim_item_val?.type === 'lease' &&
                                `Leasing Option`}
                            </p>
                          </div>
                          <p className="font-large font-w font-color">
                            {trim_item_val?.type === 'finance' &&
                              `$${trim_item_val?.finance_amount_per_month ? LeaseAmountPerMonthFormate(trim_item_val?.finance_amount_per_month) : '0'}/month`
                            }
                            {trim_item_val?.type === 'lease' &&
                              `$${trim_item_val?.finance_amount_per_month ? LeaseAmountPerMonthFormate(trim_item_val?.finance_amount_per_month) : '0'}/month`
                            }
                            {trim_item_val?.type === 'finance' &&
                              !isEmpty(tooltipText?.[41]) &&
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip className="custom-tooltip ">
                                    <div className="tc-text">
                                      {tooltipText?.[36]}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                {/* <img width="13px" src={infosvg} alt="" /> */}
                                <svg className="ms-1 mb-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                                  <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                                  <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                                </svg>
                              </OverlayTrigger>
                            }
                            {trim_item_val?.type === 'lease' &&
                              !isEmpty(tooltipText?.[36]) &&
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip className="custom-tooltip ">
                                    <div className="tc-text">
                                      {tooltipText?.[36]}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                {/* <img width="13px" src={infosvg} alt="" /> */}
                                <svg className="ms-1 mb-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                                  <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                                  <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                                </svg>
                              </OverlayTrigger>
                            }



                          </p>
                        </div>
                      </div>
                      <div>
                      </div>
                      {trim_item_val?.type === 'finance' &&
                        <Fragment>
                          <div className="tex-detail">
                            <span className="replace-hyphen">{trim_item_val?.finance_term + " "}months / {trim_item_val?.apr > 0 ? trim_item_val?.apr : 0}%
                              {Number(trim_item_val?.down_payment) === Number(0) ? ", plus 0 off" : " APR"}
                              {trim_item_val?.apr_cash && trim_item_val?.apr_cash > 0 && " + $" + NumberWithThousandSeparator(trim_item_val?.apr_cash) + " Cash"}
                            </span>
                            {!isEmpty(tooltipText?.[40]) &&
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip className="custom-tooltip ">
                                    <div className="tc-text">
                                      {tooltipText?.[40]}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                {/* <img width="13px" src={infosvg} alt="" /> */}
                                <svg className="ms-1 mb-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                                  <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                                  <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                                </svg>
                              </OverlayTrigger>
                            }

                            <br />
                            <span className="replace-hyphen">${trim_item_val?.down_payment && NumberWithThousandSeparator(trim_item_val?.down_payment)} down payment</span>
                          </div>
                        </Fragment>
                      }
                      {trim_item_val?.type === 'lease' &&
                        <div className="tex-detail">
                          <span className="replace-hyphen">{trim_item_val?.finance_term} months / {trim_item_val?.apr && NumberWithThousandSeparator(trim_item_val?.apr)} miles
                          </span>
                          {!isEmpty(tooltipText?.[35]) &&
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip className="custom-tooltip ">
                                  <div className="tc-text">
                                    {tooltipText?.[35]}
                                  </div>
                                </Tooltip>
                              }
                            >
                              {/* <img width="13px" src={infosvg} alt="" /> */}
                              <svg className="ms-1 mb-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                                <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                                <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                              </svg>
                            </OverlayTrigger>
                          }

                          <br />
                          <span className="replace-hyphen">${trim_item_val?.down_payment && NumberWithThousandSeparator(trim_item_val?.down_payment)} down payment</span>
                        </div>
                      }
                    </div>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={4}>
                    <div className="py-2 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <p className="car-postential font-wxl font-large">Potential Savings</p>
                        {trim_item_val?.type === 'finance' &&
                          !isEmpty(tooltipText?.[42]) &&
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip className="custom-tooltip ">
                                <div className="tc-text">
                                  {tooltipText?.[42]}
                                </div>
                              </Tooltip>
                            }
                          >
                            {/* <img width="13px" src={infosvg} alt="" /> */}
                            <svg className="ms-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                              <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                              <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                            </svg>
                          </OverlayTrigger>
                        }
                        {trim_item_val?.type === 'lease' &&
                          !isEmpty(tooltipText?.[37]) &&
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip className="custom-tooltip ">
                                <div className="tc-text">
                                  {tooltipText?.[37]}
                                </div>
                              </Tooltip>
                            }
                          >
                            {/* <img width="13px" src={infosvg} alt="" /> */}
                            <svg className="ms-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                              <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                              <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                            </svg>
                          </OverlayTrigger>
                        }
                      </div>
                      <h4 className="m-0 font-midium max-Potential font-w4 font-color">
                        $
                        {trim_item_val?.total_saving_amount !== null
                          ? NumberWithThousandSeparator(
                            trim_item_val?.total_saving_amount
                          )
                          : 0}
                      </h4>
                    </div>
                    {trim_item_val?.total_potential_savings && trim_item_val?.total_potential_savings?.length > 0 &&
                      trim_item_val?.total_potential_savings?.map((saving_amount_val, saving_amount_key) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between tex-detail" key={saving_amount_key}>
                            <span className="replace-hyphen">{isEmpty(saving_amount_val?.incentive_name_text) ? saving_amount_val?.incentive_name : saving_amount_val?.incentive_name_text}</span>
                            <span className="replace-hyphen">{isEmpty(saving_amount_val?.incentive_amount_text) ? saving_amount_val?.incentive_amount : saving_amount_val?.incentive_amount_text}</span>
                          </div>
                        );
                      })
                    }
                  </Col>
                </Row>
                <div className="mt-3 Programs-available">
                  <ul className="d-flex align-items-center justify-content-evenly font-smallx mb-0 font-w3 font-color">
                    {trim_item_val?.type === 'finance' &&
                      trim_item_val?.finance_special_incentive_available > 0 &&
                      <li>Special Programs available
                        {!isEmpty(tooltipText?.[44]) &&
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip className="custom-tooltip ">
                                <div className="tc-text">
                                  {tooltipText?.[44]}
                                </div>
                              </Tooltip>
                            }
                          >
                            {/* <img width="13px" src={infosvg} alt="" /> */}
                            <svg className="ms-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 13 12" fill="none" style={{ marginBottom: "2px" }}>
                              <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                              <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                            </svg>
                          </OverlayTrigger>
                        }
                      </li>}
                    {trim_item_val?.type === 'lease' &&
                      trim_item_val?.lease_special_incentive_available > 0 &&
                      <li>Special Programs available

                        {!isEmpty(tooltipText?.[44]) &&
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip className="custom-tooltip ">
                                <div className="tc-text">
                                  {tooltipText?.[44]}
                                </div>
                              </Tooltip>
                            }
                          >
                            {/* <img width="13px" src={infosvg} alt="" /> */}
                            <svg className="ms-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 13 12" fill="none" style={{ marginBottom: "2px" }}>
                              <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                              <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                            </svg>
                          </OverlayTrigger>
                        }

                      </li>
                    }
                    {trim_item_val?.type === 'finance' &&
                      trim_item_val?.finance_charging_incentive_available > 0 &&
                      <li>Charging Incentives available</li>}
                    {trim_item_val?.type === 'lease' && trim_item_val?.lease_charging_incentive_available > 0 &&
                      <li>Charging Incentives available</li>
                    }
                  </ul>
                </div>
              </div>

            </div>
            {/* )} */}
          </div>
          {trim_item_key + 1 !== item_val?.length && !expandedRows[`hidden_trim_row_${trim_item_val?.vehicle_id}_${trim_item_val?.type}`] &&
            <div className="bottom-border-samll border-margin"></div>
          }
        </Fragment>
      )
    })
  )
};


export default withRouter(NewIncentiveFinderByRequirements);