import * as API_URLS from "./url_helper";
import axios from 'axios';
import { getLoginUserData } from './sessionStorage';

axios.interceptors.request.use((config) => {

  let userData = getLoginUserData();

  if (config.method === 'post') {
    config.data = {
      ...config.data,
      device_type: "w",
      app_version: '2.0',
      user_id: userData?.user_id || '',
      login_token: userData?.login_token ? userData?.login_token : userData?.user_id ? "xyz" : ""
    };
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


export const postIncentivesByVehical = (params = {}) => axios.post(API_URLS.POST_INCENTIVE_FINDER_BY_VEHICLE, params);
// 
export const postGetIncentiveFinderFilters = (params = {}) => axios.post(API_URLS.POST_INCENTIVE_FINDER_FILTERS, params);
// 
export const postIncentiveFinderCheckCredentials = (params = {}) => axios.post(API_URLS.POST_CHECK_CREDENTIAL, params);

export const postIncentivesByAll = (params = {}) => axios.post(API_URLS.POST_INCENTIVE_SEARCH_BY_ALL, params);
//new
export const postValidateZipcode = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'ValidateZipcode' });

export const postEVIncentiveFinder = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'EVIncentiveFinder' });

export const postEVIncentiveFinderFinance = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'EVIncentiveFinderFinance' });

export const postGetEVIncentiveFinderFilters = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'EVIncentiveFinderFilters' });

export const postGetEVIncentiveDetail = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'EVIncentiveDetail' });

export const postCompareVehicleFilter = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'CompareVehiclesFilter' });
export const postCompareVehicle = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'CompareVehiclesDetail' });

export const postIncentiveSummary = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'IncentiveSummary' });

export const postIncentiveDetailSearchFilter = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'IncentiveDetailSearchFilter' });

export const postIncentiveDetailSearch = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'IncentiveDetailSearch' });
export const postTest = (params = {}) => axios.post(API_URLS.POST_test, params);

export const postContectUs = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'ContactUs' });

//auth
export const postSendAuthCode = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'SendAuthCode' });
export const postUserSignIn = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'UserSignIn' });
export const postUserSignUp = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'UserSignUp' });
export const postGetProfile = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'GetProfile' });
export const postUpdateProfile = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'UpdateProfile' });
export const postDeleteAccount = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'DeleteAccount' });
export const postValidateEmail = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'ValidateEmail' });

export const postPageMetaData = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'PageMetaData' });
export const postGetVehicle = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'GetVehicle' });
export const postCheckLogin = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'CheckLogin' });
export const postBannerOffer = (params = {}) => axios.post(process.env.REACT_APP_API_BASE_URL, { ...params, action: 'BannerOffer' });