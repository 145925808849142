import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent";
import 'react-loading-skeleton/dist/skeleton.css';

const IFLoader = () => {
    const loopCount = 5;
    return (
        <Fragment>
            {[...Array(loopCount)].map((x, i) => {
                return (
                    <tr className="border-tr p-0" key={i}>
                        <td colSpan={5} className="p-0">
                            <SkeletonComponent count={1} className="lp-skeleton-row " />
                        </td>
                    </tr>);
            })}
        </Fragment>
    );
}

export default withRouter(IFLoader);