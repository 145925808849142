import React, { useState, useMemo, useEffect, Fragment, useCallback, useRef } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useLocation, useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEVIncentiveDetailLoading, updateEVIncentiveDetail, updateAuthPoupState, updateUserDetails } from "../../store/action";
import { clearLoginUserData, setLoginUserData, getUserCurrentZipCode } from "../../helper/sessionStorage";
import * as apiHelper from "../../helper/api_helper";
import TabSection from "./TabSection";
import ModelDetailSection from "./ModelDetailSection";
import ChargingSection from "./ChargingSection";
import { defaultPopUpState, ArrayAsc, isEmpty, DateFormatterLastUpdated, createSeoFriendlyUrl, toTitleCase, setLocalStorageData, getLocalStorageData, isValidateZipcode } from '../../helper/project_helper';
import { useAuth, useEVIncentiveDetailSelectedState } from "../../components/hooks/useAuth";
import { withRouter } from "react-router-dom";
import CryptoJS from 'crypto-js';
import ChangePopup from "../../components/ChangePopup";
import { Helmet } from 'react-helmet-async';
import SkeletonComponent from "../../components/SkeletonComponent";

const defaultValueIncentiveDetail = (make, model, model_year, trim, userData) => {
    let tax_filing_status = '';
    let household_income = '';

    // if (!isEmpty(userData?.user_attributes?.tax_filing_status)) {
    //     tax_filing_status = userData?.user_attributes?.tax_filing_status;
    // }
    // if (!isEmpty(userData?.user_attributes?.annual_household_income_limit)) {
    //     household_income = userData?.user_attributes?.annual_household_income_limit;
    // }

    return {
        make: make || "",
        model: model || "",
        model_year: model_year || "",
        trim: trim || "",
        tax_filing_status: tax_filing_status,
        household_income: household_income,
        checkEligibility: false,
        down_payment: '',
        lease_down_payment: '',
        lease_term: '',
        annual_mileage: '',
        zip_code: getLocalStorageData('user_typed_zip_code') ? getLocalStorageData('user_typed_zip_code') : !isEmpty(userData?.zip_code) ? userData?.zip_code : ''
        // zip_code: ''
    };
};

const EVIncentiveDetail = ({
    history
}) => {
    let { vehicle_id_hash } = useParams();
    // let purchase_type = '';

    const [defaultValues, setDefaultValues] = useState({});
    const [purchase_type, setpurchase_type] = useState('Cash');
    const [currentTab, setCurrentTab] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [hideViewChangePopup, setHideViewChangePopup] = useState(false);
    const [model_year, setmodelYear] = useState('');
    const [defaultLeaseTerm, setDefaultLeaseTerm] = useState('');
    const [defaultAnnualMileage, setDefaultAnnualMileage] = useState('');
    const [defaultFinanceTerm, setDefaultFinanceTerm] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [defaultDownPayment, setDefaultDownPayment] = useState('');
    const [defaultLeaseDownPayment, setDefaultLeaseDownPayment] = useState('');
    const [writePath, setWritePath] = useState(false);
    const [back, setBack] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isLogin, userData] = useAuth();
    const [zipCode, setZipCode] = useState(!isEmpty(getLocalStorageData('user_typed_zip_code')) ? getLocalStorageData('user_typed_zip_code') : isLogin ? userData?.zip_code : '');
    const [validZipCode, setValidZipCode] = useState(true);
    const [changeZipPopup, setChangeZipPopup] = useState(isEmpty(getLocalStorageData('user_typed_zip_code')) ? true : false);


    const [incentiveCheckState, updateIncentiveState, updateFinanceIncentiveState] = useEVIncentiveDetailSelectedState();

    const dispatch = useDispatch();
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);



    useEffect(() => {

        // queryParams = new URLSearchParams(location.search);
        const p_type = queryParams.get('type');
        let lease_term = '';
        let finance_term = '';
        let down_payment = '';
        let lease_down_payment = '';
        if (p_type === 'lease') {
            lease_term = queryParams.get('term');
            lease_down_payment = queryParams.get('down');
        } else if (p_type === 'finance') {
            finance_term = queryParams.get('term');
            down_payment = queryParams.get('down');
        }
        const annual_mileage = queryParams.get('mileage');
        if (!isEmpty(p_type)) {
            setpurchase_type(toTitleCase(p_type));
            if (currentTab === '') {
                setCurrentTab(toTitleCase(p_type));
            }
        } else {
            if (currentTab === '') {
                setCurrentTab(toTitleCase("cash"));
            }
        }
        if (lease_term !== undefined && lease_term !== null) {
            setDefaultLeaseTerm(lease_term);
        }
        if (annual_mileage !== undefined && annual_mileage !== null) {
            setDefaultAnnualMileage(annual_mileage);
        }
        if (finance_term !== undefined && finance_term !== null) {
            setDefaultFinanceTerm(finance_term);
        }
        if (down_payment !== undefined && down_payment !== null) {
            setDefaultDownPayment(down_payment);
        }
        if (lease_down_payment !== undefined && lease_down_payment !== null) {
            setDefaultLeaseDownPayment(lease_down_payment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);


    const [first, setFirst] = useState(true);
    const [firstCall, setFirstCall] = useState(true);
    const [displaySkeletonView, setDisplaySkeletonView] = useState(true);
    const [filter, setFilter] = useState(false);
    const [tmpFilter, setTmpFilter] = useState({});
    const [popupChangeLoading, setPopupChangeLoading] = useState(false);
    const [incentiveAmount, setIncentiveAmount] = useState(0);
    const [financeIncentiveAmount, setFinanceIncentiveAmount] = useState(0);
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedMoreFeature, setExpandedMoreFeature] = useState(false);
    const [specialIncentives, setSpecialIncentives] = useState(0);
    const [totalSavingAmount, settotalSavingAmount] = useState(0);
    const [financeSpecialIncentives, setFinanceSpecialIncentives] = useState(0);
    const [totalFinanceSavingAmount, settotalFinanceSavingAmount] = useState(0);
    const [incentiveLeaseDetailData, setIncentiveLeaseDetailData] = useState(false);
    const [incentiveFinanceDetailData, setIncentiveFinanceDetailData] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [changeLeaseFinance, setChangeLeaseFinance] = useState(false);
    const [changeLoading, setChangeLoading] = useState(true);
    const [trimError, setTrimError] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    async function onChangePopUpField(name, value) {
        const tempState = JSON.parse(JSON.stringify(tmpFilter));
        let tempFilterList = JSON.parse(JSON.stringify(filterList));


        tempState[name] = value;

        if (name === "make") {
            tempState.model = "";
            tempState.trim = "";
            tempState.model_year = "";
        }
        if (name === "model") {
            tempState.trim = "";
            tempState.model_year = "";
        }
        if (name === "trim") {
            tempState.model_year = "";
        }

        if (!popupChangeLoading) {
            let list = await getPopupData(tempState);

            if (list?.model_year?.length === 1) {
                tempState.model_year = String(list?.model_year[0]);
            }
            if (tempState?.make !== list?.selected_make && name === 'model' && !isEmpty(list?.selected_make)) {
                tempState.make = list?.selected_make;
            }

            if (name !== 'model') {
                list = await getPopupData(tempState);
                if (list?.model?.length === 1) {
                    if (list?.model[0] !== tempState?.model) {
                        tempState.model = list?.model[0];
                        list = await getPopupData(tempState);
                        if (tempState?.make !== list?.selected_make && !isEmpty(list?.selected_make)) {
                            tempState.make = list?.selected_make;
                        }
                    }
                }
            }

            if (name !== 'trim') {
                list = await getPopupData(tempState);
                if (list?.trim?.length === 1 && list?.is_blank_trim_available !== true) {
                    if (list?.trim[0] !== tempState?.trim) {
                        tempState.trim = list?.trim[0];
                        list = await getPopupData(tempState);
                    }
                }
            }

            tempFilterList = list;
        }

        if (tempFilterList?.is_blank_trim_available === true && tempFilterList?.available_blank_trim_model_years?.length > 0 && !isEmpty(tempState?.model_year) && tempFilterList?.available_blank_trim_model_years?.includes(tempState?.model_year) !== true) {
            setTrimError(true);
        } else if (tempFilterList?.is_blank_trim_available === false) {
            setTrimError(true);
        } else {
            setTrimError(false);
        }
        setTmpFilter(tempState);
        setFilterList(tempFilterList);
    }

    async function getPopupData(params = {}) {
        // dispatch(setCompareVehiclesLoading(true));
        setPopupChangeLoading(true);
        try {
            const response = await apiHelper.postCompareVehicleFilter(params);
            var resData = [];
            if (response.data.status === 1) {
                resData = response?.data?.body;
            }

            setPopupChangeLoading(false);
            // dispatch(setCompareVehiclesLoading(false));
            return resData
        } catch (error) {
            console.error(error);
            setPopupChangeLoading(false);
            // dispatch(setCompareVehiclesLoading(false));
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional for smooth scrolling 
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    async function GetVehicleData() {
        dispatch(setEVIncentiveDetailLoading(true));
        try {
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '') {
                const params = {}
                params.vehicle_id_hash = vehicle_id_hash;
                const buyResponse = await apiHelper.postGetVehicle(params);
                if (buyResponse.status === 200) {
                    if (buyResponse.data.status === 1) {
                        const vehicleData = buyResponse.data.body;
                        setModel(vehicleData?.model);
                        setTrim(vehicleData?.trim);
                        setmodelYear(vehicleData?.model_year);
                        setMake(vehicleData?.make);
                        // const _back = JSON.parse(JSON.stringify(filter));


                        if (!isLogin && isEmpty(getLocalStorageData('user_typed_zip_code'))) {
                            const zip = await getUserCurrentZipCode();
                            if (!isEmpty(zip)) {
                                setZipCode(zip);
                                setValidZipCode(true);
                                setChangeZipPopup(false);
                                setLocalStorageData('user_typed_zip_code', zip)
                            }
                        }



                        let defaultFil = defaultValueIncentiveDetail(vehicleData?.make, vehicleData?.model, String(vehicleData?.model_year), vehicleData?.trim, userData);
                        if (filter !== false) {
                            defaultFil = JSON.parse(JSON.stringify(filter));
                        }
                        if (Object.keys(filter)?.length > 0) {
                            defaultFil = JSON.parse(JSON.stringify(filter));
                            defaultFil.make = vehicleData?.make;
                            defaultFil.model = vehicleData?.model;
                            defaultFil.trim = vehicleData?.trim;
                            defaultFil.model_year = String(vehicleData?.model_year);
                            defaultFil.annual_mileage = filter?.annual_mileage;
                            defaultFil.lease_term = filter?.lease_term;
                            defaultFil.finance_term = Number(filter?.finance_term);
                            defaultFil.down_payment = Number(filter?.down_payment);
                        }
                        setFilter(defaultFil);
                        setTmpFilter(defaultFil);
                        // setDefaultValues(defaultFil);


                        dispatch(setEVIncentiveDetailLoading(false));
                        const list = await getPopupData(defaultFil);
                        setFilterList(list);
                        setTimeout(() => {
                            // setBack(!back);
                            setChangeLeaseFinance(!changeLeaseFinance);
                        }, 100);
                    } else if (buyResponse.data.status === 3) {
                        history.push("/", true)
                        dispatch(setEVIncentiveDetailLoading(false));
                    }
                }
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    useEffect(() => {
        // if (filter === false) {
        GetVehicleData();
        // setTimeout(() => {
        // }, 100);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle_id_hash]);


    const { stateIncentiveDetail } = useSelector((state) => ({
        stateIncentiveDetail: state.EVIncentiveDetail,
    }));


    useEffect(() => {
        if (stateIncentiveDetail) {
            setBack(!back);
            dispatch(updateEVIncentiveDetail({
                financeData: false,
                leaseData: false,
                buyData: false,
                filter: false
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (currentTab === 'Lease' && stateIncentiveDetail?.leaseData?.setLeaseDisable) {
            if (stateIncentiveDetail?.financeData?.setFinanceDisable) {
                setCurrentTab('Cash');
            } else {
                setCurrentTab('Finance');
            }
        } else if (currentTab === 'Finance' && stateIncentiveDetail?.financeData?.setFinanceDisable) {
            if (currentTab === 'Lease' && stateIncentiveDetail?.leaseData?.setLeaseDisable) {
                setCurrentTab('Cash');
            } else {
                setCurrentTab('Lease');
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIncentiveDetail]);

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState
    }));

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData]);

    const onLogin = (x) => {

    }

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    if (first === true) {
        // if (userData === false || userData === undefined) {
        //     askLogin();
        // }
        // setSpecialIncentives(0);

        // updateIncentiveState({
        //     selectedIncentives: [],
        //     otherIncentives: []
        // });

        // dispatch(updateEVIncentiveDetail({
        //     loading: false,
        //     status: true,
        //     buyData: false,
        //     filter: false
        // }));
        setFirst(false);
    }

    const incentiveBuyDetailData = useMemo(() => {

        if (stateIncentiveDetail.buyData !== false) {
            return stateIncentiveDetail.buyData;
        }
        return null;
    }, [stateIncentiveDetail]);

    const incentiveLeaseDetailDataState = useMemo(() => {

        if (stateIncentiveDetail.leaseData !== false) {
            return stateIncentiveDetail.leaseData;
        }
        return null;
    }, [stateIncentiveDetail]);

    const incentiveFinanceDetailDataState = useMemo(() => {

        if (stateIncentiveDetail.financeData !== false) {
            return stateIncentiveDetail.financeData;
        }
        return null;
    }, [stateIncentiveDetail]);



    const page_title = useMemo(() => {
        // if (incentiveBuyDetailData !== false) {
        //     if (incentiveBuyDetailData?.page_title !== null) {
        //         return incentiveBuyDetailData?.page_title;
        //     }
        // }
        if (incentiveBuyDetailData !== false) {
            if (!isEmpty(incentiveBuyDetailData?.buy?.make)) {
                return `${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name} Deals, Lease, Incentives & Rebates | Siffra`;
            }
        }

        return null;
    }, [incentiveBuyDetailData]);

    const page_meta_description = useMemo(() => {
        // if (incentiveBuyDetailData !== false) {
        //     if (incentiveBuyDetailData?.page_meta_description !== null) {
        //         return incentiveBuyDetailData?.page_meta_description;
        //     }
        // }
        if (incentiveBuyDetailData !== false) {
            if (!isEmpty(incentiveBuyDetailData?.buy?.make)) {
                return `Browse the latest ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name} lease, incentives, deals and rebates in your area at siffra.com. Find all ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} incentives, savings and current offers.`;
            }
        }

        return null;
    }, [incentiveBuyDetailData]);

    const page_meta_keyword = useMemo(() => {
        if (incentiveBuyDetailData !== false) {
            if (!isEmpty(incentiveBuyDetailData?.buy?.make)) {
                return `${incentiveBuyDetailData?.buy?.make}, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.segment_name}, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name}, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} Incentives, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} Rebates, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} federal tax credit, potential savings, ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} lease`;
            }
        }

        return null;
    }, [incentiveBuyDetailData]);




    useEffect(() => {
        // if (!isEmpty(page_title)) {
        //     document.title = page_title;
        // }
        // if (!isEmpty(page_meta_description)) {
        //     document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
        // }
        if (!isEmpty(page_meta_keyword)) {
            const keyword_tag = document.querySelector('meta[name="keywords"]');
            if (keyword_tag) {
                keyword_tag.setAttribute("content", page_meta_keyword);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page_title, page_meta_description, page_meta_keyword]);

    const onReset = () => {
        const temp = { ...filter }
        setFilter({ ...temp, tax_filing_status: '', household_income: "", checkEligibility: false });
        // setSpecialIncentives(0);
        // settotalSavingAmount(parseFloat(incentiveBuyDetailData?.buy?.total_saving_amount))
        // const otherInc = [];
        // incentiveBuyDetailData?.buy?.specialIncentives?.map((incentivesVal, incentivesKey) => {
        //     otherInc.push(incentivesVal?.incentive_id);
        // });

        // updateIncentiveState({
        //     selectedIncentives: [],
        //     otherIncentives: otherInc
        // });
    }

    // var vehicleLoading = useMemo(() => {
    //     return stateIncentiveDetail.vehicleLoading || false;
    // }, [stateIncentiveDetail]);

    // var buyLoading = useMemo(() => {
    //     return stateIncentiveDetail.buyLoading || false;
    // }, [stateIncentiveDetail]);

    // var leaseLoading = useMemo(() => {
    //     return stateIncentiveDetail.leaseLoading || false;
    // }, [stateIncentiveDetail]);

    var isLoading = useMemo(() => {
        return stateIncentiveDetail.loading || false;
    }, [stateIncentiveDetail]);

    // useEffect(() => {

    //     if (isLoading === false) {
    //         setTimeout(() => {
    //             if (displaySkeletonView === true) {
    //                 setDisplaySkeletonView(false);
    //             }
    //         }, 3000);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoading]);

    // useEffect(() => {
    //     console.log(vehicleLoading);

    //     if (vehicleLoading === false && buyLoading === false && leaseLoading === false) {
    //         setTimeout(() => {
    //             setIsLoading(false);
    //             if (displaySkeletonView === true) {
    //                 setDisplaySkeletonView(false);
    //             }
    //         }, 3000);
    //     } else {
    //         setIsLoading(true);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [vehicleLoading, buyLoading, leaseLoading]);

    useEffect(() => {
        // if (userData?.tax_filing_status == filter?.tax_filing_status) {
        //     setFilter({ ...filter, household_income: userData?.household_income });
        // } else {
        if (filter?.tax_filing_status !== '') {
            setFilter({ ...filter, household_income: "1" });
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.tax_filing_status]);

    useEffect(() => {
        if (stateIncentiveDetail?.loading === false && stateIncentiveDetail?.error === '' && firstCall === false) {
            // setIsLoading(true);
            getBuyIncentiveDetail(filter);
            getFinanceIncentiveDetail(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.checkEligibility]);

    useEffect(() => {
        if (filter !== undefined || filter?.isBoo) {

            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && !isEmpty(make)) {
                getBuyIncentiveDetail(filter, true);
                setFirstCall(false);
            }
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && !isEmpty(make)) {
                getLeaseIncentiveDetail(filter, true);
            }
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && !isEmpty(make)) {
                getFinanceIncentiveDetail(filter, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.make, filter?.model, filter?.trim, filter?.model_year, back]);

    useEffect(() => {
        const fetchData = async () => {
            if (isEmpty(getLocalStorageData('user_typed_zip_code'))) {
                // console.log('userData?.zip_code', userData?.zip_code);
                setZipCode(userData?.zip_code);
                setLocalStorageData('user_typed_zip_code', userData?.zip_code)
            }

            if (firstLoad === false) {
                await setDefaultValues(defaultValueIncentiveDetail(make, model, model_year, trim, userData))
                await setFilter(defaultValues);
                await getBuyIncentiveDetail(defaultValues, true);
                await getLeaseIncentiveDetail(defaultValues, true);
                await getFinanceIncentiveDetail(defaultValues, true);
                const tmpFilter = JSON.parse(JSON.stringify(filter));
                if (defaultLeaseTerm === '' && defaultAnnualMileage === '' && defaultLeaseDownPayment === '') {
                    if (Number(tmpFilter?.lease_term) !== Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term)) {
                        if (!isEmpty(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term)) {
                            tmpFilter['lease_term'] = Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term);
                            tmpFilter['annual_mileage'] = Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.annual_mileage);
                            tmpFilter['lease_down_payment'] = Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.down_payment);
                        }
                    }
                }
                if (defaultDownPayment === '' && defaultFinanceTerm === '') {
                    if (Number(tmpFilter?.finance_term) !== Number(incentiveFinanceDetailData?.finance?.finance_payment_data?.finance_term)) {
                        if (!isEmpty(incentiveFinanceDetailData?.finance?.finance_payment_data?.finance_term)) {
                            tmpFilter['finance_term'] = Number(incentiveFinanceDetailData?.finance?.finance_payment_data?.finance_term);
                            tmpFilter['down_payment'] = Number(incentiveFinanceDetailData?.finance?.finance_payment_data?.down_payment);
                        }
                    }
                }

                setFilter(tmpFilter);
            }
            if (firstLoad === true) {
                setFirstLoad(false);
            }
        }
        if (isLogin) {
            fetchData();
            setChangeZipPopup(false);
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);


    useEffect(() => {
        setFilter(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    useEffect(() => {
        // setTimeout(() => {
        const tmpFilter = JSON.parse(JSON.stringify(filter));
        if (defaultLeaseTerm === '' && defaultAnnualMileage === '' && defaultLeaseDownPayment === '') {
            if (Number(tmpFilter?.lease_term) !== Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term)) {
                if (!isEmpty(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term)) {
                    tmpFilter['lease_term'] = Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term);
                    tmpFilter['annual_mileage'] = Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.annual_mileage);
                    tmpFilter['lease_down_payment'] = Number(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.down_payment);
                }
            }
        }
        if (defaultDownPayment === '' && defaultFinanceTerm === '') {
            if (Number(tmpFilter?.finance_term) !== Number(incentiveFinanceDetailData?.finance?.finance_payment_data?.finance_term)) {
                if (!isEmpty(incentiveFinanceDetailData?.finance?.finance_payment_data?.finance_term)) {
                    tmpFilter['finance_term'] = Number(incentiveFinanceDetailData?.finance?.finance_payment_data?.finance_term);
                    tmpFilter['down_payment'] = Number(incentiveFinanceDetailData?.finance?.finance_payment_data?.down_payment);
                }
            }
        }

        setFilter(tmpFilter);
        // }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incentiveFinanceDetailData, incentiveLeaseDetailData])

    useEffect(() => {

        // if (firstTrim === false) {
        //     if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== undefined && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== null && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== false) {
        //         let writePath = '';

        //         // if (incentiveLeaseDetailDataState?.setLeaseDisable) {

        //         if (!isEmpty(filter?.model) && !isEmpty(filter?.make) && !isEmpty(filter?.model_year)) {
        //             if (filter?.make !== make || filter?.model !== model || filter?.trim != trim || filter?.model_year !== model_year) {
        //                 writePath = '/detail/' + CryptoJS.MD5(stateIncentiveDetail?.buyData?.buy?.vehicle_id?.toString()).toString() + "/" + createSeoFriendlyUrl(filter?.make) + "/" + createSeoFriendlyUrl(filter?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(filter?.trim) + "?purchase_type=" + purchase_type.replace(/^./, char => char.toLowerCase());
        //             }
        //         }
        //         // } else {
        //         //     if (!isEmpty(filter?.model) && !isEmpty(filter?.make) && !isEmpty(filter?.model_year)) {
        //         //         writePath = '/detail/' + purchase_type.replace(/^./, char => char.toLowerCase()) + "/" + CryptoJS.MD5(stateIncentiveDetail?.buyData?.buy?.vehicle_id?.toString()).toString() + "/" + createSeoFriendlyUrl(filter?.make) + "/" + createSeoFriendlyUrl(filter?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(filter?.trim);
        //         //     }

        //         // }
        //         console.log(writePath);

        //         if (location.pathname.includes(writePath) !== true && writePath != '') {
        //             history.push(writePath, null, { shallow: true });
        //         }
        //     }
        // } else {
        //     setFirstTrim(false);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIncentiveDetail?.buyData?.buy?.vehicle_id, incentiveLeaseDetailDataState?.setLeaseDisable, currentTab]);

    useEffect(() => {
        // if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin) {
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '') {

            const tempIncentiveFinanceDetailDataState = JSON.parse(JSON.stringify({ ...incentiveFinanceDetailDataState }));
            if (filter?.finance_term !== '' && filter?.down_payment !== '' && tempIncentiveFinanceDetailDataState?.finance !== undefined) {

                const index = tempIncentiveFinanceDetailDataState?.finance?.finance_payment_data?.findIndex(item => Number(item?.finance_term) === Number(filter?.finance_term) && Number(item?.down_payment) === Number(filter?.down_payment));

                if (index !== -1) {
                    tempIncentiveFinanceDetailDataState.finance.finance_payment_data = tempIncentiveFinanceDetailDataState?.finance?.finance_payment_data?.[index];
                } else {

                    tempIncentiveFinanceDetailDataState.finance.finance_payment_data = false;

                }
                setIncentiveFinanceDetailData(tempIncentiveFinanceDetailDataState);
            }
        }

        // console.log(tempLeaseData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.finance_term, filter?.down_payment]);


    useEffect(() => {
        // if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin) {
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '') {
            const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveLeaseDetailDataState }));
            if (filter?.lease_term !== '' && filter?.annual_mileage !== '' && filter?.lease_down_payment !== '' && tempIncentiveLeaseDetailDataState?.vehicle_lease_data) {

                console.log('ddddindex');
                const index = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.findIndex(item => Number(item?.lease_term) === Number(filter?.lease_term) && Number(item?.annual_mileage) === Number(filter?.annual_mileage) && Number(item?.down_payment) === Number(filter?.lease_down_payment));

                if (index !== -1) {
                    tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.[index];
                } else {

                    tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = false;

                }
                setIncentiveLeaseDetailData(tempIncentiveLeaseDetailDataState);
            }
        }

        // console.log(tempLeaseData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.lease_term, filter?.annual_mileage, filter?.lease_down_payment]);

    async function getBuyIncentiveDetail(params = {}, dontCheckFilter = false) {
        if (params === undefined || typeof params === 'boolean') {
            return false;
        }

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }
        dispatch(setEVIncentiveDetailLoading(true));
        try {
            params.purchase_type = 'Buy';
            params.purchase_sub_type = 'Cash';
            const buyResponse = await apiHelper.postGetEVIncentiveDetail(params);
            var incentiveDetail = false;
            if (buyResponse.data.status === 1) {
                incentiveDetail = buyResponse.data.body;
                if (incentiveDetail?.isLogin === false) {
                    clearLoginUserData();
                    dispatch(updateUserDetails(false));
                }
                if (incentiveDetail?.user_attributes !== undefined && incentiveDetail?.user_attributes !== null && incentiveDetail?.user_attributes !== undefined) {
                    if (filter?.checkEligibility === true) {
                        let tempUserData = { ...userData };
                        tempUserData.user_attributes = incentiveDetail?.user_attributes;
                        setLoginUserData(tempUserData);
                    }
                }

                dispatch(updateEVIncentiveDetail({
                    loading: false,
                    status: true,
                    buyData: incentiveDetail,
                    filter: filter
                }));

                setTimeout(() => {
                    if (changeLoading) {
                        if (writePath === true) {
                            let tmp_writePath = '';
                            if (!isEmpty(filter?.model) && !isEmpty(filter?.make) && !isEmpty(filter?.model_year)) {
                                if (filter?.make !== make || filter?.model !== model || filter?.trim !== trim || filter?.model_year !== model_year) {
                                    tmp_writePath = '/detail/' + CryptoJS.MD5(incentiveDetail?.buy?.vehicle_id?.toString()).toString() + "/" + createSeoFriendlyUrl(filter?.make) + "/" + createSeoFriendlyUrl(filter?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(filter?.trim);

                                    if (!isEmpty(queryParams.get('type'))) {
                                        tmp_writePath += "?type=" + purchase_type.replace(/^./, char => char.toLowerCase());
                                    }
                                }
                            }
                            if (location.pathname.includes(tmp_writePath) !== true && tmp_writePath !== '') {
                                history.push(tmp_writePath, null, { shallow: true });
                                setWritePath(false);
                            }
                        }
                        setChangeLoading(false);
                    }
                }, 100);
            } else if (buyResponse.data.status === 2) {
                dispatch(setEVIncentiveDetailLoading(false));
                askLogin();
            } else {
                dispatch(setEVIncentiveDetailLoading(false));
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }
    async function getFinanceIncentiveDetail(params = {}, dontCheckFilter = false) {
        if (params === undefined || typeof params === 'boolean') {
            return false;
        }

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }
        dispatch(setEVIncentiveDetailLoading(true));
        try {
            params.purchase_type = 'Buy';
            params.purchase_sub_type = 'Finance';
            const buyResponse = await apiHelper.postGetEVIncentiveDetail(params);
            var incentiveDetail = false;
            if (buyResponse.data.status === 1) {
                incentiveDetail = buyResponse.data.body;
                if (incentiveDetail?.isLogin === false) {
                    clearLoginUserData();
                    dispatch(updateUserDetails(false));
                }
                if (incentiveDetail?.user_attributes !== undefined && incentiveDetail?.user_attributes !== null && incentiveDetail?.user_attributes !== undefined) {
                    if (filter?.checkEligibility === true) {
                        let tempUserData = { ...userData };
                        tempUserData.user_attributes = incentiveDetail?.user_attributes;
                        setLoginUserData(tempUserData);
                    }
                }

                const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveDetail }));

                if (tempIncentiveLeaseDetailDataState?.finance !== undefined) {
                    const tempLeaseData = JSON.parse(JSON.stringify({ ...incentiveDetail }));
                    tempIncentiveLeaseDetailDataState.finance.finance_payment_data = tempLeaseData?.finance?.finance_payment_data?.[0];



                    setIncentiveFinanceDetailData(tempIncentiveLeaseDetailDataState);


                    if (defaultDownPayment !== '' && defaultFinanceTerm !== '') {

                        let filterValue = { ...filter };
                        filterValue['down_payment'] = Number(defaultDownPayment);
                        filterValue['finance_term'] = Number(defaultFinanceTerm);
                        setFilter(filterValue);
                    }
                    // else if (incentiveDetail?.finance !== null) {
                    //     let filterValue = { ...filter };
                    //     filterValue['down_payment'] = tempIncentiveLeaseDetailDataState?.finance?.finance_payment_data?.down_payment;
                    //     filterValue['finance_term'] = tempIncentiveLeaseDetailDataState?.finance?.finance_payment_data?.finance_term;
                    //     setFilter(filterValue);
                    // }
                } else {
                    setIncentiveFinanceDetailData(false);
                }




                dispatch(updateEVIncentiveDetail({
                    loading: false,
                    status: true,
                    financeData: incentiveDetail,
                    filter: filter
                }));
                setTimeout(() => {
                    if (changeLoading) {
                        setChangeLoading(false);
                    }
                }, 100);

            } else if (buyResponse.data.status === 2) {
                dispatch(setEVIncentiveDetailLoading(false));
                askLogin();
            } else {
                dispatch(setEVIncentiveDetailLoading(false));
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    async function getLeaseIncentiveDetail(params = {}, dontCheckFilter = false) {

        if (params === undefined || typeof params === 'boolean') {
            return false;
        }

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }

        dispatch(setEVIncentiveDetailLoading(true))
        try {
            var incentiveDetail = false;

            params.purchase_type = 'Lease';
            params.purchase_sub_type = '';
            const leaseResponse = await apiHelper.postGetEVIncentiveDetail(params);
            if (leaseResponse?.data?.status === 1) {
                incentiveDetail = leaseResponse?.data?.body;
                if (incentiveDetail?.vehicle_lease_data !== null) {


                    const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveDetail }));

                    if (tempIncentiveLeaseDetailDataState.vehicle_lease_data !== undefined) {
                        const tempLeaseData = JSON.parse(JSON.stringify({ ...incentiveDetail }));
                        tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = tempLeaseData?.vehicle_lease_data?.lease_data?.[0];

                        console.log('tempIncentiveLeaseDetailDataState', tempIncentiveLeaseDetailDataState);

                        setIncentiveLeaseDetailData(tempIncentiveLeaseDetailDataState);

                        // console.log(tempIncentiveLeaseDetailDataState);

                        if (defaultAnnualMileage !== '' && defaultLeaseTerm !== '' && defaultLeaseDownPayment !== '') {
                            let filterValue = { ...filter };
                            filterValue['annual_mileage'] = Number(defaultAnnualMileage);
                            filterValue['lease_term'] = Number(defaultLeaseTerm);
                            filterValue['lease_down_payment'] = Number(defaultLeaseDownPayment);
                            setFilter(filterValue);
                        }
                        // else if (incentiveDetail?.vehicle_lease_data !== null) {
                        //     let filterValue = { ...filter };

                        //     filterValue['annual_mileage'] = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.annual_mileage;
                        //     filterValue['lease_term'] = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.lease_term;
                        //     setFilter(filterValue);
                        // }
                    } else {
                        setIncentiveLeaseDetailData(false);
                    }

                } else {
                    setIncentiveLeaseDetailData(false);
                }

            } else {
                dispatch(setEVIncentiveDetailLoading(true))
            }

            // setTimeout(() => {
            if (displaySkeletonView === true) {
                setDisplaySkeletonView(false);
            }
            // }, 1500);

            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: true,
                leaseData: incentiveDetail,
                filter: filter
            }));

        } catch (error) {
            console.error("error", error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    const handleHideShowClick = (rowId) => {
        setExpandedRows((prev) => ({
            [rowId]: !prev[rowId],
        }));
    };

    const handleChangeClick = async () => {
        if (hideViewChangePopup === true) {
            setTmpFilter(filter);
            const list = await getPopupData(JSON.parse(JSON.stringify(filter)));
            setFilterList(list);
        }

        setHideViewChangePopup(!hideViewChangePopup)
    };
    const onChangeClickVehicle = () => {
        setValidZipCode(true);
        dispatch(updateEVIncentiveDetail({
            financeData: false,
            leaseData: false,
            buyData: false,
            filter: null
        }));
        setIsImageLoaded(false);
        setWritePath(true);
        setFilter(tmpFilter);
        setHideViewChangePopup(false);
        setChangeLoading(true);
        setTimeout(() => {
            updateIncentiveState([], []);
            setSpecialIncentives(0);
            window.scrollTo(0, 0); // Add a small delay

        }, 100);
    };

    const handleChangeZipClick = async () => {

        setChangeZipPopup(!changeZipPopup)
    };

    const onUpdateZipCode = async () => {


        const tmp_validZipCode = await isValidateZipcode(zipCode);

        if (tmp_validZipCode) {
            setLocalStorageData('user_typed_zip_code', zipCode);
            setChangeZipPopup(false);
            setValidZipCode(true);
            const temp = { ...filter }
            setFilter({ ...temp, zip_code: zipCode });

            dispatch(updateEVIncentiveDetail({
                financeData: false,
                leaseData: false,
                buyData: false,
                filter: null
            }));
            await getBuyIncentiveDetail({ ...temp, zip_code: zipCode }, true);
            await getLeaseIncentiveDetail({ ...temp, zip_code: zipCode }, true);
            await getFinanceIncentiveDetail({ ...temp, zip_code: zipCode }, true);
        } else {
            setValidZipCode(false);
        }
    };
    const onChangeZipField = async (zip) => {
        if (zip?.length < 6) {
            setZipCode(zip);
        }
        if (zip?.length === 5) {
            setValidZipCode(true);
        }
    };

    const divchangeZip = useRef(null);
    const handleClickOutsideZip = (event) => {

        if (divchangeZip.current && !divchangeZip?.current?.contains(event.target) && !event.target.closest(`.model-detail-zip`) && !event.target.closest(`.vdp-zip-btn`)) {
            setChangeZipPopup(false);
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutsideZip);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutsideZip);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (changeZipPopup) {
            const input = document.querySelector('.zip-focus');
            // Check if the element exists and has the class
            if (input && input.classList.contains('zip-focus')) {
                input.focus(); // Give focus if the class is present
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeZipPopup]);


    return (
        <Fragment>
            <Helmet>
                <title>{page_title}</title>
                <meta name="description" content={page_meta_description} />
                <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}${location.pathname}`} />
            </Helmet>
            {/* {displaySkeletonView && */}
            {/* <PageLoaderComponent loading={isLoading || displaySkeletonView} /> */}
            {/* } */}
            {/* {displaySkeletonView && */}
            {/* {isLoading &&
                <DetailLoder />
            } */}
            {/* {(isEmpty(displaySkeletonView?.error?.msg) && !displaySkeletonView) ? ( */}
            <div className={"detail-main"}>
                {!isEmpty(filter?.make) && (!isLoading || !changeLoading) ?
                    <Fragment>
                        <section className="detaile-top-sticky main-animation">
                            <Row>
                                <Col lg={12}>
                                    <div className="d-flex align-items-center justify-content-between flex-wrap bg-detail-top py-3 px-lg-5 px-3">
                                        <div className="d-flex flex-column align-items-center">
                                            <h1 className="font-wx font-largeX font-white">{`${filter?.model_year} ${filter?.make} ${filter?.model}`}</h1>
                                            <div className="d-sm-flex align-items-center">
                                                <div className="d-flex trim-select-div">
                                                    <p
                                                        className="font-midium font-wxl font-white"
                                                        style={{ margin: '0' }}
                                                    >
                                                        {incentiveBuyDetailData?.buy?.trim}
                                                    </p>
                                                </div>
                                                <div className="change-pro">
                                                    <span className="font-midium font-w4 font-white"></span>
                                                    <button className="font-midium font-wx dropdown compare-change-button font-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => handleChangeClick()}>Change</button>
                                                </div>
                                                {hideViewChangePopup &&
                                                    <ChangePopup
                                                        popupLoading={popupChangeLoading}
                                                        filterList={filterList}
                                                        filter={tmpFilter}
                                                        filterState={filter}
                                                        onChangePopUpField={onChangePopUpField}
                                                        trimError={trimError}
                                                        onChangeClickVehicle={onChangeClickVehicle}
                                                        handleChangeClick={handleChangeClick} />
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className={"font-large font-w4 font-white d-flex align-items-center p-relative " + ((!validZipCode) && "vdp-top-zip-input")}>
                                                {getLocalStorageData('user_typed_zip_code') &&
                                                    <span className={"font-large font-w4 font-white " + (changeZipPopup && "me-2 text-nowrap")}>Showing Results for</span>}
                                                <div className={(!changeZipPopup ? 'd-none' : '') + ' zip-input'}>
                                                    <Form.Control
                                                        className={"font-small font-wxl font-color zip-focus " + ((!validZipCode || zipCode?.length < 5) && "zip-error-border")}
                                                        aria-label="Default select example"
                                                        value={zipCode}
                                                        onChange={(x) => onChangeZipField(x.target.value)}
                                                        style={{ height: "35px" }}
                                                        placeholder="Enter Zip code"
                                                        onBlur={onUpdateZipCode}
                                                    />
                                                </div>
                                                <button className={".font-large font-wx vdp-zip-btn dropdown font-white " + (changeZipPopup && 'd-none')} data-bs-toggle="dropdown" aria-expanded="false" onClick={handleChangeZipClick}>
                                                    {getLocalStorageData('user_typed_zip_code')}
                                                </button>
                                                {/* <button className="font-midium font-wx vdp-zip-btn dropdown font-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleChangeZipClick}>
                                                Change
                                                </button> */}

                                                {/* {changeZipPopup &&
                                                <div className="border border-dark-subtle p-4 rounded model-detail-zip" ref={divchangeZip}>
                                                    <div className="mb-2 text-start">
                                                        <div className='popup-close pt-1 cursor-pointer'>
                                                            <svg onClick={() => handleChangeZipClick()} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                                        </div>
                                                        <label className="font-w font-midium font-color">Zip Code</label>
                                                        <Form.Control
                                                            className={"font-small font-wxl font-color"}
                                                            aria-label="Default select example"
                                                            value={zipCode}
                                                            onChange={(x) => onChangeZipField(x.target.value)}
                                                        />
                                                        <span className="mt-5 font-midium arning-msg font-black">
                                                            {(!validZipCode || zipCode?.length < 5) ? 'Enter a valid Zip code.' : ''}{" "}
                                                        </span>


                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button"
                                                            disabled={(zipCode?.length !== 5 || String(zipCode) === String(getLocalStorageData('user_typed_zip_code') || ''))}
                                                            className="btn-update-zipcode text-decoration-none"
                                                            onClick={(x) => onUpdateZipCode(x.target.value)}> Update </button>
                                                    </div>
                                                </div>
                                            } */}
                                            </div>
                                            {!validZipCode &&
                                                <div style={{ textAlign: 'end', paddingRight: getLocalStorageData('user_typed_zip_code') ? '6%' : '12%', paddingTop: '12px', maxWidth: "fit-content", marginLeft: "auto" }}>
                                                    <span className="mt-5 font-midium arning-msg font-black zip-error">
                                                        Enter a valid Zip code
                                                    </span>
                                                </div>}
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </section>
                        <div className="px-lg-5 px-3  main-animation">
                            <ul className="d-flex p-0 list-style-none mb-0">
                                <li>
                                    <span>
                                        <Link className="text-decoration-none font-color font-small green-hover-text" to={"/"}>Home</Link>
                                    </span>
                                </li>
                                <li className="mx-1">
                                    <span className="font-small">›</span>
                                </li>
                                <li><span className="a-list-item">
                                    <span className="text-decoration-none font-color font-small">New {filter?.model_year} {filter?.make} {filter?.model}</span>
                                </span>
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                    : <section className="">
                        <Row>
                            <Col lg={3} className="ms-5">
                                <div className="">
                                    <SkeletonComponent count={1} className="height-1" />
                                </div>
                            </Col>
                        </Row>
                    </section>
                }

                <ModelDetailSection
                    incentiveBuyDetailData={incentiveBuyDetailData}
                    chargingDetail={incentiveBuyDetailData?.charging_data}
                    setExpandedMoreFeature={setExpandedMoreFeature}
                    expandedMoreFeature={expandedMoreFeature}
                    expandedRows={expandedRows}
                    filter={filter}
                    isLoading={isLoading}
                    changeLoading={changeLoading}
                    isImageLoaded={isImageLoaded}
                    setIsImageLoaded={setIsImageLoaded}
                />

                <TabSection
                    incentiveBuyDetailData={incentiveBuyDetailData}
                    incentiveLeaseDetailData={incentiveLeaseDetailData}
                    incentiveFinanceDetailData={incentiveFinanceDetailData}
                    filter={filter}
                    setFilter={setFilter}
                    incentiveAmount={incentiveAmount}
                    setIncentiveAmount={setIncentiveAmount}
                    financeIncentiveAmount={financeIncentiveAmount}
                    setFinanceIncentiveAmount={setFinanceIncentiveAmount}
                    handleHideShowClick={handleHideShowClick}
                    expandedRows={expandedRows}
                    tooltipText={incentiveBuyDetailData?.tooltips}
                    onReset={onReset}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                    askLogin={askLogin}
                    setExpandedRows={setExpandedRows}
                    setSpecialIncentives={setSpecialIncentives}
                    financeSpecialIncentives={financeSpecialIncentives}
                    setFinanceSpecialIncentives={setFinanceSpecialIncentives}
                    specialIncentives={specialIncentives}
                    settotalSavingAmount={settotalSavingAmount}
                    totalSavingAmount={totalSavingAmount}
                    totalFinanceSavingAmount={totalFinanceSavingAmount}
                    settotalFinanceSavingAmount={settotalFinanceSavingAmount}
                    incentiveCheckState={incentiveCheckState}
                    updateIncentiveState={updateIncentiveState}
                    updateFinanceIncentiveState={updateFinanceIncentiveState}
                    isLoading={isLoading}
                    isLogin={isLogin}
                    changeLoading={changeLoading}
                />
                <ChargingSection
                    chargingDetail={incentiveBuyDetailData?.charging_data}
                    chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                    tooltipText={incentiveBuyDetailData?.tooltips}
                    isLoading={isLoading}
                    changeLoading={changeLoading}
                />
                {/* <section className="mx-lg-5 mx-3 "> */}
                {!isEmpty(incentiveBuyDetailData?.last_updated_date) &&
                    <Row className="pt-5 px-lg-5 px-3 pb-2 last-updated-date">
                        <Col>
                            <label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(incentiveBuyDetailData?.last_updated_date)}</label>
                        </Col>
                    </Row>
                }
                {/* </section> */}
            </div>
            {/* ) : */}
            {/* <main className="blank-page main-animation"></main> */}
            {/* } */}
        </Fragment>
    )

}

export default withRouter(EVIncentiveDetail);


