import React, { useRef, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { isEmpty, NumberWithThousandSeparator, createSeoFriendlyUrl, LeaseAmountPerMonthFormate } from "../helper/project_helper";
import CryptoJS from 'crypto-js';

const BannerOfferPopup = ({
    data,
    handleBannerLearnMoreClick
}) => {
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            handleBannerLearnMoreClick(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside); // Clean up the event listener when the component unmounts 
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const list = data?.allData;

    const getVehicleInfo = (val) => {
        const leaseAmount = !isEmpty(val?.vehicle_lease_data?.lease_amount_per_month) ? `<strong><span>$${LeaseAmountPerMonthFormate(val?.vehicle_lease_data?.lease_amount_per_month)}/month</span></strong>` : '';
        const leaseTerm = val?.vehicle_lease_data?.lease_term ? `${val?.vehicle_lease_data?.lease_term} Months` : '';
        const annualMileage = val?.vehicle_lease_data?.annual_mileage ? `${NumberWithThousandSeparator(val?.vehicle_lease_data?.annual_mileage)} Miles` : '';
        const downPayment = val?.vehicle_lease_data?.down_payment ? `$${NumberWithThousandSeparator(val?.vehicle_lease_data?.down_payment)} Down` : '';
        return `${val?.vehicle_data?.model_year} ${val?.vehicle_data?.make} ${val?.vehicle_data?.model} ${val?.vehicle_data?.trim} ${leaseAmount}, ${leaseTerm}, ${annualMileage}, ${downPayment}.`;
    };

    let isVehicleSame = false;
      
    
    if(list[0].vehicle_data)
    {
        // Extract the vehicle_id of the first object for comparison
        const firstVehicleId = list[0].vehicle_data.vehicle_id;
        
        // Use the `every()` method to check if all vehicle_ids are the same
        isVehicleSame = list.every(obj => obj.vehicle_data.vehicle_id === firstVehicleId);
        
    }      

    return (
        <Fragment>
            <div className="fade modal-backdrop show"></div>
            <div className="login-popups banner-offer-popup popup-set-width" ref={divRef}>
                <section className='px-lg-4 px-3 banner-detail-popup'>
                    <div className='popup-close pt-1'>
                        <svg style={{ cursor: "pointer" }} onClick={() => handleBannerLearnMoreClick(false)} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className='mb-2 mt-5 pb-3'>
                        <div className='mb-lg-4 mb-2 d-flex align-items-center'>
                            <p className='content-font font-large font-black font-wx' style={{ textWrap: 'nowrap' }}>{data?.banner_offer_header}</p>
                        </div>
                        {list?.map((val, key) => {
                            return (
                                val?.vehicle_lease_data !== null && val?.link_to_page_id === 3 &&
                                <div className='mb-lg-3 mb-2 d-flex align-items-center' key={key}>
                                    <span className="font-midium content-font font-black" dangerouslySetInnerHTML={{ __html: getVehicleInfo(val) }} />

                                </div>
                            )
                        })}

                        {/* {data?.vehicle_incentive_data !== null && isEmpty(data?.special_program) && !isEmpty(data?.eligibility_requirement_text) && data?.incentive_type !== 'Retail Incentive' && data?.incentive_type !== 'Manufacturer Incentive'  &&
                            <div className='my-4 d-flex align-items-center'>
                                <span className="font-midium content-font font-black">{`${!isEmpty(data?.eligibility_requirement_text) && data?.eligibility_requirement_text}`}</span>
                            </div>} */}

                        {/* <div className="">
                            {list?.map((val, key) => {
                                    return (val?.vehicle_incentive_data !== null &&
                                        <div className='d-flex mb-1 justify-content-between banner-popup-explore-div' style={{ width: "350px" }} key={key}>
                                            <div>
                                                <span className="font-large">{val?.vehicle_data?.model_year} </span>&nbsp;
                                                <span className="me-5 font-midium ">{val?.vehicle_data?.model}</span>
                                            </div>
                                            <div>
                                                <span className="ms-5 font-large">{val?.vehicle_incentive_data?.incentive_amount}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div> */}
                        <div className="">

                            {list?.map((val, key) => {
                                return (
                                    <Fragment>
                                        {val?.vehicle_incentive_data !== null &&
                                            <div className="d-flex align-items-center justify-content-start py-2" key={key}>
                                                {/* <div className='d-flex me-md-5 mb-1 justify-content-between banner-popup-explore-div align-items-center' style={{ width: "350px" }}>
                                                    <div>
                                                        <span className="font-large">{val?.vehicle_data?.make}</span>
                                                    </div>
                                                    <div className="ms-md-3">
                                                        <span className="font-large">{val?.vehicle_data?.model}</span>
                                                    </div>
                                                    <div>
                                                        <span className="ms-md-5 font-large">{val?.vehicle_incentive_data?.incentive_amount}</span>
                                                    </div>
                                                </div> */}
                                                <div className='d-flex me-md-5 mb-1 align-items-center' style={{ width: isVehicleSame ? "350px" : "200px" }}>
                                                    {isVehicleSame ? <div className="text-start w-75">
                                                        <span className="font-large">{val?.vehicle_incentive_data?.incentive_name}</span>
                                                    </div> :
                                                    <div className="text-start w-75">
                                                        <span className="font-large">{val?.vehicle_data?.make}</span>
                                                    </div>}
                                                    <div className="text-start w-25">
                                                        <span className="font-large">{val?.vehicle_incentive_data?.incentive_amount}</span>
                                                    </div>
                                                </div>

                                                <div className="ms-5">
                                                    {val?.vehicle_incentive_data !== null && val?.link_to_page_id === 3 &&
                                                        <Link target="_blank" className="btn-learn-more font-large text-decoration-none d-flex align-items-center me-5 cursor-pointer" to={`/detail/${CryptoJS.MD5(val?.vehicle_data?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex)}/${createSeoFriendlyUrl(val?.vehicle_data?.make)}/${createSeoFriendlyUrl(val?.vehicle_data?.model)}/${val?.vehicle_data?.model_year}/${createSeoFriendlyUrl(val?.vehicle_data?.trim)}?type=` + (val?.vehicle_incentive_data?.purchase_type === 'Lease' ? 'lease' : val?.vehicle_incentive_data?.purchase_sub_type === 'Finance' ? 'finance' : 'cash')} key={key}>
                                                            Explore
                                                        </Link>}
                                                </div>
                                            </div>}
                                    </Fragment>
                                );
                            })}
                        </div>
                        <div className={'d-flex div-wrap align-items-center banner-popup-explore-div ' + (((list?.[0]?.vehicle_lease_data === null && list?.[0]?.link_to_page_id === 3 && list?.[0]?.vehicle_incentive_data === null) || (list?.[0]?.vehicle_lease_data !== null && list?.[0]?.link_to_page_id === 3 && list?.[0]?.vehicle_incentive_data === null)) && 'mt-4 mb-4')}>
                            {list?.map((val, key) => {
                                return (
                                    val?.vehicle_lease_data === null && val?.link_to_page_id === 3 && val?.vehicle_incentive_data === null ?
                                        <Link target="_blank" className="btn-learn-more font-large text-decoration-none d-flex align-items-center banner-offer-explore-btn me-5 mt-2 cursor-pointer" to={`/detail/${CryptoJS.MD5(val?.vehicle_data?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex)}/${createSeoFriendlyUrl(val?.vehicle_data?.make)}/${createSeoFriendlyUrl(val?.vehicle_data?.model)}/${val?.vehicle_data?.model_year}/${createSeoFriendlyUrl(val?.vehicle_data?.trim)}`} key={key}>
                                            {`Explore ${val?.vehicle_data?.make} ${val?.vehicle_data?.model}`}

                                        </Link> : val?.vehicle_lease_data !== null && val?.link_to_page_id === 3 && val?.vehicle_incentive_data === null && (<Link target="_blank" className="btn-learn-more font-large text-decoration-none d-flex align-items-center banner-offer-explore-btn me-5 mt-2 cursor-pointer" to={`/detail/${CryptoJS.MD5(val?.vehicle_data?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex)}/${createSeoFriendlyUrl(val?.vehicle_data?.make)}/${createSeoFriendlyUrl(val?.vehicle_data?.model)}/${val?.vehicle_data?.model_year}/${createSeoFriendlyUrl(val?.vehicle_data?.trim)}?term=${val?.vehicle_lease_data?.lease_term}&mileage=${val?.vehicle_lease_data?.annual_mileage}&type=lease`} key={key}>
                                            {`Explore ${val?.vehicle_data?.make} ${val?.vehicle_data?.model}`}
                                        </Link>)
                                )
                            })}
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default BannerOfferPopup;