import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent";
import 'react-loading-skeleton/dist/skeleton.css';

const IFMobileLoader = () => {
    const loopCount = 5;
    return (
        <Fragment>
            {[...Array(loopCount)].map((x, i) => {
                return (
                    <div className="px-2">
                        <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row" />
                    </div>);
            })}
        </Fragment>
    );
}

export default withRouter(IFMobileLoader);