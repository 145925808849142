import React, { Fragment, useState, useEffect, useRef } from "react";
import brandimg from '../assets/img/brand-logo.png'
import brandimgBlack from '../assets/img/logo_green 1.png'
import userimg from '../assets/img/UserIcon1.png'
import { Nav, Navbar, Container, Offcanvas } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AuthButton } from "../components/AuthButton";
import BannerOfferPopup from "../components/BannerOfferPopup";
import BannerOfferListPopup from "../components/BannerOfferListPopup";
import * as helper from "../helper/sessionStorage";
import { useDispatch } from "react-redux";
import { updateUserDetails, updateAuthPoupState } from "../store/action";
import { useAuth } from "../components/hooks/useAuth";
import { handleDragStart, handleContextMenu, defaultPopUpState } from "../helper/project_helper";
import { useMyHistory } from "../components/hooks/useMyHistory";
import * as apiHelper from "../helper/api_helper";

export const Header = () => {

    const [isLogin, userData] = useAuth();
    const location = useLocation();
    const history = useMyHistory();
    const [profilePopup, setProfilePopup] = useState(false);
    const [bannerOfferList, setbannerOfferList] = useState([]);

    const [bannerOfferCurrentIndex, setBannerOfferCurrentIndex] = useState(0);
    const [bannerOfferLearnMoreCurrentIndex, setBannerOfferLearnMoreCurrentIndex] = useState(null);
    const [inTransition, setInTransition] = useState(false);
    const [bannerOfferPopup, setBannerOfferPopup] = useState(false);
    const [bannerExpand, setBannerExpand] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [zipCode, setZipCode] = useState('');
    const divdragRef = useRef(null);
    const divoutRef = useRef(null);

    const dispatch = useDispatch();

    const hideShowProfilePopUp = (close = false) => {
        if (close === true) {
            setProfilePopup(false);
        } else {
            setProfilePopup(!profilePopup);
        }
    }

    const onSignOut = () => {
        helper.clearLoginUserData();
        dispatch(updateUserDetails(false));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }));
        hideShowProfilePopUp();
        // history.push('/', true);
    }

    const handleBannerLearnMoreClick = (data) => {
        if (data === false) {
            setInTransition(true);
            setTimeout(() => {
                setBannerOfferCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % bannerOfferList.length
                );
                setInTransition(false); // Start fade-in animation
            }, 1000);
        }
        if (bannerOfferCurrentIndex !== null) {
            setBannerOfferLearnMoreCurrentIndex(null);
        }
        setBannerOfferPopup(data);
    }
    const handleBannerListLearnMoreClick = (data) => {
        setShowDropdown(false);
        setBannerOfferLearnMoreCurrentIndex(data);
    }

    const handleCopy = (event) => {
        event.preventDefault(); // Prevent copy
    };

    useEffect(() => {
        // Add the event listener
        document.addEventListener('copy', handleCopy);

        // Cleanup the event listener when component unmounts
        return () => {
            document.removeEventListener('copy', handleCopy);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(location.pathname === '/')
        {
            getBannerOffer({ page_id: "1" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getBannerOffer(params = {}) {
        // setLoading(true);
        try {
            const response = await apiHelper.postBannerOffer(params);
            if (response?.data?.status === 1) {
                setbannerOfferList(response?.data?.body);
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            console.error(error);
            // setLoading(false);
        }
    }

    useEffect(() => {

        const unlisten = history.listen(() => {
            setProfilePopup(false);
        });

        return () => {
            unlisten();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        if (showDropdown) return;
        if (bannerOfferPopup) return;
        if (bannerOfferList.length === 0) return;

        const intervalId = setInterval(() => {
            if (!bannerOfferPopup && !showDropdown) {
                setInTransition(true); // Start fade-out animation
            }
            setTimeout(() => {

                // Switch to the next text after fade-out
                setBannerOfferCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % bannerOfferList.length
                );
                setInTransition(false); // Start fade-in animation
            }, 1000); // 1-second fade-out duration
        }, 10000); // 10s visible + 1s fade-out + 1s fade-in

        return () => clearInterval(intervalId); // Cleanup on component unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerOfferList.length, bannerOfferPopup, showDropdown]);


    const handleHideShow = () => {

        if (showDropdown === true) {
            setInTransition(true);
            setTimeout(() => {
                setBannerOfferCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % bannerOfferList.length
                );
                setInTransition(false); // Start fade-in animation
            }, 1000);
        }
        setShowDropdown(!showDropdown);
    };


    const handleClickOutside = (event) => {

        if (showDropdown === true) {

            if (divdragRef.current && !divdragRef.current.contains(event.target) && !event.target.closest(`.expand-banner-btn`)) {
                handleHideShow();
            }

        }
        if (divoutRef.current && !divoutRef.current.contains(event.target) && !event.target.closest(`.out-close`) && !event.target.closest(`.user-profile`)) {
            hideShowProfilePopUp(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside); // Clean up the event listener when the component unmounts 
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown, profilePopup]);
    return (
        <div className="" style={{ position: "relative" }}>
            {location.pathname === '/' && bannerOfferList && bannerOfferList.length > 0 ?
                <div className="banner-offer">
                    {bannerOfferPopup && <BannerOfferPopup data={bannerOfferList[bannerOfferCurrentIndex]} handleBannerLearnMoreClick={handleBannerLearnMoreClick} />}
                    {bannerOfferLearnMoreCurrentIndex !== null && <BannerOfferPopup data={bannerOfferList[bannerOfferLearnMoreCurrentIndex]} handleBannerLearnMoreClick={handleBannerLearnMoreClick} />}
                    {showDropdown && (
                        <BannerOfferListPopup
                            divRef={divdragRef}
                            handleHideShow={handleHideShow}
                            handleBannerListLearnMoreClick={handleBannerListLearnMoreClick}
                            bannerOfferList={bannerOfferList}
                        />
                    )}
                    <Container fluid>
                        <div className={"fade rotate-text-container font-large " + (inTransition ? "in-transition" : "display")}>
                            {/* <div className={"fade rotate-text-container display"}> */}
                            {!loading && (
                                <Fragment>
                                    <span className="animated-text" id="animated-text">
                                        {bannerOfferList[bannerOfferCurrentIndex]?.banner_offer_header}&nbsp;
                                    </span>
                                    <span className="banner-learn-more font-midium green-hover-text font-wx text-nowrap" onClick={() => handleBannerLearnMoreClick(true)}>Learn More</span>
                                </Fragment>
                            )}
                        </div>
                    </Container>
                    <div className="cursor-pointer expand-banner-btn me-4" onClick={handleHideShow} variant="primary" >
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 0.75C0 0.335786 0.335786 0 0.75 0H15.25C15.6642 0 16 0.335786 16 0.75C16 1.16421 15.6642 1.5 15.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75Z" fill="black" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 4.25C0 3.83579 0.335786 3.5 0.75 3.5H15.25C15.6642 3.5 16 3.83579 16 4.25C16 4.66421 15.6642 5 15.25 5H0.75C0.335786 5 0 4.66421 0 4.25Z" fill="black" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.75C0 7.33579 0.335786 7 0.75 7H15.25C15.6642 7 16 7.33579 16 7.75C16 8.16421 15.6642 8.5 15.25 8.5H0.75C0.335786 8.5 0 8.16421 0 7.75Z" fill="black" />
                            <path d="M8.44158 15.1454C8.2472 15.447 7.80779 15.4517 7.60696 15.1544L5.03057 11.3398C4.80765 11.0097 5.04128 10.5642 5.43953 10.5599L10.5095 10.5053C10.9078 10.501 11.151 10.9414 10.9352 11.2762L8.44158 15.1454Z" fill="black" />
                        </svg>
                    </div>

                </div> : <Fragment></Fragment>}

            <Navbar expand="lg" className={"header-set " + ( location.pathname === '/' && "header-gb")} style={{ position: "relative" }}>
                <Container fluid className={"px-md-5 px-3"}>
                    <a className="brand-logo" href="/">
                        <Navbar.Brand>
                            <img className="logoimg" src={location.pathname.indexOf("incentivesummary") === 1 || location.pathname.indexOf("detail") === 1 || location.pathname.indexOf("account") === 1 || location.pathname.indexOf("comparevehicles") === 1 || location.pathname.indexOf("incentivefinder") === 1 || location.pathname.indexOf("privacypolicy") === 1 || location.pathname.indexOf("Basic404") === 1 ? brandimgBlack : brandimg} alt="" />
                        </Navbar.Brand>
                    </a>
                    <AuthButton className="button-set user-profile" onLogin={(s) => {
                        if (isLogin) {
                            hideShowProfilePopUp();
                        }
                    }}>
                        <Nav className="border-0 user" style={{ position: "relative" }}>
                            {isLogin ? <div className="user-profile-letter-header d-flex align-items-center justify-content-center">
                                <p style={{ fontSize: "20px" }}>{userData?.first_name?.substring(0, 1)?.toUpperCase()}</p>
                            </div> : <img
                                onContextMenu={handleContextMenu}
                                onDragStart={handleDragStart}
                                src={userimg} alt=""
                            />}
                        </Nav>
                        {isLogin &&
                            <Fragment>
                                {!profilePopup &&
                                    <div className="account-detail mx-4">
                                        <div>
                                            <p className="font-large font-w4 font-white">{userData?.first_name} {userData?.last_name}</p>
                                        </div>
                                    </div>
                                }

                            </Fragment>
                        }
                    </AuthButton>
                    {profilePopup &&
                        <div id="profilePopup out-close" class="popup mt-2 mx-4" ref={divoutRef}>
                            <div class="popup-content">
                                {/* <span class="close-btn">&times;</span> */}
                                <div className='popup-close cursor-pointer pt-1'>
                                    <svg onClick={() => hideShowProfilePopUp(true)} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                </div>
                                <div class="profile-popup-email">{userData?.email}</div>
                                <div class="profile-icon">{userData?.first_name?.substring(0, 1)?.toUpperCase()}</div>
                                <div class="profile-name">{userData?.first_name} {userData?.last_name}</div>
                                <div class="actions">
                                    {/* Manage Account Button with Icon */}
                                    <button class="manage-btn font-white" onClick={() => {
                                        history?.push("/account", true);
                                    }}>
                                        <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#fff">
                                            <path d="M3 14C3 14 2 14 2 13C2 12 3 9 8 9C13 9 14 12 14 13C14 14 13 14 13 14H3ZM8 8C8.79565 8 9.55871 7.68393 10.1213 7.12132C10.6839 6.55871 11 5.79565 11 5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5C5 5.79565 5.31607 6.55871 5.87868 7.12132C6.44129 7.68393 7.20435 8 8 8Z" fill="#fff" />
                                        </svg>Manage Account
                                    </button>
                                    {/* Sign Out Button with Icon  */}
                                    <button class="signout-btn font-white" onClick={onSignOut}>
                                        <svg className="profile-popup-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                                        </svg>&nbsp; Sign Out
                                    </button>
                                </div>
                            </div>
                        </div>}
                    {/* <Link to=""></Link> */}
                    <Nav className="border-0 dots" href="#action2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" fill="#000" width='4px'><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" /></svg></Nav>
                </Container>
            </Navbar>
        </div >
    );
}
