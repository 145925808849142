import React from "react";
import { Redirect } from "react-router-dom"; // Import Redirect if using React Router v5 or below
import EVIncentiveFinder from "../pages/EVIncentiveFinder";
import EVIncentiveDetail from "../pages/EVIncentiveDetail";
import IncentiveDetailSearch from "../pages/IncentiveDetailSearch";
import CompareVehicles from "../pages/CompareVehicles";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactUs from "../pages/ContactUs";
import Account from "../pages/Account";
import Basic404 from "../pages/Basic404";
import Pagination from "../components/Pagination";
import RefereshCache from "../pages/RefereshCache";

const authProtectedRoutes = []

const publicRoutes = [
    { path: "/404", component: Basic404 },
    // { path: "/unauthorized", component: Unauthorized },
    { path: "/detail/:purchase_type/:vehicle_id_hash////", component: EVIncentiveDetail },
    { path: "/detail/:vehicle_id_hash/:make/:model/:model_year/:trim?", component: EVIncentiveDetail },
    // { path: "/detail/:purchase_type/:vehicle_id_hash/:make/:model/:model_year/:trim?/", component: EVIncentiveDetail},
    {
        path: "/detail/:purchase_type/:vehicle_id_hash/:make/:model/:model_year/:trim?/",
        component: () => <Redirect to="/" />,
    },
    { path: "/incentivefinder/:vehicle_id_hash?/:make?/:model?/:model_year?/:trim?", component: IncentiveDetailSearch },
    {
        path: "/incentivefinder/:purchase_type/:vehicle_id_hash?/:make?/:model?/:model_year?/:trim?",
        component: () => <Redirect to="/" />,
    },
    {
        path: "/contactus",
        component: () => <Redirect to="/contact" />,
    },
    // { path: "/incentivefinder/:purchase_type?/:vehicle_id_hash?/:make?/:model?/:model_year?/:trim?", component: IncentiveDetailSearch },
    { path: "/comparevehicles/:compare_vehicles", component: CompareVehicles },
    { path: "/privacypolicy", component: PrivacyPolicy },
    { path: "/contact", component: ContactUs },
    { path: "/account", component: Account },
    { path: "/RefereshCache", component: RefereshCache },
    { path: "/Pagination", component: Pagination },
    {
        path: "/",
        exact: true,
        component: EVIncentiveFinder,
    },
    // Redirect route
    {
        path: "/incentivedetail",
        exact: true,
        component: () => <Redirect to="/incentivefinder" />,
    },
    // {
    //     path: "/",
    //     exact: true,
    //     component: IncentiveFinderByVehicle,
    // },
];

export { authProtectedRoutes, publicRoutes };
