import React, { Fragment } from "react";
import { Row, Col, Form, Container } from 'react-bootstrap';
import NewIncentiveFinderByVehicleTable from './NewIncentiveFinderByVehicleTable';
import NewIncentiveFinderByRequirements from './NewIncentiveFinderByRequirements';

import { EVIncentiveFinderFilters } from "../../components/EVIncentiveFinderFilters";
import { withRouter } from "react-router-dom";
import { isEmpty, DateFormatterLastUpdated } from "../../helper/project_helper";

import Pagination from "../../components/Pagination";

const DesktopView = ({
	setFilter,
	setFilterState,
	setFirstLoading,
	setExpandedRows,
	setNotFound,
	incentives,
	loading,
	notFound,
	filterState,
	expandedRows,
	filter,
	reset,
	onClickCompareVehicle,
	history,
	handleCompareCheck,
	compareVehicles,
	getCompareValue,
	valueCompareCheck,
	onChangeCompareCheck,
	compareVehiclesError,
	tooltipText,
	lastUpdatedDate,
	quizView,
	quizFilter,
	onUpdateQuizFilter,
	handleShowMeOptionClick,
	quizFilterState,
	shortBy,
	disableFilter,
	onChangeShort,
	eligible,
	onChangeEligible,
	is0Apr,
	onChangeIs0Apr,
	setOffset,
	offset,
	handlePageClick,
	stateIncentives,
	msg,
	selectedModelYear,
	setSelectedSegments,
	setSelectedModelYear,
	selectedSegments,
	stateEVIncentiveFinderFilters
}) => {

	return (
		<Fragment>
			<section className="desktop-view">
				<Container fluid>
					<div className="px-5 pt-3 banner-toggle-section">
						<p className="font-px font-color text-center mb-1">Welcome! To get started, simply answer the following questions or browse the electric vehicles of your choice below.</p>
						<div className="py-4">
							<Row className="align-items-center">
								<Col lg={5}>
									<div className="">
										<p className="font-large font-w font-black mb-1">What is your approximate monthly budget?</p>
										<div className="option-select">
											<Form.Check
												inline
												checked={quizFilter?.budget === '1'}
												label="Under $500"
												name="budget"
												type="radio"
												className=""
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}
												value={1}
											/>
											<Form.Check
												inline
												value={2}
												checked={quizFilter?.budget === '2'}
												label="$500-$1,000"
												name="budget"
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}

												type="radio"
											/>
											<Form.Check
												inline
												value={3}
												checked={quizFilter?.budget === '3'}
												label="Above $1,000"
												name="budget"
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}

												type="radio"
											/>
											<Form.Check
												inline
												value="any"
												checked={quizFilter?.budget === 'any'}
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}
												label="Any"
												name="budget"
												type="radio"
											/>
										</div>
									</div>
								</Col>
								<Col lg={5}>
									<div className="">
										<p className="font-large font-w font-black mb-1">How do you want to purchase your vehicle?</p>
										<div className="option-select">
											<Form.Check
												inline
												label="Finance"
												checked={quizFilter?.purchase_type === 'Finance'}
												onChange={(x) => onUpdateQuizFilter('purchase_type', x.target.value)}
												value={"Finance"}
												name="purchase_type"
												type="radio"
												className=""
											/>
											<Form.Check
												inline
												label="Lease"
												checked={quizFilter?.purchase_type === 'Lease'}
												onChange={(x) => onUpdateQuizFilter('purchase_type', x.target.value)}
												value={"Lease"}
												name="purchase_type"
												type="radio"
											/>
											<Form.Check
												inline
												onChange={(x) => onUpdateQuizFilter('purchase_type', x.target.value)}
												label="Any"
												checked={quizFilter?.purchase_type === 'any'}
												value={"any"}
												name="purchase_type"
												type="radio"
											/>
										</div>
									</div>
								</Col>
								<Col lg={2}>
									<div>
										<button className="text-decoration-none font-color font-large options-btn font-wxl green-hover-text" onClick={handleShowMeOptionClick}>Show Me Options</button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Container>
				<div className="d-flex px-5 py-3">
					<div className="hr"></div>
				</div>
				<Row className="row-bg mx-5" id="scroll_here">
					<Col lg={3} md={3} className="pe-4 ps-0" style={{ position: "sticky", top: "0px", zIndex: "10", alignSelf: "flex-start" }} >
						<div className="px-3 pt-4" style={{ background: "#fff" }}>
							<EVIncentiveFinderFilters
								value={filterState}
								onChange={setFilterState}
								onSearch={(dd) => {
									setFilter(dd);
									setFilterState(dd);
									setFirstLoading(false);
								}}
								reset={reset}
								lastFilter={filter}
								onClickCompareVehicle={onClickCompareVehicle}
								compareVehicles={compareVehicles}
								compareVehiclesError={compareVehiclesError}
								loading={loading}
								disableFilter={disableFilter}
								selectedModelYear={selectedModelYear}
								setSelectedSegments={setSelectedSegments}
								setSelectedModelYear={setSelectedModelYear}
								selectedSegments={selectedSegments}
							/>
						</div>
					</Col>
					<Col lg={9} md={9} >
						<div className=" table-body-color right-bar" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
							{!quizView ?
								<NewIncentiveFinderByVehicleTable incentives={incentives} loading={loading} notFound={notFound} onSavingClick={(dd) => {
									setExpandedRows(dd);
								}}
									expandedRows={expandedRows}
									handleCompareCheck={handleCompareCheck}
									compareVehicles={compareVehicles}
									getCompareValue={getCompareValue}
									valueCompareCheck={valueCompareCheck}
									onChangeCompareCheck={onChangeCompareCheck}
									tooltipText={tooltipText}
									shortBy={shortBy}
									onChangeShort={onChangeShort}
									msg={msg}
									eligible={eligible}
									onChangeEligible={onChangeEligible}
									is0Apr={is0Apr}
									onChangeIs0Apr={onChangeIs0Apr}
									stateEVIncentiveFinderFilters={stateEVIncentiveFinderFilters}
								/>
								:
								<NewIncentiveFinderByRequirements incentives={incentives} loading={loading} notFound={notFound} onSavingClick={(dd) => {
									setExpandedRows(dd);
								}}
									expandedRows={expandedRows}
									handleCompareCheck={handleCompareCheck}
									compareVehicles={compareVehicles}
									getCompareValue={getCompareValue}
									valueCompareCheck={valueCompareCheck}
									onChangeCompareCheck={onChangeCompareCheck}
									tooltipText={tooltipText}
									quizFilter={quizFilter}
									quizFilterState={quizFilterState} shortBy={shortBy}
									onChangeShort={onChangeShort}
									msg={msg}
									eligible={eligible}
									onChangeEligible={onChangeEligible}
									is0Apr={is0Apr}
									onChangeIs0Apr={onChangeIs0Apr}
									stateEVIncentiveFinderFilters={stateEVIncentiveFinderFilters}
								/>}
						</div>
						{stateIncentives?.total_without_limit > 10 && quizView &&
							< div className="pagination mt-2">
								<Pagination
									itemsPerPage={10}
									total={stateIncentives?.total_without_limit}
									offset={offset}
									setOffset={setOffset}
									handlePageClick={handlePageClick}
								/>
							</div>
						}
					</Col>
				</Row>
				{!isEmpty(lastUpdatedDate) &&
					<Row className="pt-5 pb-2 px-5 last-updated-date">
						<Col>
							<label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(lastUpdatedDate)}</label>
						</Col>
					</Row>
				}

			</section >
		</Fragment >
	)
};

export default withRouter(DesktopView);