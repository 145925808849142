
// Actions
export const EV_INCENTIVE_DETAIL = "EV_INCENTIVE_DETAIL";
export const SET_EV_INCENTIVE_DETAIL_LOADING = "SET_EV_INCENTIVE_DETAIL_LOADING";
export const SET_EV_INCENTIVE_DETAIL_VEHICLE_LOADING = "SET_EV_INCENTIVE_DETAIL_VEHICLE_LOADING";
export const SET_EV_INCENTIVE_DETAIL_BUY_LOADING = "SET_EV_INCENTIVE_DETAIL_BUY_LOADING";
export const SET_EV_INCENTIVE_DETAIL_LEASE_LOADING = "SET_EV_INCENTIVE_DETAIL_LEASE_LOADING";
export const UPDATE_EV_INCENTIVE_DETAIL_DATA = "UPDATE_EV_INCENTIVE_DETAIL_DATA";
export const UPDATE_INCENTIVE_DATA_DATA = "UPDATE_INCENTIVE_DATA_DATA";
export const UPDATE_FINANCE_INCENTIVE_DATA_DATA = "UPDATE_FINANCE_INCENTIVE_DATA_DATA";
