import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({
    itemsPerPage,
    total,
    setOffset,
    offset,
    handlePageClick
}) => {

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = offset + itemsPerPage;
    const pageCount = Math.ceil(total/ itemsPerPage);
    const selectedPage = offset === 0 ? 0 : (offset / itemsPerPage);

    return (
        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel={
                    <svg width={16} height={16} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down cursor-pointer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                        </path>
                    </svg>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={
                    <svg width={16} height={16} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down cursor-pointer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                        </path>
                    </svg>
                }
                renderOnZeroPageCount={null}
                handlePageClick={handlePageClick}
                forcePage={selectedPage}
            />
        </>
    );

};

export default Pagination;