import React, { Fragment } from "react";

const BannerOfferListPopup = ({
    bannerOfferList,
    divRef,
    handleBannerListLearnMoreClick,
    handleHideShow
}) => {


    return (
        <Fragment>
            <div className="fade modal-backdrop show"></div>
            <div className="login-popups banner-offer-popup banner-offer-list-popup" ref={divRef}>
                <section className='px-lg-4 banner-detail-popup banner-popup-mobile'>
                    <div className="text-end filter-sicky bg-banner pt-lg-2">
                        <button className="button-set" onClick={handleHideShow}>
                            <svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                        </button>
                    </div>
                    <div className="dropdown-content">
                        {bannerOfferList?.map((item_val, item_key) => {
                            return (
                                <div className="d-flex align-items-baseline mb-lg-4 mb-3" key={item_key}>
                                    <svg className="me-3" width={7} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#000"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" /></svg>
                                    <p className="font-color font-17 font-pop-m text-list">{item_val?.banner_offer_header} <span className="banner-learn-more font-15 font-pop-l green-hover-text font-wx text-nowrap" onClick={() => handleBannerListLearnMoreClick(item_key)}>Learn More</span></p>
                                </div>
                            )
                        })}
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default BannerOfferListPopup;