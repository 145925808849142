import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent"
import { Row, Col, Container, Form, Button, CloseButton } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';

const IDSLoaderMobile = ({
    hideFilter = false
}) => {
    const loopCount = 1;
    return (
        <Fragment>
            {/* <div className={"table-responsive height-100"}>
                <div className="table-striped w-100 table-hover fixed-table">
                    <div className="s-NewTbody">
                        {[...Array(loopCount)].map((x, i) => {
                            return (
                                <Fragment key={i}>
                                    <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row"/>
                                </Fragment>
                            );
                        })
                        }
                    </div>
                </div>
            </div> */}
            <div className="bg-incentive-detail-top">
                <div className="text-p-detaile-new" style={{ alignContent: 'center' }}>
                    <h1 class="font-white font-wx m-0 idf-title" style={{ fontSize: "56px", paddingBottom: "0px" }}>Incentive Finder</h1>
                </div>
            </div>
            <div class="mx-3"><p class="pb-4 pt-2 font-midium font-wxl font-black">Search by zip code and vehicle for the full list of Manufacturer, Federal, State, Local, and Utility incentives available for your location.</p><div class="d-flex justify-content-between align-items-center"><button type="button" class="btn-learn-more size-change btn btn-primary">Search</button></div></div>
            <div>
                <h5 class="my-4 font-w font-color search-datile font-midium py-2 px-3">Vehicle Incentives</h5>
            </div>
            <div className="px-2">
                <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row" />
            </div>
            <h5 class="my-4 font-w font-px search-datile font-w font-color font-midium py-2 px-3">Charging Incentives</h5>
            <div className="px-2">
                <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row" />
            </div>
        </Fragment>
    );
}

export default withRouter(IDSLoaderMobile);