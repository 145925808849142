import { Row, Col, Nav, Tab } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import LeaseIncentiveSection from "./LeaseIncentiveSection";
import BuyIncentiveSection from "./BuyIncentiveSection";
import FinanceIncentiveSection from "./FinanceIncentiveSection";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SkeletonComponent from "../../components/SkeletonComponent";
import { isEmpty } from '../../helper/project_helper';


const TabSection = ({
    incentiveBuyDetailData,
    incentiveLeaseDetailData,
    incentiveFinanceDetailData,
    filter,
    setFilter,
    incentiveAmount,
    setIncentiveAmount,
    financeIncentiveAmount,
    setFinanceIncentiveAmount,
    handleHideShowClick,
    expandedRows,
    tooltipText,
    onReset,
    currentTab,
    setCurrentTab,
    askLogin,
    setExpandedRows,
    settotalSavingAmount,
    setSpecialIncentives,
    setFinanceSpecialIncentives,
    financeSpecialIncentives,
    specialIncentives,
    totalSavingAmount,
    incentiveCheckState,
    updateIncentiveState,
    updateFinanceIncentiveState,
    isLoading,
    isLogin,
    settotalFinanceSavingAmount,
    totalFinanceSavingAmount,
    changeLoading
}) => {

    const [blurleaseDisabled, setBlurLeaseDisabled] = useState(false);

    useEffect(() => {

        setBlurLeaseDisabled(incentiveLeaseDetailData?.setLeaseDisable);

    }, [incentiveLeaseDetailData?.setLeaseDisable]);

    return (
        <section className="mx-lg-5 mx-3 mt-2">

            <Tab.Container id="left-tabs-example" defaultActiveKey={currentTab} activeKey={currentTab}>
                {(!isEmpty(incentiveFinanceDetailData?.finance?.make) && (!isLoading || !changeLoading)) ?
                    <Col lg={12} className='sticky-detail-tabs main-animation pb-5'>
                        <Nav variant="pills" className="flex-column">
                            <Row>
                                <Col lg={4} md={4} sm={4} xs={4}>
                                    <Nav.Item>
                                        <Nav.Link className='green-hover-text' eventKey="Cash"
                                            onClick={() => {
                                                setCurrentTab('Cash');
                                                setExpandedRows({});
                                            }}
                                        >Cash</Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={4}>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Finance"
                                            className={incentiveFinanceDetailData?.setFinanceDisable ? "blur-text " : "green-hover-text"}
                                            disabled={incentiveFinanceDetailData?.setFinanceDisable}
                                            onClick={() => {
                                                if (!incentiveFinanceDetailData?.setFinanceDisable) {
                                                    setCurrentTab('Finance');
                                                    setExpandedRows({});
                                                }
                                            }}
                                        >Finance</Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={4}>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={blurleaseDisabled ? "blur-text" : "green-hover-text"}
                                            disabled={blurleaseDisabled}
                                            eventKey="Lease"
                                            onClick={() => {
                                                if (!blurleaseDisabled) {
                                                    setCurrentTab('Lease');
                                                    setExpandedRows({});
                                                }
                                            }}
                                        >Lease
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                            </Row>
                        </Nav>
                    </Col>
                    : <Row className='pb-5'>
                        <Col lg={4}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-0-5" />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-0-5" />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-0-5" />
                            </div>
                        </Col>
                    </Row>
                }
                <Col lg={12}>
                    <Tab.Content>
                        <BuyIncentiveSection
                            buyDetail={incentiveBuyDetailData?.buy}
                            filter={filter}
                            isLogin={isLogin}
                            tax_filing_status={incentiveBuyDetailData?.tax_filing_status}
                            household_income={incentiveBuyDetailData?.household_income}
                            setUpdatedFilter={setFilter}
                            incentiveAmount={incentiveAmount}
                            setIncentiveAmount={setIncentiveAmount}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            tooltipText={tooltipText}
                            onReset={onReset}
                            chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                            settotalSavingAmount={settotalSavingAmount}
                            setSpecialIncentives={setSpecialIncentives}
                            specialIncentives={specialIncentives}
                            totalSavingAmount={totalSavingAmount}
                            incentiveCheckState={incentiveCheckState}
                            updateIncentiveState={updateIncentiveState}
                            isLoading={isLoading}
                            changeLoading={changeLoading}
                        />
                        <FinanceIncentiveSection
                            incentiveFinanceDetailData={incentiveFinanceDetailData}
                            filter={filter}
                            isLogin={isLogin}
                            tax_filing_status={incentiveBuyDetailData?.tax_filing_status}
                            household_income={incentiveBuyDetailData?.household_income}
                            setUpdatedFilter={setFilter}
                            incentiveAmount={financeIncentiveAmount}
                            setIncentiveAmount={setFinanceIncentiveAmount}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            tooltipText={tooltipText}
                            onReset={onReset}
                            chargingIncentives={incentiveFinanceDetailData?.finance?.chargingIncentives}
                            settotalSavingAmount={settotalFinanceSavingAmount}
                            setSpecialIncentives={setFinanceSpecialIncentives}
                            specialIncentives={financeSpecialIncentives}
                            totalSavingAmount={totalFinanceSavingAmount}
                            incentiveCheckState={incentiveCheckState}
                            updateFinanceIncentiveState={updateFinanceIncentiveState}
                            isLoading={isLoading}
                            changeLoading={changeLoading}
                        />
                        <LeaseIncentiveSection
                            leaseDetail={incentiveLeaseDetailData}
                            filter={filter}
                            setUpdatedFilter={setFilter}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            // chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                            chargingIncentives={incentiveLeaseDetailData?.vehicle_lease_data?.chargingIncentives}
                            tooltipText={tooltipText}
                            isLoading={isLoading}
                            changeLoading={changeLoading}
                        />
                    </Tab.Content>
                </Col>
            </Tab.Container>
        </section>
    )
}

export default withRouter(TabSection);