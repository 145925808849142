import React, { useEffect, Fragment } from "react";
import { Row, Col, Form, Tab } from 'react-bootstrap';
import checked from '../../assets/img/checked.svg'
import 'react-circular-progressbar/dist/styles.css';
import { withRouter, Link } from "react-router-dom";
import { NumberWithThousandSeparator, isEmpty, handleDragStart, handleContextMenu, createSeoFriendlyUrl } from "../../helper/project_helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IncentiveDetailPopup } from "../../components/IncentiveDetailPopup";
import CheckGreen from "../../components/WebComponent/CheckGreen";
import RedClose from "../../components/WebComponent/RedClose";
import MyLink from '../../components/MyLink';
import CryptoJS from 'crypto-js';
import SkeletonComponent from "../../components/SkeletonComponent";

const BuyIncentiveSection = ({
    buyDetail,
    filter,
    household_income,
    tax_filing_status,
    setUpdatedFilter,
    incentiveAmount,
    setIncentiveAmount,
    handleHideShowClick,
    expandedRows,
    tooltipText,
    onReset,
    history,
    chargingIncentives,
    settotalSavingAmount,
    setSpecialIncentives,
    specialIncentives,
    totalSavingAmount,
    incentiveCheckState,
    updateIncentiveState,
    isLoading,
    changeLoading,
    isLogin
}) => {

    const onUpdate = (name, value1) => {
        let value_ = { ...filter };
        value_[name] = value1;

        if (name === 'tax_filing_status') {
            if (isEmpty(value1)) {
                value_['household_income'] = '';
            }
        }
        setUpdatedFilter(value_);
    }

    useEffect(() => {
        if (incentiveAmount !== buyDetail?.federal_tax_credit_eligibility?.incentive_amount) {
            setIncentiveAmount(buyDetail?.federal_tax_credit_eligibility?.incentive_amount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incentiveAmount, buyDetail]);

    const currentHouseHoldIncome = household_income?.New?.[filter?.tax_filing_status ? filter.tax_filing_status : '1'];



    const onUpdateIncentiveState = (selected = [], other = []) => {
        updateIncentiveState({
            selectedIncentives: selected,
            otherIncentives: other
        });
    }

    useEffect(() => {
        let incentiveData = incentiveCheckState?.incentiveData;
        if (incentiveData.selectedIncentives) {
            onUpdateIncentiveState(incentiveData.selectedIncentives, incentiveData?.otherIncentives);
        } else {
            const otherInc = [];
            buyDetail?.specialIncentives?.forEach(incentivesVal => {
                otherInc.push(incentivesVal?.incentive_id);
            });
            onUpdateIncentiveState([], otherInc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyDetail?.specialIncentives])

    const onCheck = (e) => {
        const { value, checked } = e.target;
        const checkValues = value?.split('|');
        let incentiveData = incentiveCheckState?.incentiveData;
        const inc_id = parseInt(checkValues[1]);
        if (checked) {
            setSpecialIncentives(specialIncentives + parseFloat(checkValues[0]));
            onUpdateIncentiveState([...incentiveData.selectedIncentives, inc_id], incentiveData?.otherIncentives.filter(v => v !== inc_id));
        } else {
            setSpecialIncentives(specialIncentives - parseFloat(checkValues[0]));
            onUpdateIncentiveState(incentiveData?.selectedIncentives.filter(v => v !== inc_id), [...incentiveData?.otherIncentives, inc_id]);
        }
    }

    useEffect(() => {
        settotalSavingAmount(parseFloat(buyDetail?.total_saving_amount) + parseFloat(specialIncentives));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialIncentives, buyDetail]);
    useEffect(() => {

    })

    return (
        <Tab.Pane className="mt-2" eventKey="Cash">
            <Row className="g-4">
                <a className="btn-learn-more text-center d-none" id={'incentive_link'} target="_blank" href='' rel="noopener noreferrer" >
                </a>
                {!isEmpty(buyDetail?.make) && (!isLoading || !changeLoading) ?
                    <Col lg={6} className="main-animation">
                        <div className="main-text-detail d-flex flex-column justify-content-between p-3 white-bg full-height">
                            <div className="">
                                <div className="d-flex flex-column align-items-center top-b p-2">
                                    <div className="d-flex align-items-center justify-content-center mb-2">
                                        <h4 className="me-1 font-px m-0 font-w" style={{ opacity: "0.8" }}>Potential Savings</h4>
                                    </div>
                                    <h1 className="text-center font-wx font-black" style={{ fontSize: "56px" }}>${totalSavingAmount
                                        ? NumberWithThousandSeparator(totalSavingAmount)
                                        : 0}</h1>
                                    {totalSavingAmount > 0 &&
                                        <span className="text-font">*Subject to eligibility</span>
                                    }
                                </div>
                                <div className="top-b pt-3 pb-1">
                                    <div className="d-flex justify-content-between mb-3">
                                        <span className="font-w font-large font-Gunmetal">Base MSRP</span>
                                        <span className="font-large font-w font-black">${buyDetail?.base_msrp
                                            ? NumberWithThousandSeparator(buyDetail?.base_msrp)
                                            : 0}</span>
                                    </div>
                                    {
                                        buyDetail?.total_potential_savings != null &&
                                        buyDetail?.total_potential_savings?.map(
                                            (object, i) => {
                                                return (
                                                    <div style={{ position: "relative" }} key={i} className={((object?.is_combinable === false) ? "pb-4-5" : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between"} key={i}>
                                                            {(isEmpty(object?.incentive_type)) ?
                                                                <span
                                                                    className="font-Gunmetal font-large font-w4 replace-hyphen"
                                                                >{isEmpty(object?.incentive_name_text) ? object?.incentive_name : object?.incentive_name_text}</span> :
                                                                <div>
                                                                    <span
                                                                        className="font-Gunmetal all-incentive font-large font-w4"
                                                                        onClick={() => handleHideShowClick("potential_savings_" + i)}
                                                                        aria-disabled={isLoading}>
                                                                        {isEmpty(object?.incentive_name_text) ? object?.incentive_name : object?.incentive_name_text}</span>
                                                                    {object?.is_combinable === false &&
                                                                        <Fragment>
                                                                            <br />
                                                                            <span className="text-font p-absolute">Exclusions apply</span>
                                                                        </Fragment>
                                                                    }
                                                                    {/* {object?.is_included_in_monthly_payment === true &&
                                                                        <Fragment>
                                                                            <br />
                                                                            <span className="text-font p-absolute replace-hyphen margin-min">Applied to monthly payment</span>
                                                                        </Fragment>
                                                                    } */}
                                                                </div>
                                                            }
                                                            <span className="font-black font-large font-w4 replace-hyphen">{isEmpty(object?.incentive_amount_text) ? object?.incentive_amount : object?.incentive_amount_text}</span>
                                                        </div>
                                                        {expandedRows["potential_savings_" + i] &&
                                                            <div className={"detail-popup"}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick}
                                                                    IncentiveObject={object}
                                                                    specialIncentivesKey={"potential_savings_" + i} />
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        )}
                                </div>
                                <div className="top-b">
                                    <div className="d-flex justify-content-between mt-3 pb-3">
                                        <span className="font-w font-px font-Gunmetal">Price After Potential Savings</span>
                                        <span className="font-large font-w font-black">${buyDetail?.base_msrp
                                            ? totalSavingAmount ? NumberWithThousandSeparator(buyDetail?.base_msrp - totalSavingAmount) : NumberWithThousandSeparator(buyDetail?.base_msrp - 0)
                                            : 0}</span>
                                    </div>
                                </div>
                                {buyDetail?.specialIncentives && buyDetail?.specialIncentives.length > 0 &&
                                    <div className="pt-3 pb-2 top-b">
                                        <h6 className="m-0 mt-2 mb-1 incentiver-title">Special Programs</h6>
                                        {<p className="font-small font-wxl font-black my-2">Select any of these incentives below to include in your Potential Savings.</p>}
                                        {buyDetail?.specialIncentives?.map((specialIncentivesVal, specialIncentivesKey) => {
                                            return (
                                                <div style={{ position: "relative" }} className={((specialIncentivesVal?.is_combinable === false) ? "pb-4-5" : 'pb-3')} key={specialIncentivesKey}>
                                                    <div className="d-flex justify-content-between" key={specialIncentivesKey}>
                                                        <Form>
                                                            <div className="d-flex">
                                                                <Form.Check
                                                                    disabled={isLoading}
                                                                    id={`default-${specialIncentivesKey}`}
                                                                    checked={(incentiveCheckState?.incentiveData?.selectedIncentives && incentiveCheckState?.incentiveData?.selectedIncentives.includes(specialIncentivesVal?.incentive_id)) ? true : false}
                                                                    value={specialIncentivesVal?.saving_amount + "|" + specialIncentivesVal?.incentive_id}
                                                                    name={specialIncentivesVal?.incentive_name}
                                                                    className={"me-4 form-checkbox form-lable"}
                                                                    onChange={onCheck}
                                                                />
                                                                {
                                                                    <div>
                                                                        <label
                                                                            className="font-large all-incentive saving-detail font-w4 font-Gunmetal replace-hyphen green-hover-text"
                                                                            onClick={() => handleHideShowClick("other_incentive_" + specialIncentivesKey)}
                                                                            aria-disabled={isLoading}
                                                                        >{isEmpty(specialIncentivesVal?.incentive_name_text) ? specialIncentivesVal?.incentive_name : specialIncentivesVal?.incentive_name_text}</label>

                                                                        {specialIncentivesVal?.is_combinable === false &&
                                                                            <Fragment>
                                                                                <br />
                                                                                <span className="text-font p-absolute">Exclusions apply</span>
                                                                            </Fragment>
                                                                        }
                                                                        {/* {specialIncentivesVal?.is_included_in_monthly_payment === true &&
                                                                                <Fragment>
                                                                                    <br />
                                                                                    <span className="text-font p-absolute replace-hyphen margin-min">Applied to monthly payment</span>
                                                                                </Fragment>
                                            } */}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Form>
                                                        <span className="font-large font-w4 font-black replace-hyphen">{isEmpty(specialIncentivesVal?.incentive_amount_text) ? specialIncentivesVal?.incentive_amount : specialIncentivesVal?.incentive_amount_text}</span>
                                                    </div>
                                                    <div className={"detail-popup"}>
                                                        {expandedRows["other_incentive_" + specialIncentivesKey] &&
                                                            <IncentiveDetailPopup
                                                                tooltipText={tooltipText}
                                                                handleHideShowClick={handleHideShowClick} IncentiveObject={specialIncentivesVal} specialIncentivesKey={"other_incentive_" + specialIncentivesKey} />}
                                                    </div>


                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                <div className="d-flex flex-column mt-4 d-none">
                                    {(buyDetail?.vehicle_id && buyDetail?.vehicle_condition) &&
                                        <MyLink className="btn-learn-more text-center" to={"/incentivesummary/" + buyDetail?.vehicle_id + "/Buy/" + buyDetail?.vehicle_condition + "/" +
                                            (incentiveCheckState?.incentiveData?.otherIncentives?.join(",") === '' ? "0" : incentiveCheckState?.incentiveData?.otherIncentives?.join(",")) + "/" + (incentiveCheckState?.incentiveData?.selectedIncentives && incentiveCheckState?.incentiveData?.selectedIncentives.length > 0 ? incentiveCheckState?.incentiveData?.selectedIncentives?.join(",") : "0") + "/0/0" + (filter?.checkEligibility === true ? "/" + filter?.tax_filing_status + "/" + filter?.household_income : "")}>View Details</MyLink>
                                    }
                                </div>
                                {buyDetail?.promotionsIncentives && buyDetail?.promotionsIncentives?.length > 0 && (
                                    <div className={"pt-3 pb-2 top-b"}>
                                        <Fragment>
                                            <h6 className="m-0 mt-2 mb-1 incentiver-title">Promotions</h6>
                                            {buyDetail?.promotionsIncentives?.map((promotionsIncentivesValue, promotionsIncentivesKey) => {
                                                return (
                                                    <div style={{ position: "relative" }} key={promotionsIncentivesKey} className={((promotionsIncentivesValue?.is_combinable === false) ? "pb-4-5" : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <div className="detail-incentive-name">
                                                                <span
                                                                    className="font-large font-w4 font-Gunmetal text-decoration-underline detail-incentive-name all-incentive pe-5 cursor-pointer replace-hyphen green-hover-text"
                                                                    onClick={() => handleHideShowClick("promotions_incentive_" + promotionsIncentivesKey)}
                                                                    aria-disabled={isLoading}
                                                                >{isEmpty(promotionsIncentivesValue?.incentive_name_text) ? promotionsIncentivesValue?.incentive_name : promotionsIncentivesValue?.incentive_name_text}</span>
                                                                {promotionsIncentivesValue?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }
                                                                {/* {promotionsIncentivesValue?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute replace-hyphen margin-min">Applied to monthly payment</span>
                                                                    </Fragment>
                                                                } */}
                                                            </div>
                                                            <div className="detail-incentive-amount-div">
                                                                <span
                                                                    className="font-large font-w4 font-black detail-incentive-amount replace-hyphen"
                                                                >{isEmpty(promotionsIncentivesValue?.incentive_amount_text) ? promotionsIncentivesValue?.incentive_amount : promotionsIncentivesValue?.incentive_amount_text}</span>
                                                            </div>
                                                        </div>
                                                        {expandedRows["promotions_incentive_" + promotionsIncentivesKey] &&
                                                            <div className={"detail-popup"}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={promotionsIncentivesValue} specialIncentivesKey={"promotions_incentive_" + promotionsIncentivesKey} />
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}

                                        </Fragment>
                                    </div>
                                )}

                                {chargingIncentives && chargingIncentives.length > 0 && (
                                    <div className={"pt-3 pb-2 top-b"}>
                                        <Fragment>
                                            <h6 className="m-0 mt-2 mb-1 incentiver-title">Charging Incentives</h6>
                                            {chargingIncentives?.map((chargingIncentiveValue, chargingIncentivesKey) => {
                                                return (
                                                    <div style={{ position: "relative" }} key={chargingIncentivesKey} className={((chargingIncentiveValue?.is_combinable === false) ? "pb-4-5" : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <div className="detail-incentive-name">
                                                                <span
                                                                    className="font-large font-w4 font-Gunmetal text-decoration-underline detail-incentive-name all-incentive pe-5 cursor-pointer replace-hyphen green-hover-text"
                                                                    onClick={() => handleHideShowClick("charging_incentive_" + chargingIncentivesKey)}
                                                                    aria-disabled={isLoading}
                                                                >{isEmpty(chargingIncentiveValue?.incentive_name_text) ? chargingIncentiveValue?.incentive_name : chargingIncentiveValue?.incentive_name_text}</span>
                                                                {chargingIncentiveValue?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }
                                                                {/* {chargingIncentiveValue?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute replace-hyphen margin-min">Applied to monthly payment</span>
                                                                    </Fragment>
                                                                } */}
                                                            </div>

                                                            <div className="detail-incentive-amount-div">
                                                                <span
                                                                    className="font-large font-w4 font-black detail-incentive-amount replace-hyphen"
                                                                >{isEmpty(chargingIncentiveValue?.incentive_amount_text) ? chargingIncentiveValue?.incentive_amount : chargingIncentiveValue?.incentive_amount_text}</span>
                                                            </div>
                                                        </div>
                                                        {expandedRows["charging_incentive_" + chargingIncentivesKey] &&
                                                            <div className={"detail-popup"}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={chargingIncentiveValue} specialIncentivesKey={"charging_incentive_" + chargingIncentivesKey} />
                                                            </div>
                                                        }
                                                        {/* <div className="detail-popup">
                                         <IncentiveDetailPopup />
                                     </div> */}
                                                    </div>
                                                );
                                            })}

                                        </Fragment>
                                    </div>
                                )}
                            </div>
                            <span className="pt-4 pb-2 detail-search-link d-block align-items-center mt-3 font-midium">For the full list of State, Local, and Utility incentives that may be available for your location, visit&nbsp;
                                <Link className="text-dark btn-incentive-finder font-wx p-0 green-hover-text" to={"/incentivefinder/" + CryptoJS.MD5(buyDetail?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(buyDetail?.make) + "/" + createSeoFriendlyUrl(buyDetail?.model) + "/" + buyDetail?.model_year + "/" + createSeoFriendlyUrl(buyDetail?.trim) + "?type=cash"}>
                                    Incentive Finder
                                </Link><span className="small-space" style={{ marginLeft: '0px' }}>.</span></span>

                        </div>

                    </Col>
                    : <Col lg={6}>
                        <div className="">
                            <SkeletonComponent count={1} className="height-4" />
                        </div>
                    </Col>

                }
                {!isEmpty(buyDetail?.make) && (!isLoading || !changeLoading) ?
                    <Col lg={6} className="main-animation">
                        <div className="white-bg main-text-detail full-height">
                            <div className="px-3 pt-3 ">
                                <div className="">
                                    <h5 className="font-px font-w mb-2 font-black">Federal Tax Credit Eligibility Finder</h5>
                                    {
                                        <p className="font-midium font-w4 font-black mb-3">

                                            {buyDetail?.fed_tax_credit_amount < 1 && !filter?.checkEligibility ?

                                                "This vehicle does NOT qualify for Federal Tax Credit. For more information, check the requirements below."
                                                :
                                                filter?.checkEligibility ? buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0 ?
                                                    ""
                                                    : "You do NOT qualify for Federal Tax Credit. For more information, check the requirements below." :
                                                    "This vehicle qualifies for Federal Tax Credit. Enter your information below to find out if you qualify for the Credit."
                                            }
                                        </p>
                                    }
                                </div>
                                {(!filter?.checkEligibility && !isLoading && buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0) && (
                                    <Fragment>
                                        <div className={"d-flex align-items-center justify-content-between flex-wrap mt-3"}>
                                            <div className="d-flex align-items-baseline flex-wrap">
                                                <div className="me-3">
                                                    <span className="font-midium font-w font-color">Tax Filing Status</span>
                                                    <Form.Select
                                                        className="mt-2 mx-w font-small font-wxl font-color"
                                                        aria-label="Default select example"
                                                        defaultValue={filter?.tax_filing_status}
                                                        // value={filter?.tax_filing_status} 
                                                        onChange={(e) => onUpdate("tax_filing_status", e.target.value)}
                                                        disabled={isLoading}
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        {tax_filing_status?.map((tax_filing_status_val, tax_filing_status_key) => {
                                                            return (
                                                                <option value={tax_filing_status_val?.value} key={tax_filing_status_key}>
                                                                    {tax_filing_status_val?.text}
                                                                </option>
                                                            )
                                                        }
                                                        )}
                                                    </Form.Select>
                                                </div>
                                                <div>
                                                    <span className="font-midium font-wx font-color">Household Income</span>
                                                    <Form>
                                                        <div className="mb-3">
                                                            {currentHouseHoldIncome && (currentHouseHoldIncome?.map((household_income_val, keyIndex) => {
                                                                return (
                                                                    <Form.Check
                                                                        key={keyIndex}
                                                                        disabled={isEmpty(filter?.tax_filing_status) || isLoading}
                                                                        label={household_income_val?.text}
                                                                        className="radio-check font-color"
                                                                        value={household_income_val?.value}
                                                                        name="household_income"
                                                                        checked={filter?.household_income === household_income_val.value}
                                                                        onChange={(e) => onUpdate("household_income", e.target.value)}
                                                                    />
                                                                )
                                                            })
                                                            )}
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    disabled={isEmpty(filter?.tax_filing_status) || isEmpty(filter.household_income) || isLoading}
                                                    className="btn-learn-more text-decoration-none"
                                                    onClick={(e) => onUpdate("checkEligibility", true)}
                                                >
                                                    Check
                                                </button>
                                            </div>
                                        </div>
                                        <div className="text-end" style={{ marginTop: "-0.8rem" }}>
                                            <button
                                                className={"text-dark button-set text-decoration-none font-small font-w font-color link-button green-hover-text"}
                                                onClick={onReset}
                                                disabled={isLoading}
                                            >Reset</button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                            {isLoading ?
                                <Fragment>
                                    <SkeletonComponent count={1} className="height-1" />
                                    <SkeletonComponent count={1} className="height-0-5 mt-2" />
                                </Fragment>
                                :
                                <div className="Federal-text main-animation px-3 mt-2">

                                    <div className="d-flex justify-content-between aling-items-center pt-3 pb-2 flex-wrap">
                                        <span className="font-midium font-w font-color">Federal Tax Credit for {buyDetail?.vehicle_condition} Electric Vehicles</span>
                                        <p className="text-center font-midium font-w font-color">${buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount
                                            ? NumberWithThousandSeparator(buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount)
                                            : 0}</p>
                                        {filter?.checkEligibility ? buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0
                                            ? (<label className="text-Eligible text-decoration-none">Eligible</label>)
                                            : (<label className="text-not-Eligible text-decoration-none">Not Eligible</label>) :
                                            buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0 ?
                                                (<label className="text-Eligible-none text-decoration-none"></label>) : <label className="text-not-Eligible text-decoration-none">Not Eligible</label>
                                        }
                                    </div>
                                    {filter.checkEligibility === true &&
                                        <div className="text-end mb-2">
                                            <button className="text-dark button-set text-decoration-none font-small font-w font-color link-button" onClick={onReset}>Reset</button>
                                        </div>}
                                </div>}
                            <div className="px-3 pt-3 rounded-bottom mt-1">
                                <Row>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        {buyDetail?.federal_tax_credit_eligibility?.eligibility_object &&
                                            buyDetail?.federal_tax_credit_eligibility.eligibility_object?.map((eligibilityCategoryObjectVal, eligibilityCategoryObjectKey) => {

                                                return (
                                                    <div key={eligibilityCategoryObjectKey}>
                                                        {
                                                            eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'Critical Minerals Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'Battery Components Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'Final Assembly Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'MSRP Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'House Hold Income'.trim().toLowerCase() ?
                                                                (

                                                                    <div className="mb-4">
                                                                        <h6 className="el-cetegory">{eligibilityCategoryObjectVal?.category_name}</h6>
                                                                        {eligibilityCategoryObjectVal?.attr &&
                                                                            eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                return (
                                                                                    <div className="d-flex align-items-center" key={eligibilityReqObjectKey}>
                                                                                        {eligibilityReqObjectVal?.valid ?
                                                                                            <i className=" me-3 "><CheckGreen /></i> :
                                                                                            <i className="me-3 d-flex">
                                                                                                <RedClose />
                                                                                            </i>
                                                                                        }
                                                                                        <span className="font-wxl font-small font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                    </div>

                                                                ) :
                                                                (
                                                                    eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'House Hold Income'.trim().toLowerCase() && !isLoading ?
                                                                        <div className="main-animation">
                                                                            <h6 className="el-cetegory">{eligibilityCategoryObjectVal?.category_name}</h6>
                                                                            <div className="d-flex align-items-center mb-2" key={eligibilityCategoryObjectKey}>
                                                                                {buyDetail?.federal_tax_credit_eligibility?.gross_income === true &&
                                                                                    filter?.checkEligibility === false ?
                                                                                    <ul className="p-0" style={{ listStyle: "none" }}>
                                                                                        {eligibilityCategoryObjectVal?.attr &&
                                                                                            eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                                return (
                                                                                                    <Fragment key={eligibilityReqObjectKey}>
                                                                                                        <li className={eligibilityReqObjectVal?.tax_filing_status && Number(eligibilityReqObjectVal?.tax_filing_status) === Number(filter?.tax_filing_status) && buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0 ? "text-color font-wxl font-small" : "text-color font-wxl font-small mar-left"} key={eligibilityReqObjectKey}>
                                                                                                            {(eligibilityReqObjectVal?.tax_filing_status && Number(eligibilityReqObjectVal?.tax_filing_status) === Number(filter?.tax_filing_status) && buyDetail?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0) &&
                                                                                                                <img width="20px"
                                                                                                                    onContextMenu={handleContextMenu}
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    className="me-2"
                                                                                                                    src={checked} alt="" />
                                                                                                            }
                                                                                                            {eligibilityReqObjectVal?.requirement}</li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            })}
                                                                                    </ul> :
                                                                                    eligibilityCategoryObjectVal?.attr &&
                                                                                    eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                        return (
                                                                                            <div className="mb-3" key={eligibilityReqObjectKey}>
                                                                                                {eligibilityReqObjectVal?.valid ?
                                                                                                    <i className="me-3"><CheckGreen /></i> :
                                                                                                    <i className="me-3"><RedClose /></i>
                                                                                                }
                                                                                                <span className="font-wxl font-small font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                            </div>
                                                                        </div> : eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'House Hold Income'.trim().toLowerCase() &&
                                                                        <div>
                                                                            <SkeletonComponent count={1} className="height-1" />
                                                                        </div>
                                                                )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        {buyDetail?.federal_tax_credit_eligibility?.eligibility_object &&
                                            buyDetail?.federal_tax_credit_eligibility.eligibility_object?.map((eligibilityCategoryObjectVal, eligibilityCategoryObjectKey) => {
                                                return (
                                                    <div key={eligibilityCategoryObjectKey}>
                                                        {
                                                            (eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'MSRP Requirement'.trim().toLowerCase() ||
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'Final Assembly Requirement'.trim().toLowerCase() ||
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'Battery Components Requirement'.trim().toLowerCase() ||
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'Critical Minerals Requirement'.trim().toLowerCase()

                                                            ) &&
                                                            (

                                                                <div className="mb-4">
                                                                    <h6 className="el-cetegory">{eligibilityCategoryObjectVal?.category_name}</h6>
                                                                    {eligibilityCategoryObjectVal?.attr &&
                                                                        eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                            return (
                                                                                <div className="d-flex align-items-center" key={eligibilityReqObjectKey}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        {eligibilityReqObjectVal?.valid ?
                                                                                            <i className="me-3"><CheckGreen /></i> :
                                                                                            <i className="me-3"><RedClose /></i>
                                                                                        }

                                                                                        <span className="font-wxl font-small font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                        {(((eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" && !isEmpty(tooltipText[7])) || (eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" && !isEmpty(tooltipText[8])))) &&
                                                                                            (
                                                                                                ((eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" && !isEmpty(tooltipText[7])) || (eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" && !isEmpty(tooltipText[8]))) &&
                                                                                                <OverlayTrigger
                                                                                                    placement="bottom"
                                                                                                    trigger={['hover', 'click']}
                                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                                    overlay={
                                                                                                        <Tooltip className="custom-tooltip ">
                                                                                                            <div className="tc-text">
                                                                                                                {eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" ? tooltipText[7]
                                                                                                                    : eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" ? tooltipText[8] : <Fragment></Fragment>}
                                                                                                            </div>
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <svg className="ms-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                                        <path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z" fill="#1E1E1F" />
                                                                                                        <path d="M6.69746 4.941L4.97996 5.15625L4.91846 5.44125L5.25596 5.5035C5.47646 5.556 5.51996 5.6355 5.47196 5.85525L4.91846 8.45625C4.77296 9.129 4.99721 9.4455 5.52446 9.4455C5.93321 9.4455 6.40796 9.2565 6.62321 8.997L6.68921 8.685C6.53921 8.817 6.32021 8.8695 6.17471 8.8695C5.96846 8.8695 5.89346 8.72475 5.94671 8.46975L6.69746 4.941ZM6.74996 3.375C6.74996 3.57391 6.67095 3.76468 6.53029 3.90533C6.38964 4.04598 6.19888 4.125 5.99996 4.125C5.80105 4.125 5.61029 4.04598 5.46963 3.90533C5.32898 3.76468 5.24996 3.57391 5.24996 3.375C5.24996 3.17609 5.32898 2.98532 5.46963 2.84467C5.61029 2.70402 5.80105 2.625 5.99996 2.625C6.19888 2.625 6.38964 2.70402 6.53029 2.84467C6.67095 2.98532 6.74996 3.17609 6.74996 3.375Z" fill="#1E1E1F" />
                                                                                                    </svg>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    {buyDetail?.fed_tax_credit_amount > 0 &&
                                        <Fragment>
                                            {!isLoading ?
                                                <div className="mb-4 main-animation">
                                                    <h6 className="el-cetegory">IRS requirement</h6>
                                                    <div className="d-flex align-items-center">
                                                        <div className="">
                                                            <p className="font-wxl font-small font-black">Beginning January 1, 2024, Clean Vehicle Tax Credits must be initiated and approved at the time of sale. The dealer/seller must submit a ‘time of sale report’ containing buyer and vehicle information to IRS Energy Credits Online.</p>
                                                            <p className="mt-2 font-wxl font-small font-black">Buyers are advised to obtain a copy of the IRS’s confirmation that a “time-of-sale” report was submitted successfully by the dealer/seller.</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <SkeletonComponent count={1} className="height-1 mt-2" />}

                                        </Fragment>
                                    }

                                </Row>
                            </div>
                        </div>
                    </Col>
                    : <Col lg={6}>
                        <div className="">
                            <SkeletonComponent count={1} className="height-4" />
                        </div>
                    </Col>
                }
            </Row>
        </Tab.Pane>
    );
}

export default withRouter(BuyIncentiveSection);