import { withRouter } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { defaultValueIncentiveFinderFilters } from "../../components/EVIncentiveFinderFilters";
import * as apiHelper from "../../helper/api_helper";

const RefereshCache = () => {

    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);

    async function getIncentives(params = {}) {

        setLoad(true);
        params = defaultValueIncentiveFinderFilters();
        params.is_cron = true;
        try {
            const response = await apiHelper.postEVIncentiveFinder(params);
            if (response.data.status === 1) {
                setError(false);
            } else {
                setError(process.env.REACT_APP_SOMTHING_WRONG_MSG);
            }

            setLoad(false);

        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getIncentives();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return (
        <Fragment>
            <h1>
                {!load && !error ? "Update" : error}
            </h1>
        </Fragment>
    );
}

export default withRouter(RefereshCache);