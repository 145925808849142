import { useSelector, useDispatch } from "react-redux";
import { updateIncentiveData, updateFinanceIncentiveData } from "../../store/action";


export const useAuth =()=>{
	
	const { stateUserDetails } = useSelector((state) => ({
		stateUserDetails: state.UserDetails,
	}));
	
    return [ stateUserDetails.isLogin, stateUserDetails.data ];
}



export const useEVIncentiveDetailSelectedState=()=>{
	const dispatch = useDispatch();
	const { stateEVIncentiveDetail } = useSelector((state) => ({
		stateEVIncentiveDetail: state.EVIncentiveDetail,
	}));

	const updateIncentiveState=( data )=>{
		dispatch(updateIncentiveData( data ))
	}
	const updateFinanceIncentiveState=( data )=>{
		dispatch(updateFinanceIncentiveData( data ))
	}

	return [ stateEVIncentiveDetail, updateIncentiveState, updateFinanceIncentiveState ]
}



