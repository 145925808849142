import React, { useEffect, Fragment } from "react";
import { Row, Col, Form, Tab, ButtonGroup, Button } from 'react-bootstrap';
import checked from '../../assets/img/checked.svg'
import 'react-circular-progressbar/dist/styles.css';
import { withRouter, Link } from "react-router-dom";
import { NumberWithThousandSeparator, isEmpty, handleDragStart, handleContextMenu, createSeoFriendlyUrl, LeaseAmountPerMonthFormate } from "../../helper/project_helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IncentiveDetailPopup } from "../../components/IncentiveDetailPopup";
import CheckGreen from "../../components/WebComponent/CheckGreen";
import RedClose from "../../components/WebComponent/RedClose";
import MyLink from '../../components/MyLink';
import CryptoJS from 'crypto-js';
import SkeletonComponent from "../../components/SkeletonComponent";


const FinanceIncentiveSection = ({
    filter,
    household_income,
    tax_filing_status,
    setUpdatedFilter,
    incentiveAmount,
    setIncentiveAmount,
    handleHideShowClick,
    expandedRows,
    tooltipText,
    onReset,
    history,
    chargingIncentives,
    settotalSavingAmount,
    setSpecialIncentives,
    specialIncentives,
    totalSavingAmount,
    incentiveCheckState,
    updateFinanceIncentiveState,
    isLoading,
    incentiveFinanceDetailData,
    changeLoading
}) => {

    const financeData = incentiveFinanceDetailData?.finance;
    const financePaymentData = incentiveFinanceDetailData?.finance?.finance_payment_data;
    const financeTermList = incentiveFinanceDetailData?.finance?.finance_term_list;
    const financeDownPaymentList = incentiveFinanceDetailData?.finance?.down_payment_list;

    const onUpdate = (name, value1) => {
        let value_ = { ...filter };
        value_[name] = value1;
        setUpdatedFilter(value_);
        if (name === 'down_payment') {
            const input = document.querySelector('.down_payment_select');

            // Check if the element exists and has the class
            if (input && input.classList.contains('down_payment_select')) {
                input.blur(); // Give focus if the class is present
            }
        }
    }

    useEffect(() => {
        if (incentiveAmount !== financeData?.federal_tax_credit_eligibility?.incentive_amount) {
            setIncentiveAmount(financeData?.federal_tax_credit_eligibility?.incentive_amount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incentiveAmount, financeData]);

    const currentHouseHoldIncome = household_income?.New?.[filter?.tax_filing_status ? filter.tax_filing_status : '1'];

    const onUpdateIncentiveState = (selected = [], other = []) => {
        updateFinanceIncentiveState({
            selectedIncentives: selected,
            otherIncentives: other
        });
    }

    useEffect(() => {
        let incentiveData = incentiveCheckState?.financeIncentiveData;
        if (incentiveData?.selectedIncentives) {
            onUpdateIncentiveState(incentiveData.selectedIncentives, incentiveData?.otherIncentives);
        } else {
            const otherInc = [];
            financeData?.specialIncentives?.forEach(incentivesVal => {
                otherInc.push(incentivesVal?.incentive_id);
            });
            onUpdateIncentiveState([], otherInc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financeData?.specialIncentives])




    const onCheck = (e) => {
        const { value, checked } = e.target;
        const checkValues = value?.split('|');
        let incentiveData = incentiveCheckState?.financeIncentiveData;
        const inc_id = parseInt(checkValues[1]);
        if (checked) {
            setSpecialIncentives(specialIncentives + parseFloat(checkValues[0]));
            onUpdateIncentiveState([...incentiveData.selectedIncentives, inc_id], incentiveData?.otherIncentives.filter(v => v !== inc_id));
        } else {
            setSpecialIncentives(specialIncentives - parseFloat(checkValues[0]));
            onUpdateIncentiveState(incentiveData?.selectedIncentives.filter(v => v !== inc_id), [...incentiveData?.otherIncentives, inc_id]);
        }
    }

    useEffect(() => {

        settotalSavingAmount(parseFloat(financeData?.total_saving_amount) + parseFloat(specialIncentives));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialIncentives, financeData]);

    return (
        <Tab.Pane className="mt-2" eventKey="Finance">
            <Row className="g-4">
                <a className="btn-learn-more text-center d-none" id={'incentive_link'} target="_blank" href='' rel="noopener noreferrer" >
                </a>
                {!isEmpty(financeData?.make) && (!isLoading || !changeLoading) ?
                    <Col lg={6} className="main-animation">
                        <div className="main-text-detail d-flex flex-column justify-content-between p-3 white-bg full-height">
                            <div>
                                <div className="d-flex align-items-center justify-content-center mb-2">
                                    <h4 className="me-1 font-px m-0 font-w m-text-center" style={{ opacity: "0.8" }}>Estimated Monthly Payment</h4>
                                </div>
                                {isEmpty(financePaymentData?.finance_amount_per_month) ?
                                    <div className="d-flex align-items-center justify-content-center mb-3">
                                        <h4 className="me-1 font-px m-0" style={{ color: "#21272A" }}>Not Available</h4>
                                    </div>
                                    :
                                    <h1 className="text-center d-flex justify-content-center align-items-center font-big1 font-wx mb-3 font-black">${LeaseAmountPerMonthFormate(financePaymentData?.finance_amount_per_month)}
                                        <p className="text-month" style={{ fontSize: "24px", fontWeight: "800" }}>&nbsp;/ month</p></h1>}

                                {(financeDownPaymentList && financeDownPaymentList?.length > 0) &&
                                    <Row className={"align-items-center g-5 mb-4 pb-1 m-text-center " + (financeTermList && isEmpty(financePaymentData?.finance_amount_per_month) && "mb-4")}>
                                        <Col lg={1}></Col>
                                        <Col lg={5} md={6}>

                                            <Fragment>
                                                <p className="font-midium mb-1 font-Gunmetal" style={{ fontWeight: "700" }}>Finance Term (Months)</p>
                                                <ButtonGroup className="btn-group-main" aria-label="Basic example">
                                                    {financeTermList?.map((financeTermVal, financeTermKey) => {
                                                        return (
                                                            Number(filter?.finance_term) === Number(financeTermVal) ? (
                                                                <Button value={Number(financeTermVal)} className={Number(filter?.finance_term) === Number(financeTermVal) ? "btn-group selected-lease-term" : "btn-group"} key={financeTermKey}>{financeTermVal}</Button>
                                                            ) : (
                                                                <Button
                                                                    value={financeTermVal}
                                                                    className="btn-group"
                                                                    // disabled={isLoading}
                                                                    onClick={(e) => onUpdate("finance_term", Number(e.target.value))}
                                                                    key={financeTermKey}
                                                                >{financeTermVal}</Button>
                                                            )
                                                        )
                                                    })}
                                                </ButtonGroup>
                                            </Fragment>
                                        </Col>
                                        <Col lg={5} md={6} className="m-center-section">
                                            <Fragment>
                                                <span className="font-midium text-detaile" style={{ fontWeight: "700" }}>Down Payment</span>
                                                {(financeDownPaymentList && financeDownPaymentList?.length > 1) ?
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        className="font-large font-large font-color down_payment_select"
                                                        // disabled={isLoading}
                                                        defaultValue={Number(filter?.tax_filing_status || 0)}
                                                        // value={Number(filter?.down_payment)}
                                                        onChange={(e) => onUpdate("down_payment", Number(e.target.value))}
                                                    >
                                                        {financeDownPaymentList?.map((downPaymentVal, downPaymentKey) => {
                                                            return (
                                                                <option
                                                                    key={downPaymentKey}
                                                                    value={Number(downPaymentVal)}
                                                                    selected={Number(filter?.down_payment) == Number(downPaymentVal)}
                                                                >{downPaymentVal ? '$' + NumberWithThousandSeparator(downPaymentVal) : 0}</option>
                                                            )
                                                        })}
                                                    </Form.Select> :
                                                    <Form.Control className="font-large font-large font-color down_payment_select" type="text" defaultValue={'$' + NumberWithThousandSeparator(financeDownPaymentList?.[0])} readOnly={true} />
                                                }
                                            </Fragment>
                                        </Col>
                                        <Col lg={1}></Col>
                                        <Col lg={1}></Col>
                                        {financePaymentData &&
                                            <Fragment>
                                                <Col lg={5} md={6}>
                                                    <div>
                                                        <span className="font-midium text-detaile" style={{ fontWeight: "700" }}>APR</span>
                                                        <input className="form-control font-large font-wxl font-color" type="text" value={LeaseAmountPerMonthFormate(financePaymentData?.apr) + "%" + (!isEmpty(financePaymentData?.apr_cash) && financePaymentData?.apr_cash > 0 ? " + $" + NumberWithThousandSeparator(financePaymentData?.apr_cash) + " Cash" : "")} readOnly={true} />
                                                    </div>
                                                </Col>
                                                <Col lg={5} md={6}>
                                                    <div>
                                                        <span className="font-midium text-detaile" style={{ fontWeight: "700" }}>Credit Score</span>
                                                        <Form.Control className="form-control font-large font-wxl font-color" type="text" readOnly={true} value={financePaymentData?.credit_score_min + "+"} />
                                                    </div>
                                                </Col>
                                            </Fragment>
                                        }
                                        <Col lg={1}></Col>
                                    </Row>}
                                <div className="d-flex flex-column align-items-center top-b p-2 pt-3">
                                    <div className="d-flex align-items-center justify-content-center mb-2">
                                        <h4 className="me-1 font-px m-0 font-w" style={{ opacity: "0.8" }}>Potential Savings</h4>
                                    </div>
                                    <h1 className="text-center font-wx font-black" style={{ fontSize: "56px" }}>${totalSavingAmount
                                        ? NumberWithThousandSeparator(totalSavingAmount)
                                        : 0}</h1>
                                    {totalSavingAmount > 0 &&
                                        <span className="font-smallx font-w3 font-black">*Subject to eligibility</span>
                                    }
                                </div>
                                    {/* <div className="d-flex justify-content-between mb-3">
                            <span className="font-w font-large font-Gunmetal">Base MSRP</span>
                            <span className="font-large font-w font-black">${financeData?.base_msrp
                                ? NumberWithThousandSeparator(financeData?.base_msrp)
                                : 0}</span>
                        </div> */}
                                    {
                                        financeData?.total_potential_savings != null &&
                                        <div className="top-b pt-3 mt-2 pb-2">
                                            {financeData?.total_potential_savings?.map(
                                                (object, i) => {
                                                    return (
                                                    <div style={{ position: "relative" }} key={i} className={((object?.is_included_in_monthly_payment === true && object?.is_combinable === false) ? "pb-4-5" : (object?.is_included_in_monthly_payment === true || object?.is_combinable === false) ? 'pb-4-5' : (financePaymentData?.is_fed_tax_credit_included === true && isEmpty(object?.incentive_type)) ? 'pb-4-5' : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between"} key={i}>
                                                            {(isEmpty(object?.incentive_type)) ?
                                                                <div>
                                                                    <span
                                                                        className="font-Gunmetal font-large font-w4 replace-hyphen"
                                                                    >{isEmpty(object?.incentive_name_text) ? object?.incentive_name : object?.incentive_name_text}</span>
                                                                    {financePaymentData?.is_fed_tax_credit_included === true &&
                                                                        <Fragment>
                                                                            <br />
                                                                            <span className="text-font p-absolute replace-hyphen">Applied to monthly payment</span>
                                                                        </Fragment>
                                                                    }
                                                                </div>

                                                                :
                                                                <div>
                                                                    <span
                                                                        className="font-Gunmetal all-incentive font-large font-w4"
                                                                        onClick={() => handleHideShowClick("potential_savings_" + i)}
                                                                        aria-disabled={isLoading}>
                                                                        {isEmpty(object?.incentive_name_text) ? object?.incentive_name : object?.incentive_name_text}</span>
                                                                    {object?.is_combinable === false &&
                                                                        <Fragment>
                                                                            <br />
                                                                            <span className="text-font p-absolute">Exclusions apply</span>
                                                                        </Fragment>
                                                                    }{object?.is_included_in_monthly_payment === true &&
                                                                        <Fragment>
                                                                            <br />
                                                                            <span className={"text-font p-absolute replace-hyphen " + (object?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                            }
                                                            <span className="font-black font-large font-w4 replace-hyphen">{isEmpty(object?.incentive_amount_text) ? object?.incentive_amount : object?.incentive_amount_text}</span>
                                                        </div>
                                                        {expandedRows["potential_savings_" + i] &&
                                                            <div className={"detail-popup " + ((object?.is_included_in_monthly_payment === true && object?.is_combinable === false) ? "detail-popup-top-double" : (object?.is_included_in_monthly_payment === true || object?.is_combinable === false) ? '' : '')}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick}
                                                                    IncentiveObject={object}
                                                                    specialIncentivesKey={"potential_savings_" + i} />
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                }
                                {/* <div>
                        <div className="d-flex justify-content-between mt-3 cash-detail pb-3">
                            <span className="font-w font-px font-Gunmetal">Price After Potential Savings</span>
                            <span className="font-large font-w font-black">${financeData?.base_msrp
                                ? totalSavingAmount ? NumberWithThousandSeparator(financeData?.base_msrp - totalSavingAmount) : NumberWithThousandSeparator(financeData?.base_msrp - 0)
                                : 0}</span>
                        </div>
                    </div> */}
                                {financeData?.specialIncentives && financeData?.specialIncentives.length > 0 &&
                                    <div className="pt-3 pb-3 top-b">
                                        <h6 className="m-0 mt-2 mb-1 incentiver-title">Special Programs</h6>
                                        {financeData?.specialIncentives?.map((specialIncentivesVal, specialIncentivesKey) => {
                                            return (
                                                // <div style={{ position: "relative" }} className={((specialIncentivesVal?.is_included_in_monthly_payment === true && specialIncentivesVal?.is_combinable === false) ? "py-4" : (specialIncentivesVal?.is_included_in_monthly_payment === true || specialIncentivesVal?.is_combinable === false) ? 'py-3' : 'py-2')} key={specialIncentivesKey}>
                                                <div style={{ position: "relative" }} className={((specialIncentivesVal?.is_included_in_monthly_payment === true && specialIncentivesVal?.is_combinable === false) ? "pb-4-5" : (specialIncentivesVal?.is_included_in_monthly_payment === true || specialIncentivesVal?.is_combinable === false) ? 'pb-4-5' : 'pb-3')} key={specialIncentivesKey}>
                                                    <div className="d-flex justify-content-between" key={specialIncentivesKey}>
                                                        <Form>
                                                            <div className="d-flex">
                                                                {/* <Form.Check
                                                            disabled={isLoading}
                                                            id={`default-${specialIncentivesKey}`}
                                                            checked={(incentiveCheckState?.financeIncentiveData?.selectedIncentives && incentiveCheckState?.financeIncentiveData?.selectedIncentives.includes(specialIncentivesVal?.incentive_id)) ? true : false}
                                                            value={specialIncentivesVal?.saving_amount + "|" + specialIncentivesVal?.incentive_id}
                                                            name={specialIncentivesVal?.incentive_name}
                                                            className={"me-4 form-checkbox form-lable"}
                                                            onChange={onCheck}
                                                        /> */}
                                                                {
                                                                    <div>
                                                                        <label
                                                                            className="font-large all-incentive saving-detail font-w4 font-Gunmetal replace-hyphen green-hover-text"
                                                                            onClick={() => handleHideShowClick("other_incentive_" + specialIncentivesKey)}
                                                                            aria-disabled={isLoading}
                                                                        >{isEmpty(specialIncentivesVal?.incentive_name_text) ? specialIncentivesVal?.incentive_name : specialIncentivesVal?.incentive_name_text}</label>

                                                                        {specialIncentivesVal?.is_combinable === false &&
                                                                            <Fragment>
                                                                                <br />
                                                                                <span className="text-font p-absolute">Exclusions apply</span>
                                                                            </Fragment>
                                                                        }{specialIncentivesVal?.is_included_in_monthly_payment === true &&
                                                                            <Fragment>
                                                                                <br />
                                                                                <span className={"text-font p-absolute replace-hyphen " + (specialIncentivesVal?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                            </Fragment>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Form>
                                                        <span className="font-large font-w4 font-black replace-hyphen">{isEmpty(specialIncentivesVal?.incentive_amount_text) ? specialIncentivesVal?.incentive_amount : specialIncentivesVal?.incentive_amount_text}</span>
                                                    </div>
                                                    <div className={"detail-popup " + ((specialIncentivesVal?.is_included_in_monthly_payment === true && specialIncentivesVal?.is_combinable === false) ? "detail-popup-top-double" : (specialIncentivesVal?.is_included_in_monthly_payment === true || specialIncentivesVal?.is_combinable === false) ? '' : '')}>
                                                        {expandedRows["other_incentive_" + specialIncentivesKey] &&
                                                            <IncentiveDetailPopup
                                                                tooltipText={tooltipText}
                                                                handleHideShowClick={handleHideShowClick} IncentiveObject={specialIncentivesVal} specialIncentivesKey={"other_incentive_" + specialIncentivesKey} />}
                                                    </div>


                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                {financeData?.promotionsIncentives && financeData?.promotionsIncentives?.length > 0 && (
                                    <div className={"pt-3 pb-2 top-b"}>
                                        <Fragment>
                                            <h6 className="m-0 mt-2 mb-1 incentiver-title">Promotions</h6>
                                            {financeData?.promotionsIncentives?.map((promotionsIncentivesValue, promotionsIncentivesKey) => {
                                                return (
                                                    <div style={{ position: "relative" }} key={promotionsIncentivesKey} className={((promotionsIncentivesValue?.is_included_in_monthly_payment === true && promotionsIncentivesValue?.is_combinable === false) ? "pb-4-5" : (promotionsIncentivesValue?.is_included_in_monthly_payment === true || promotionsIncentivesValue?.is_combinable === false) ? 'pb-4-5' : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <div className="detail-incentive-name">
                                                                <span
                                                                    className="font-large font-w4 font-Gunmetal text-decoration-underline detail-incentive-name all-incentive pe-5 cursor-pointer replace-hyphen green-hover-text"
                                                                    onClick={() => handleHideShowClick("promotions_incentive_" + promotionsIncentivesKey)}
                                                                    aria-disabled={isLoading}
                                                                >{isEmpty(promotionsIncentivesValue?.incentive_name_text) ? promotionsIncentivesValue?.incentive_name : promotionsIncentivesValue?.incentive_name_text}</span>
                                                                {promotionsIncentivesValue?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }{promotionsIncentivesValue?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className={"text-font p-absolute replace-hyphen " + (promotionsIncentivesValue?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="detail-incentive-amount-div">
                                                                <span
                                                                    className="font-large font-w4 font-black detail-incentive-amount replace-hyphen"
                                                                >{isEmpty(promotionsIncentivesValue?.incentive_amount_text) ? promotionsIncentivesValue?.incentive_amount : promotionsIncentivesValue?.incentive_amount_text}</span>
                                                            </div>
                                                        </div>
                                                        {expandedRows["promotions_incentive_" + promotionsIncentivesKey] &&
                                                            <div className={"detail-popup " + ((promotionsIncentivesValue?.is_included_in_monthly_payment === true && promotionsIncentivesValue?.is_combinable === false) ? "detail-popup-top-double" : (promotionsIncentivesValue?.is_included_in_monthly_payment === true || promotionsIncentivesValue?.is_combinable === false) ? '' : '')}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={promotionsIncentivesValue} specialIncentivesKey={"promotions_incentive_" + promotionsIncentivesKey} />
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}

                                        </Fragment>
                                    </div>
                                )}

                                {chargingIncentives && chargingIncentives.length > 0 && (
                                    <div className={"pt-3 pb-2 top-b"}>
                                        <Fragment>
                                            <h6 className="m-0 mt-2 mb-1 incentiver-title">Charging Incentives</h6>
                                            {chargingIncentives?.map((chargingIncentiveValue, chargingIncentivesKey) => {
                                                return (
                                                    <div style={{ position: "relative" }} key={chargingIncentivesKey} className={((chargingIncentiveValue?.is_included_in_monthly_payment === true && chargingIncentiveValue?.is_combinable === false) ? "pb-4-5" : (chargingIncentiveValue?.is_included_in_monthly_payment === true || chargingIncentiveValue?.is_combinable === false) ? 'pb-4-5' : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <div className="detail-incentive-name">
                                                                <span
                                                                    className="font-large font-w4 font-Gunmetal text-decoration-underline detail-incentive-name all-incentive pe-5 cursor-pointer replace-hyphen green-hover-text"
                                                                    onClick={() => handleHideShowClick("charging_incentive_" + chargingIncentivesKey)}
                                                                    aria-disabled={isLoading}
                                                                >{isEmpty(chargingIncentiveValue?.incentive_name_text) ? chargingIncentiveValue?.incentive_name : chargingIncentiveValue?.incentive_name_text}</span>
                                                                {chargingIncentiveValue?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }{chargingIncentiveValue?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className={"text-font p-absolute replace-hyphen " + (chargingIncentiveValue?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                    </Fragment>
                                                                }
                                                            </div>

                                                            <div className="detail-incentive-amount-div">
                                                                <span
                                                                    className="font-large font-w4 font-black detail-incentive-amount replace-hyphen"
                                                                >{isEmpty(chargingIncentiveValue?.incentive_amount_text) ? chargingIncentiveValue?.incentive_amount : chargingIncentiveValue?.incentive_amount_text}</span>
                                                            </div>
                                                        </div>
                                                        {expandedRows["charging_incentive_" + chargingIncentivesKey] &&
                                                            <div className={"detail-popup " + ((chargingIncentiveValue?.is_included_in_monthly_payment === true && chargingIncentiveValue?.is_combinable === false) ? "detail-popup-top-double" : (chargingIncentiveValue?.is_included_in_monthly_payment === true || chargingIncentiveValue?.is_combinable === false) ? '' : '')}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={chargingIncentiveValue} specialIncentivesKey={"charging_incentive_" + chargingIncentivesKey} />
                                                            </div>
                                                        }
                                                        {/* <div className="detail-popup">
                                        <IncentiveDetailPopup />
                                    </div> */}
                                                    </div>
                                                );
                                            })}

                                        </Fragment>
                                    </div>
                                )}
                            </div>

                            <span className="pt-4 pb-2 detail-search-link d-block align-items-center mt-3 font-midium">For the full list of State, Local, and Utility incentives that may be available for your location, visit&nbsp;
                                <Link className="text-dark btn-incentive-finder font-wx p-0 green-hover-text" to={"/incentivefinder/" + CryptoJS.MD5(financeData?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(financeData?.make) + "/" + createSeoFriendlyUrl(financeData?.model) + "/" + financeData?.model_year + "/" + createSeoFriendlyUrl(financeData?.trim) + "?type=finance"}>
                                    Incentive Finder
                                </Link><span className="small-space" style={{ marginLeft: '0px' }}>.</span></span>
                        </div>

                    </Col>
                    : <Col lg={6}>
                        <div className="">
                            <SkeletonComponent count={1} className="height-4" />
                        </div>
                    </Col>
                }
                {!isEmpty(financeData?.make) && (!isLoading || !changeLoading) ?
                    <Col lg={6} className="d-flex main-animation flex-column">
                        {!isEmpty(financePaymentData?.finance_term) &&
                            <div className="main-text-detail p-3 white-bg h-25">
                                {/* <div className="d-flex align-items-center justify-content-center mb-4">
                     <h4 className="me-1 font-px m-0 font-w m-text-center" style={{ opacity: "0.8" }}>Estimated Monthly Payment</h4>
                 </div>

                 <div className="d-flex align-items-center justify-content-center mb-3">
                     <h4 className="me-1 font-px m-0 font-w" style={{ color: "#21272A" }}>Not Available</h4>
                 </div>
                 <h1 className="text-center d-flex justify-content-center align-items-center font-big1 font-wx mb-3 font-black">$429
                     <p className="text-month" style={{ fontSize: "24px", fontWeight: "800" }}>&nbsp;/ month</p></h1>

                 <Fragment>
                     <div className="mx-fit-content mb-4">
                         <p className="font-midium mb-1 font-Gunmetal text-start" style={{ fontWeight: "600" }}>Finance Term</p>
                         <ButtonGroup className="btn-group-main btn-group-main-finance" aria-label="Basic example">
                             <Button className="btn-group selected-lease-term">48</Button>
                             <Button className="btn-group">60</Button>
                             <Button className="btn-group">72</Button>
                         </ButtonGroup>
                     </div>
                 </Fragment>
                 <p className="mb-4 pb-1 text-center font-midium font-w font-black">*5.99% Estimated APR, $2,999 down payment and 720+ credit score  </p> */}
                                <div className="">
                                    <div className="">
                                        <h5 className="font-px font-w font-Gunmetal mb-3">Financing Information & Disclosures</h5>
                                        <p className="font-midium font-w4 font-black mb-3">These are estimated monthly payments if you qualify for credit.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Actual payments and rates may be higher.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Additional charges such as tax, title, registration, and other fees are due at signing.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={"main-text-detail p-3 pb-0 white-bg h-100 " + (!isEmpty(financePaymentData?.finance_term) && ('mt-4'))}>
                            <div className="px-3 pt-3 ">
                                <div className="">
                                    <h5 className="font-px font-w mb-2 font-black">Federal Tax Credit Eligibility Finder</h5>
                                    {
                                        <p className="font-midium font-w4 font-black mb-3">

                                            {financeData?.fed_tax_credit_amount < 1 && !filter?.checkEligibility ?

                                                "This vehicle does NOT qualify for Federal Tax Credit. For more information, check the requirements below."
                                                :
                                                filter?.checkEligibility ? financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0 ?
                                                    ""
                                                    : "You do NOT qualify for Federal Tax Credit. For more information, check the requirements below." :
                                                    "This vehicle qualifies for Federal Tax Credit. Enter your information below to find out if you qualify for the Credit."
                                            }
                                        </p>
                                    }
                                </div>
                                {(!filter?.checkEligibility && !isLoading && financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0) && (
                                    <Fragment>
                                        <div className={"d-flex align-items-center justify-content-between flex-wrap mt-3"}>
                                            <div className="d-flex align-items-baseline flex-wrap">
                                                <div className="me-3">
                                                    <span className="font-midium font-w font-color">Tax Filing Status</span>
                                                    <Form.Select
                                                        className="mt-2 mx-w font-small font-wxl font-color"
                                                        aria-label="Default select example"
                                                        defaultValue={filter?.tax_filing_status}
                                                        // value={filter?.tax_filing_status}
                                                        onChange={(e) => onUpdate("tax_filing_status", e.target.value)}
                                                        disabled={isLoading}
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        {tax_filing_status?.map((tax_filing_status_val, tax_filing_status_key) => {
                                                            return (
                                                                <option value={tax_filing_status_val?.value} key={tax_filing_status_key}>
                                                                    {tax_filing_status_val?.text}
                                                                </option>
                                                            )
                                                        }
                                                        )}
                                                    </Form.Select>
                                                </div>
                                                <div>
                                                    <span className="font-midium font-wx font-color">Household Income</span>
                                                    <Form>
                                                        <div className="mb-3">
                                                            {currentHouseHoldIncome && (currentHouseHoldIncome?.map((household_income_val, keyIndex) => {
                                                                return (
                                                                    <Form.Check
                                                                        key={keyIndex}
                                                                        disabled={isEmpty(filter?.tax_filing_status) || isLoading}
                                                                        label={household_income_val?.text}
                                                                        className="radio-check font-color"
                                                                        value={household_income_val?.value}
                                                                        name="household_income"
                                                                        checked={filter?.household_income === household_income_val.value}
                                                                        onChange={(e) => onUpdate("household_income", e.target.value)}
                                                                    />
                                                                )
                                                            })
                                                            )}
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    disabled={isEmpty(filter?.tax_filing_status) || isEmpty(filter.household_income) || isLoading}
                                                    className="btn-learn-more text-decoration-none"
                                                    onClick={(e) => onUpdate("checkEligibility", true)}
                                                >
                                                    Check
                                                </button>
                                            </div>
                                        </div>
                                        <div className="text-end" style={{ marginTop: "-0.8rem" }}>
                                            <button
                                                className={"text-dark button-set text-decoration-none font-small font-w font-color link-button green-hover-text"}
                                                onClick={onReset}
                                                disabled={isLoading}
                                            >Reset</button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                            {isLoading ?
                                <Fragment>
                                    <SkeletonComponent count={1} className="height-1" />
                                    <SkeletonComponent count={1} className="height-0-5 mt-2" />
                                </Fragment>
                                :
                                <div className="Federal-text main-animation px-3 mt-2">

                                    <div className="d-flex justify-content-between aling-items-center pt-3 pb-2 flex-wrap">
                                        <span className="font-midium font-w font-color">Federal Tax Credit for {financeData?.vehicle_condition} Electric Vehicles</span>
                                        <p className="text-center font-midium font-w font-color">${financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount
                                            ? NumberWithThousandSeparator(financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount)
                                            : 0}</p>
                                        {filter?.checkEligibility ? financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0
                                            ? (<label className="text-Eligible text-decoration-none">Eligible</label>)
                                            : (<label className="text-not-Eligible text-decoration-none">Not Eligible</label>) :
                                            financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0 ?
                                                (<label className="text-Eligible-none text-decoration-none"></label>) : <label className="text-not-Eligible text-decoration-none">Not Eligible</label>
                                        }
                                    </div>
                                    {filter.checkEligibility === true &&
                                        <div className="text-end mb-2">
                                            <button className="text-dark button-set text-decoration-none font-small font-w font-color link-button" onClick={onReset}>Reset</button>
                                        </div>}
                                </div>}
                            <div className="px-3 pt-3 rounded-bottom mt-1">
                                <Row>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        {financeData?.federal_tax_credit_eligibility?.eligibility_object &&
                                            financeData?.federal_tax_credit_eligibility.eligibility_object?.map((eligibilityCategoryObjectVal, eligibilityCategoryObjectKey) => {

                                                return (
                                                    <div key={eligibilityCategoryObjectKey}>
                                                        {
                                                            eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'Critical Minerals Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'Battery Components Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'Final Assembly Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'MSRP Requirement'.trim().toLowerCase() &&
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() !== 'House Hold Income'.trim().toLowerCase() ?
                                                                (

                                                                    <div className="mb-4">
                                                                        <h6 className="el-cetegory">{eligibilityCategoryObjectVal?.category_name}</h6>
                                                                        {eligibilityCategoryObjectVal?.attr &&
                                                                            eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                return (
                                                                                    <div className="d-flex align-items-center" key={eligibilityReqObjectKey}>
                                                                                        {eligibilityReqObjectVal?.valid ?
                                                                                            <i className=" me-3 "><CheckGreen /></i> :
                                                                                            <i className="me-3 d-flex">
                                                                                                <RedClose />
                                                                                            </i>
                                                                                        }
                                                                                        <span className="font-wxl font-small font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                    </div>

                                                                ) :
                                                                (
                                                                    eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'House Hold Income'.trim().toLowerCase() && !isLoading && eligibilityCategoryObjectVal?.attr ?
                                                                        <div className="main-animation">
                                                                            <h6 className="el-cetegory">{eligibilityCategoryObjectVal?.category_name}</h6>
                                                                            <div className="d-flex align-items-center mb-2" key={eligibilityCategoryObjectKey}>
                                                                                {financeData?.federal_tax_credit_eligibility?.gross_income === true &&
                                                                                    filter?.checkEligibility === false ?
                                                                                    <ul className="p-0" style={{ listStyle: "none" }}>
                                                                                        {eligibilityCategoryObjectVal?.attr &&
                                                                                            eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                                return (
                                                                                                    <Fragment key={eligibilityReqObjectKey}>
                                                                                                        <li className={eligibilityReqObjectVal?.tax_filing_status && Number(eligibilityReqObjectVal?.tax_filing_status) === Number(filter?.tax_filing_status) && financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0 ? "text-color font-wxl font-small" : "text-color font-wxl font-small mar-left"} key={eligibilityReqObjectKey}>
                                                                                                            {(eligibilityReqObjectVal?.tax_filing_status && Number(eligibilityReqObjectVal?.tax_filing_status) === Number(filter?.tax_filing_status) && financeData?.federal_tax_credit_eligibility?.fed_tax_credit_amount > 0) &&
                                                                                                                <img width="20px"
                                                                                                                    onContextMenu={handleContextMenu}
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    className="me-2"
                                                                                                                    src={checked} alt="" />
                                                                                                            }
                                                                                                            {eligibilityReqObjectVal?.requirement}</li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            })}
                                                                                    </ul> :
                                                                                    eligibilityCategoryObjectVal?.attr &&
                                                                                    eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                        return (
                                                                                            <div className="mb-3" key={eligibilityReqObjectKey}>
                                                                                                {eligibilityReqObjectVal?.valid ?
                                                                                                    <i className="me-3"><CheckGreen /></i> :
                                                                                                    <i className="me-3"><RedClose /></i>
                                                                                                }
                                                                                                <span className="font-wxl font-small font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                            </div>
                                                                        </div> : eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'House Hold Income'.trim().toLowerCase() &&
                                                                        <div>
                                                                            <SkeletonComponent count={1} className="height-1" />
                                                                        </div>
                                                                )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        {financeData?.federal_tax_credit_eligibility?.eligibility_object &&
                                            financeData?.federal_tax_credit_eligibility.eligibility_object?.map((eligibilityCategoryObjectVal, eligibilityCategoryObjectKey) => {
                                                return (
                                                    <div key={eligibilityCategoryObjectKey}>
                                                        {
                                                            (eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'MSRP Requirement'.trim().toLowerCase() ||
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'Final Assembly Requirement'.trim().toLowerCase() ||
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'Battery Components Requirement'.trim().toLowerCase() ||
                                                                eligibilityCategoryObjectVal?.requirement_rule?.trim().toLowerCase() === 'Critical Minerals Requirement'.trim().toLowerCase()

                                                            ) &&
                                                            (

                                                                <div className="mb-4">
                                                                    <h6 className="el-cetegory">{eligibilityCategoryObjectVal?.category_name}</h6>
                                                                    {eligibilityCategoryObjectVal?.attr &&
                                                                        eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                            return (
                                                                                <div className="d-flex align-items-center" key={eligibilityReqObjectKey}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        {eligibilityReqObjectVal?.valid ?
                                                                                            <i className="me-3"><CheckGreen /></i> :
                                                                                            <i className="me-3"><RedClose /></i>
                                                                                        }

                                                                                        <span className="font-wxl font-small font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                        {(((eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" && !isEmpty(tooltipText?.[7])) || (eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" && !isEmpty(tooltipText?.[8])))) &&
                                                                                            (
                                                                                                ((eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" && !isEmpty(tooltipText?.[7])) || (eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" && !isEmpty(tooltipText?.[8]))) &&
                                                                                                <OverlayTrigger
                                                                                                    placement="bottom"
                                                                                                    trigger={['hover', 'click']}
                                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                                    overlay={
                                                                                                        <Tooltip className="custom-tooltip ">
                                                                                                            <div className="tc-text">
                                                                                                                {eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" ? tooltipText?.[7]
                                                                                                                    : eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" ? tooltipText?.[8] : <Fragment></Fragment>}
                                                                                                            </div>
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <svg className="ms-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                                        <path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z" fill="#1E1E1F" />
                                                                                                        <path d="M6.69746 4.941L4.97996 5.15625L4.91846 5.44125L5.25596 5.5035C5.47646 5.556 5.51996 5.6355 5.47196 5.85525L4.91846 8.45625C4.77296 9.129 4.99721 9.4455 5.52446 9.4455C5.93321 9.4455 6.40796 9.2565 6.62321 8.997L6.68921 8.685C6.53921 8.817 6.32021 8.8695 6.17471 8.8695C5.96846 8.8695 5.89346 8.72475 5.94671 8.46975L6.69746 4.941ZM6.74996 3.375C6.74996 3.57391 6.67095 3.76468 6.53029 3.90533C6.38964 4.04598 6.19888 4.125 5.99996 4.125C5.80105 4.125 5.61029 4.04598 5.46963 3.90533C5.32898 3.76468 5.24996 3.57391 5.24996 3.375C5.24996 3.17609 5.32898 2.98532 5.46963 2.84467C5.61029 2.70402 5.80105 2.625 5.99996 2.625C6.19888 2.625 6.38964 2.70402 6.53029 2.84467C6.67095 2.98532 6.74996 3.17609 6.74996 3.375Z" fill="#1E1E1F" />
                                                                                                    </svg>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    {financeData?.fed_tax_credit_amount > 0 &&
                                        <Fragment>
                                            {!isLoading ?
                                                <div className="mb-4">
                                                    <h6 className="el-cetegory">IRS requirement</h6>
                                                    <div className="d-flex align-items-center">
                                                        <div className="">
                                                            <p className="font-wxl font-small font-black">Beginning January 1, 2024, Clean Vehicle Tax Credits must be initiated and approved at the time of sale. The dealer/seller must submit a ‘time of sale report’ containing buyer and vehicle information to IRS Energy Credits Online.</p>
                                                            <p className="mt-2 font-wxl font-small font-black">Buyers are advised to obtain a copy of the IRS’s confirmation that a “time-of-sale” report was submitted successfully by the dealer/seller.</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <SkeletonComponent count={1} className="height-1" />
                                            }
                                        </Fragment>}
                                </Row>
                            </div>
                        </div>
                    </Col>
                    : <Col lg={6}>
                        <div className="">
                            <div className="pb-4">
                                <SkeletonComponent count={1} className="height-2" />
                            </div>
                            <SkeletonComponent count={1} className="height-3" />
                        </div>
                    </Col>
                }
            </Row>
        </Tab.Pane>
    );
}

export default withRouter(FinanceIncentiveSection);