import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Tab, ButtonGroup, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { isEmpty, NumberWithThousandSeparator, createSeoFriendlyUrl, LeaseAmountPerMonthFormate } from "../../helper/project_helper";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { IncentiveDetailPopup } from "../../components/IncentiveDetailPopup";
import MyLink from '../../components/MyLink';
import CryptoJS from 'crypto-js';
import SkeletonComponent from "../../components/SkeletonComponent";

const LeaseIncentiveSection = ({
    leaseDetail,
    filter,
    setUpdatedFilter,
    chargingIncentives,
    history,
    handleHideShowClick,
    expandedRows,
    isLoading,
    tooltipText,
    changeLoading,
    financeDownPaymentList
}) => {

    
    const onUpdate = (name, value1) => {
        let value_ = { ...filter };
        value_[name] = value1;
        setUpdatedFilter(value_);
        if (name === 'annual_mileage') {
            const input = document.querySelector('.annual_mileage_select');

            // Check if the element exists and has the class
            if (input && input.classList.contains('annual_mileage_select')) {
                input.blur(); // Give focus if the class is present
            }
        }
        if (name === 'down_payment') {
            const input = document.querySelector('.down_payment_select');

            // Check if the element exists and has the class
            if (input && input.classList.contains('down_payment_select')) {
                input.blur(); // Give focus if the class is present
            }
        }
    }


    const [incentivesIds, setIncentivesIds] = useState("");

    useEffect(() => {
        const otherInc = [];
        leaseDetail?.vehicle_lease_data?.lease_data?.specialIncentives?.foreach((incentivesVal) => {

            otherInc.push(incentivesVal?.incentive_id);
        });

        setIncentivesIds(otherInc?.join(","));
    }, [leaseDetail?.vehicle_lease_data?.lease_data?.specialIncentives])

    
    return (
        <Tab.Pane className="mt-2" eventKey="Lease">
            <Row className="g-4">
                {!isEmpty(leaseDetail?.page_title) && (!isLoading || !changeLoading) ?
                    <Col lg={6} className=" main-animation">
                        <div className="d-flex flex-column justify-content-between bottom-b main-text-detail white-bg p-3 full-height">
                            <div>
                                <div className="d-flex flex-column align-items-center top-b p-2">
                                    <div className="d-flex align-items-center justify-content-center mb-2">
                                        <h4 className="me-1 font-px m-0 font-w" style={{ opacity: "0.8" }}>Potential Savings</h4>
                                    </div>
                                    <h1 className="text-center font-wx font-black" style={{ fontSize: "56px" }}>${leaseDetail?.vehicle_lease_data?.total_saving_amount
                                        ? NumberWithThousandSeparator(leaseDetail?.vehicle_lease_data?.total_saving_amount)
                                        : 0}</h1>
                                    {leaseDetail?.vehicle_lease_data?.total_saving_amount > 0 &&
                                        <span className="font-smallx font-w3 font-black">*Subject to eligibility</span>
                                    }
                                </div>
                                {leaseDetail?.vehicle_lease_data?.total_potential_savings && leaseDetail?.vehicle_lease_data?.total_potential_savings.length > 0 &&
                                    <div className="pt-3 mt-2 pb-2 top-b">
                                        {leaseDetail?.vehicle_lease_data?.total_potential_savings?.map(
                                            (object, i) => {
                                                return (
                                                    <div style={{ position: "relative" }} key={i} className={((object?.is_included_in_monthly_payment === true && object?.is_combinable === false) ? "pb-4-5" : (object?.is_included_in_monthly_payment === true || object?.is_combinable === false) ? 'pb-4-5' : 'pb-3')}>
                                                        <div className={"d-flex justify-content-between "} key={i}>
                                                            <div>
                                                                <span className="font-large font-w4 text-decoration-underline all-incentive replace-hyphen green-hover-text"
                                                                    onClick={() => handleHideShowClick("lease_potential_savings_" + i)}
                                                                    aria-disabled={isLoading}>{isEmpty(object?.incentive_name_text) ? object?.incentive_name : object?.incentive_name_text}</span>

                                                                {object?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }
                                                                {object?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className={"text-font p-absolute replace-hyphen " + (object?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <span className="font-large font-w4 font-black replace-hyphen">{
                                                                isEmpty(object?.incentive_amount_text) ? object?.incentive_amount : object?.incentive_amount_text
                                                            }</span>

                                                        </div>
                                                        {expandedRows["lease_potential_savings_" + i] &&
                                                            <div className={"detail-popup " + ((object?.is_included_in_monthly_payment === true && object?.is_combinable === false) ? "detail-popup-top-double" : (object?.is_included_in_monthly_payment === true || object?.is_combinable === false) ? '' : '')}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={object} specialIncentivesKey={"lease_potential_savings_" + i} />
                                                            </div>}
                                                    </div>
                                                )
                                            }
                                        )}
                                        {/* <span className="font-smallx font-w3 font-color" style={{ marginTop: "-1rem" }}>Included as capitalized cost reduction.</span> */}
                                    </div>}
                                {leaseDetail?.vehicle_lease_data?.specialIncentives && leaseDetail?.vehicle_lease_data?.specialIncentives?.length > 0 &&
                                    <div className={"pt-3 pb-2 top-b"}>
                                        <h6 className="mt-2 m-0 mb-1 incentiver-title">Special Programs</h6>
                                        {leaseDetail?.vehicle_lease_data?.specialIncentives?.map((specialIncentivesVal, specialIncentivesKey) => {
                                            return (
                                                <div style={{ position: "relative" }} className={((specialIncentivesVal?.is_included_in_monthly_payment === true && specialIncentivesVal?.is_combinable === false) ? "pb-4" : (specialIncentivesVal?.is_included_in_monthly_payment === true || specialIncentivesVal?.is_combinable === false) ? 'pb-4-5' : 'pb-3')} key={specialIncentivesKey}>
                                                    <div className={"d-flex justify-content-between "}>
                                                        <div>
                                                            <span className="all-incentive saving-detail replace-hyphen green-hover-text"
                                                                style={{ cursor: "pointer" }}
                                                                aria-disabled={isLoading}
                                                                onClick={() => handleHideShowClick("other_incentive_" + specialIncentivesKey)}
                                                            >{isEmpty(specialIncentivesVal?.incentive_name_text) ? specialIncentivesVal?.incentive_name : specialIncentivesVal?.incentive_name_text}</span>
                                                            {specialIncentivesVal?.is_combinable === false &&
                                                                <Fragment>
                                                                    <br />
                                                                    <span className="text-font p-absolute">Exclusions apply</span>
                                                                </Fragment>
                                                            }
                                                            {specialIncentivesVal?.is_included_in_monthly_payment === true &&
                                                                <Fragment>
                                                                    <br />
                                                                    <span className={"text-font p-absolute replace-hyphen " + (specialIncentivesVal?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                        <span className="replace-hyphen">{isEmpty(specialIncentivesVal?.incentive_amount_text) ? specialIncentivesVal?.incentive_amount : specialIncentivesVal?.incentive_amount_text}</span>
                                                    </div>
                                                    {expandedRows["other_incentive_" + specialIncentivesKey] &&
                                                        <div className={"detail-popup " + ((specialIncentivesVal?.is_included_in_monthly_payment === true && specialIncentivesVal?.is_combinable === false) ? "detail-popup-top-double" : (specialIncentivesVal?.is_included_in_monthly_payment === true || specialIncentivesVal?.is_combinable === false) ? '' : '')}>
                                                            <IncentiveDetailPopup
                                                                tooltipText={tooltipText}
                                                                handleHideShowClick={handleHideShowClick} IncentiveObject={specialIncentivesVal} specialIncentivesKey={"other_incentive_" + specialIncentivesKey} />
                                                        </div>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                {leaseDetail?.vehicle_lease_data?.promotionsIncentives && leaseDetail?.vehicle_lease_data?.promotionsIncentives?.length > 0 && (
                                    <div className={"pb-2 pt-3 top-b"}>
                                        <Fragment>
                                            <h6 className="m-0 mt-2 mb-1 incentiver-title">Promotions</h6>
                                            {leaseDetail?.vehicle_lease_data?.promotionsIncentives?.map((promotionsIncentivesValue, promotionsIncentivesKey) => {
                                                return (
                                                    <div style={{ position: "relative" }} className={((promotionsIncentivesValue?.is_included_in_monthly_payment === true && promotionsIncentivesValue?.is_combinable === false) ? "pb-4-5" : (promotionsIncentivesValue?.is_included_in_monthly_payment === true || promotionsIncentivesValue?.is_combinable === false) ? 'pb-4-5' : 'pb-3')} key={promotionsIncentivesKey}>
                                                        <div className={"d-flex justify-content-between "}>
                                                            <div className="detail-incentive-name">
                                                                <span
                                                                    className="font-large font-w4 font-Gunmetal text-decoration-underline detail-incentive-name all-incentive pe-5 cursor-pointer replace-hyphen"
                                                                    onClick={() => handleHideShowClick("promotions_incentive_" + promotionsIncentivesKey)}
                                                                    aria-disabled={isLoading}
                                                                >{isEmpty(promotionsIncentivesValue?.incentive_name_text) ? promotionsIncentivesValue?.incentive_name : promotionsIncentivesValue?.incentive_name_text}</span>

                                                                {promotionsIncentivesValue?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }

                                                                {promotionsIncentivesValue?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className={"text-font p-absolute replace-hyphen " + (promotionsIncentivesValue?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="detail-incentive-amount-div">
                                                                <span
                                                                    className="font-large font-w4 font-black detail-incentive-amount replace-hyphen"
                                                                >{isEmpty(promotionsIncentivesValue?.incentive_amount_text) ? promotionsIncentivesValue?.incentive_amount : promotionsIncentivesValue?.incentive_amount_text}</span>
                                                            </div>
                                                        </div>
                                                        {expandedRows["promotions_incentive_" + promotionsIncentivesKey] &&
                                                            <div className={"detail-popup " + ((promotionsIncentivesValue?.is_included_in_monthly_payment === true && promotionsIncentivesValue?.is_combinable === false) ? "detail-popup-top-double" : (promotionsIncentivesValue?.is_included_in_monthly_payment === true || promotionsIncentivesValue?.is_combinable === false) ? '' : '')}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={promotionsIncentivesValue} specialIncentivesKey={"promotions_incentive_" + promotionsIncentivesKey} />
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}

                                        </Fragment>
                                    </div>
                                )}

                                {chargingIncentives && chargingIncentives.length > 0 && (
                                    <div className={"pb-2 pt-3 top-b"}>
                                        <Fragment>
                                            <h6 className="m-0 mt-2 mb-1 incentiver-title">Charging Incentives</h6>
                                            {chargingIncentives?.map((chargingIncentiveValue, chargingIncentivesKey) => {
                                                return (
                                                    <div style={{ position: "relative" }} className={((chargingIncentiveValue?.is_included_in_monthly_payment === true && chargingIncentiveValue?.is_combinable === false) ? "pb-4-5" : (chargingIncentiveValue?.is_included_in_monthly_payment === true || chargingIncentiveValue?.is_combinable === false) ? 'pb-4-5' : 'pb-3')} key={chargingIncentivesKey}>
                                                        <div className={"d-flex justify-content-between "}>
                                                            <div className="detail-incentive-name">
                                                                <span
                                                                    className="font-large font-w4 font-Gunmetal text-decoration-underline all-incentive pe-5 cursor-pointer replace-hyphen green-hover-text"
                                                                    onClick={() => handleHideShowClick("charging_incentive_" + chargingIncentivesKey)}
                                                                    aria-disabled={isLoading}
                                                                >{isEmpty(chargingIncentiveValue?.incentive_name_text) ? chargingIncentiveValue?.incentive_name : chargingIncentiveValue?.incentive_name_text}</span>
                                                                {chargingIncentiveValue?.is_combinable === false &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className="text-font p-absolute">Exclusions apply</span>
                                                                    </Fragment>
                                                                }
                                                                {chargingIncentiveValue?.is_included_in_monthly_payment === true &&
                                                                    <Fragment>
                                                                        <br />
                                                                        <span className={"text-font p-absolute replace-hyphen " + (chargingIncentiveValue?.is_combinable === false && 'margin-min')}>Applied to monthly payment</span>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="detail-incentive-amount-div">
                                                                <span
                                                                    className="font-large font-w4 font-black detail-incentive-amount replace-hyphen"
                                                                >{isEmpty(chargingIncentiveValue?.incentive_amount_text) ? chargingIncentiveValue?.incentive_amount : chargingIncentiveValue?.incentive_amount_text}</span>
                                                            </div>
                                                        </div>
                                                        {expandedRows["charging_incentive_" + chargingIncentivesKey] &&
                                                            <div className={"detail-popup " + ((chargingIncentiveValue?.is_included_in_monthly_payment === true && chargingIncentiveValue?.is_combinable === false) ? "detail-popup-top-double" : (chargingIncentiveValue?.is_included_in_monthly_payment === true || chargingIncentiveValue?.is_combinable === false) ? 'detail-popup-top-double-helf' : '')}>
                                                                <IncentiveDetailPopup
                                                                    tooltipText={tooltipText}
                                                                    handleHideShowClick={handleHideShowClick} IncentiveObject={chargingIncentiveValue} specialIncentivesKey={"charging_incentive_" + chargingIncentivesKey} />
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}

                                        </Fragment>
                                    </div>
                                )}
                            </div>
                            <div className="">
                                <span className="pt-4 pb-2 detail-search-link d-block align-items-center mt-3 font-midium">For the full list of State, Local, and Utility incentives that may be available for your location, visit&nbsp;
                                    <Link className="text-dark btn-incentive-finder font-wx p-0 green-hover-text" to={"/incentivefinder/" + CryptoJS.MD5(leaseDetail?.vehicle_lease_data?.lease_data?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" +
                                        createSeoFriendlyUrl(leaseDetail?.vehicle_lease_data?.lease_data?.make) + "/" + createSeoFriendlyUrl(leaseDetail?.vehicle_lease_data?.lease_data?.model) + "/" + leaseDetail?.vehicle_lease_data?.lease_data?.model_year + "/" + createSeoFriendlyUrl(leaseDetail?.vehicle_lease_data?.lease_data?.trim) + "?type=lease"
                                    }>
                                        Incentive Finder</Link><span>.</span></span>
                            </div>

                            <div className="d-flex flex-column d-none">
                                <MyLink className="btn-learn-more text-center" to={"/incentivesummary/" + leaseDetail?.vehicle_lease_data?.lease_data?.vehicle_id + "/Lease/" + leaseDetail?.vehicle_lease_data?.lease_data
                                    ?.vehicle_condition + "/" + incentivesIds + "/0/" + filter?.lease_term + "/" + filter?.annual_mileage} rel="noopener noreferrer">View Summary</MyLink>
                            </div>
                        </div>
                    </Col> :
                    <Col lg={6}>
                        <div className="">
                            <SkeletonComponent count={1} className="height-4" />
                        </div>
                    </Col>}
                {!isEmpty(leaseDetail?.page_title) && (!isLoading || !changeLoading) ?
                    <Col lg={6} className=" main-animation">
                        <div className="main-text-detail pb-2 p-3 white-bg full-height">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <h4 className="me-1 font-px m-0 font-w m-text-center" style={{ opacity: "0.8" }}>Estimated Monthly Payment
                                    {/* {!isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) && "*"} */}
                                </h4>
                            </div>
                            {isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) ?
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h4 className="me-1 font-px m-0 mt-1" style={{ color: "#21272A" }}>Not Available</h4>
                                </div>
                                :
                                <Fragment>
                                    <h1 className="text-center d-flex justify-content-center align-items-center font-big1 font-wx font-black">${LeaseAmountPerMonthFormate(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month)}
                                        <p className="text-month" style={{ fontSize: "24px", fontWeight: "800" }}>&nbsp;/ month</p>
                                    </h1>

                                    <div className="mb-4 d-flex justify-content-center align-items-center">
                                        {/* <span className="font-midium font-w font-black">Excludes taxes and fees.</span> */}
                                        <span className="font-smallx font-w3 font-black">*Excludes taxes and fees</span>
                                    </div>
                                </Fragment>
                            }

                            <Row className={"align-items-center g-1 mb-4 pb-1 m-text-center " + (leaseDetail?.lease_term_list && isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) && "mb-4")}>
                                <Col lg={2}></Col>
                                <Col lg={4} md={6}>
                                    {leaseDetail?.lease_term_list && leaseDetail?.lease_term_list?.length > 0 && (
                                        <Fragment>
                                            <p className="font-midium mb-1 font-Gunmetal" style={{ fontWeight: "600" }}>Lease Term (Months)</p>
                                            <ButtonGroup className="btn-group-main" aria-label="Basic example">
                                                {leaseDetail?.lease_term_list?.map((leaseTermVal, leaseTermKey) => {
                                                    return (
                                                        Number(filter?.lease_term) === Number(leaseTermVal) ? (
                                                            <Button value={leaseTermVal} className={Number(filter?.lease_term) === Number(leaseTermVal) ? "btn-group selected-lease-term" : "btn-group"} key={leaseTermKey}>{leaseTermVal}</Button>
                                                        ) : (
                                                            <Button
                                                                value={Number(leaseTermVal)}
                                                                className="btn-group"
                                                                // disabled={isLoading}
                                                                onClick={(e) => onUpdate("lease_term", Number(e.target.value))}
                                                                key={leaseTermKey}
                                                            >{leaseTermVal}</Button>
                                                        )
                                                    )
                                                })}
                                            </ButtonGroup>
                                        </Fragment>
                                    )}
                                </Col>
                                <Col lg={4} md={6} className="m-center-section">
                                    {leaseDetail?.annual_mileage_list && leaseDetail?.annual_mileage_list?.length > 1 && (
                                        <Fragment>
                                            <span className="font-midium text-detaile" style={{ fontWeight: "700" }}>Estimated Annual Mileage</span>
                                            <Form.Select
                                                aria-label="Default select example"
                                                className="font-large font-wxl font-color annual_mileage_select"
                                                // disabled={isLoading}
                                                defaultValue={Number(filter?.annual_mileage || 0)}
                                                onChange={(e) => onUpdate("annual_mileage", e.target.value)}
                                            >
                                                {leaseDetail?.annual_mileage_list?.map((annualMileageVal, annualMileageKey) => {
                                                    return (
                                                        <option
                                                            key={annualMileageKey}
                                                            value={Number(annualMileageVal)}
                                                            selected={Number(filter?.annual_mileage) == Number(annualMileageVal)}
                                                        >{annualMileageVal ? NumberWithThousandSeparator(annualMileageVal) : 0}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Fragment>
                                    )}
                                </Col>
                                <Col lg={2}></Col>
                            </Row>
                            {leaseDetail?.down_payment_list && leaseDetail?.down_payment_list?.length > 0 &&
                                <Row className="mt-5">
                                    {/* {!isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.down_payment) &&
                                <p className="mb-4 pb-1 text-center font-midium font-w font-black">*${leaseDetail?.vehicle_lease_data?.lease_data?.down_payment ? NumberWithThousandSeparator(leaseDetail?.vehicle_lease_data?.lease_data?.down_payment) : 0} down payment, taxes and fees due at signing.</p>} */}
                                    <Col lg={2}>
                                    </Col>
                                    <Col lg={8} md={6}>
                                        <div className="w-50 mx-auto mb-4">
                                            <span className="font-midium text-detaile" style={{ fontWeight: "700" }}>Down Payment</span>
                                            {(leaseDetail?.down_payment_list && leaseDetail?.down_payment_list?.length > 1) ?
                                            <Form.Select
                                                aria-label="Default select example"
                                                className="font-large font-large font-color down_payment_select"
                                                // disabled={isLoading}
                                                defaultValue={Number(filter?.lease_down_payment)}
                                                // value={Number(filter?.down_payment)}
                                                onChange={(e) => onUpdate("lease_down_payment", Number(e.target.value))}
                                            >
                                                {leaseDetail?.down_payment_list?.map((downPaymentVal, downPaymentKey) => {
                                                    return (
                                                        <option
                                                            key={downPaymentKey}
                                                            value={Number(downPaymentVal)}
                                                            selected={Number(filter?.lease_down_payment) === Number(downPaymentVal)}
                                                        >{downPaymentVal ? '$' + NumberWithThousandSeparator(downPaymentVal) : 0}</option>
                                                    )
                                                })}
                                            </Form.Select> :
                                            <Form.Control className="font-large font-color mb-4 down_payment_select" type="text" defaultValue={"$" + NumberWithThousandSeparator(leaseDetail?.vehicle_lease_data?.lease_data?.down_payment)} readOnly={true} />
                                            }
                                        </div>
                                    </Col>
                                </Row>}
                            <div className="border-T">
                                {!isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) &&
                                    <div className="pt-4">
                                        <h5 className="font-px font-w font-Gunmetal mb-3">Leasing Information & Disclosures</h5>
                                        <p className="font-midium font-w4 font-black mb-3">These are estimated monthly payments if you participate in applicable lease agreement.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Estimates are subject to lease approval.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Taxes and fees may vary by state.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Does not include maintenance or insurance.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Security deposit not required.</p>
                                        <p className="font-midium font-w4 font-black mb-3">Available incentives will be applied towards the amount due at signing or Capitalized Cost Reduction.</p>
                                    </div>}
                            </div>
                        </div>

                    </Col> :
                    <Col lg={6}>
                        <div className="">
                            <SkeletonComponent count={1} className="height-4" />
                        </div>
                    </Col>
                }
            </Row>
        </Tab.Pane>
    );
}

export default withRouter(LeaseIncentiveSection);