import { Row, Col, Form, Button, } from 'react-bootstrap';

export const IncentiveComparePopup = ({
    compareVehicles,
    handleCompareCheck,
    onClickCompareVehicle,
    compareVehiclesError
}) => {
    return (
        <section className='compare-popup'>
            <Row>
                <Col lg={12}>
                    <div className={!compareVehiclesError ? "mb-4" : "mb-2"}>
                        <h5 className='font-midium font-wx text-center m-0'>Compare up to 3 vehicles</h5>
                        {compareVehiclesError &&
                            <span className="warning-msg-red text-center d-block mt-2 mb-1">{compareVehiclesError}</span>}
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <button className="btn-learn-more text-center" target="_blank" rel="noopener noreferrer" onClick={onClickCompareVehicle}>Compare ({compareVehicles?.length})</button>
                        <button className='font-wx text-dark font-w popupclose link-button' onClick={() => handleCompareCheck(false)}>Cancel</button>
                    </div>
                </Col>
            </Row>
        </section>
    )
}
