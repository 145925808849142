import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { withRouter, Link, useLocation } from "react-router-dom";
import * as apiHelper from "../../helper/api_helper";
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = ({
    history
}) => {

    const [pageTitle, setpageTitle] = useState('');
    const [pageDescription, setPageDescription] = useState('');
    const location = useLocation();

    const goBack = () => {
        history.goBack();  // Navigates to the previous page
        setTimeout(() => {
            window.scrollTo(0, 0); // Add a small delay
        }, 100);
    }

    async function getPageMetaData(params = {}) {
        try {
            const response = await apiHelper.postPageMetaData(params);
            if (response?.data?.status === 1) {
                // document.title = response?.data?.body?.page_title;
                // document.querySelector('meta[name="description"]').setAttribute("content", response?.data?.body?.page_meta_description);
                setpageTitle(response?.data?.body?.page_title);
                setPageDescription(response?.data?.body?.page_meta_description);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPageMetaData({ page_id: 8 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}${location.pathname}`} />
            </Helmet>
            <section className="banner-privacy-wapper">
                <div className="position-stick d-flex align-items-center" style={{ background: "#1f4427" }}>
                    <Container>
                        <h3 className="font-wx font-largeX font-white m-0 py-3">Privacy Policy</h3>
                    </Container>
                </div>
                <Container>
                <div className="">
                    <ul className="d-flex p-0 list-style-none mb-0">
                        <li>
                            <span>
                                <Link className="text-decoration-none font-color font-small green-hover-text" to="/">Home</Link>
                            </span>
                        </li>
                        <li className="mx-1">
                            <span className="font-small">›</span>
                        </li>
                        <li><span className="a-list-item">
                            <span className="text-decoration-none font-color font-small">Privacy policy</span>
                        </span>
                        </li>
                    </ul>
                </div>
                </Container>
                <Container>
                    <div className='pt-1 text-end'>
                        <button onClick={goBack} className="button-set">
                            <svg style={{ cursor: "pointer" }} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                        </button>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div className="">
                                <span className="font-large font-w4 font-black">At Siffra, we are committed to protecting privacy and ensuring the security of personal information. All our products and services are designed to be in compliance with data regulations as required.</span>
                                <p className="my-3 font-large font-w4 font-black">This Privacy Policy explains how we collect, use, disclose, and protect your personal data when you visit our website or use our mobile app. By accessing or using Siffra website, mobile app, or services, you consent to the practices described in this Privacy Policy.</p>
                                <span className="font-large font-w font-black">Information We Collect</span>
                                <p className="mb-3 font-large font-w4 font-black">We may collect various types of information from individuals and organizations who interact with our website, mobile app, and services, including, but not limited to:</p>
                                <p className="font-large font-w4 font-black">Personal Information:</p>
                                <ul>
                                    <li className="font-large font-w4 font-black">Name</li>
                                    <li className="font-large font-w4 font-black">Email address</li>
                                    <li className="font-large font-w4 font-black">Phone number</li>
                                    <li className="font-large font-w4 font-black">Zip Code</li>
                                </ul>
                                <p className="font-large font-w4 font-black">Usage Information:</p>
                                <ul className="font-large font-w4 font-black">
                                    <li>Information about your interactions with our website, app, and services.</li>
                                    <li>IP address, browser type, and operating system.</li>
                                    <li>Cookies and similar tracking technologies.</li>
                                </ul>
                                <p className="font-large font-w font-black">How We Use Your Information</p>
                                <p className="font-large font-w4 font-black">We use the information we collect for the following purposes:</p>
                                <p className="mt-3 font-large font-w4 font-black">Providing Services:</p>
                                <ul className="font-large font-w4 font-black">
                                    <li>To deliver the services you request, including access to our EV Incentive Finder product and tailored information based on your preferences.</li>
                                </ul>
                                <p className="mt-3 font-large font-w4 font-black">Communication</p>
                                <ul className="font-large font-w4 font-black">
                                    <li>To communicate with you about our services, updates, and relevant offers.</li>
                                    <li>To respond to your inquiries and provide customer support.</li>
                                </ul>
                                <p className="mt-3 font-large font-w4 font-black">Legal and Regulatory Compliance</p>
                                <ul className="font-large font-w4 font-black">
                                    <li>To comply with legal obligations, including tax credit eligibility and reporting requirements.</li>
                                </ul>
                                <p className="mt-3 font-large font-w4 font-black">Improving Services</p>
                                <ul className="font-large font-w4 font-black">
                                    <li>To analyze user behavior and preferences to enhance our services, content, and user experience.</li>
                                </ul>
                                <p className="font-large font-w font-black">Disclosure of Your Information</p>
                                <p className="font-large font-w4 font-black">We may share your information in the following circumstances:</p>
                                <div className="ms-3">
                                    <li className="mt-3 font-large font-w4 font-black">Partners and Service Providers</li>
                                    <p className="font-large font-w4 font-black margin-l">We may share your information with our partners and service providers to facilitate the delivery of our services. These partners and service providers are obligated to protect your data</p>
                                    <li className="mt-3 font-large font-w4 font-black">Legal Obligations</li>
                                    <p className="font-large font-w4 font-black margin-l">We may disclose your information in response to a legal request, such as a subpoena, court order, or government investigation.</p>
                                    <li className="mt-3 font-large font-w4 font-black">Business Transfers</li>
                                    <p className="font-large font-w4 font-black margin-l">In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</p>
                                </div>
                                <p className="mt-3 font-large font-w font-black">Your Choices</p>
                                <p className="font-large font-w4 font-black">You have the following choices regarding your information:</p>
                                <div className="ms-3">
                                    <li className="mt-3 font-large font-w4 font-black">Access and Update</li>
                                    <p className="font-large font-w4 font-black margin-l">You can access and update your personal information by logging into your account on our website.</p>
                                    <li className="mt-3 font-large font-w4 font-black">Opt-Out</li>
                                    <p className="font-large font-w4 font-black margin-l">You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.</p>
                                </div>
                                <p className="mt-3 font-large font-w font-black">Security</p>
                                <p className="font-large font-w4 font-black">We take all possible measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage can be guaranteed to be 100% secure.</p>
                                <p className="mt-3 font-large font-w font-black">Updates to Privacy Policy</p>
                                <p className="font-large font-w4 font-black">We may update this Privacy Policy to reflect changes in our practices or for legal and regulatory reasons. We will notify you of any significant changes through the website or other means.</p>
                                <p className="mt-3 font-large font-w font-black">Contact us</p>
                                <p className="font-large font-w4 font-black">Your privacy and trust are important to us, and we are committed to safeguarding your information. If you have any questions or concerns about our Privacy Policy, please contact us at <span className="text-decoration-underline green-hover-text">support@siffra.com</span><span className="small-space">.</span></p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-5 pb-2 last-updated-date">
                        <Col>
                            <label className="font-small font-w4 font-black">Last updated: Sep 29, 2024</label>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default withRouter(PrivacyPolicy);