import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent"
import { Row, Col, Container, Form, Button, CloseButton } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';

const IDSLoader = ({
    hideFilter = false
}) => {
    const loopCount = 1;
    return (
        <Fragment>
            {/* <div className={"table-responsive height-100"}>
                <div className="table-striped w-100 table-hover fixed-table">
                    <div className="s-NewTbody">
                        {[...Array(loopCount)].map((x, i) => {
                            return (
                                <Fragment key={i}>
                                    <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row"/>
                                </Fragment>
                            );
                        })
                        }
                    </div>
                </div>
            </div> */}
            <div className="bg-incentive-detail-top">
                <div className="text-p-detaile-new" style={{ alignContent: 'center' }}>
                    <h1 className="font-white font-wx m-0" style={{ fontSize: '32px', paddingBottom: '0px' }}>
                        Incentive Finder
                    </h1>
                </div>
            </div>
            <div class="px-lg-5 px-3"><ul class="d-flex p-0 list-style-none mb-0"><li><span><a class="text-decoration-none font-color font-small green-hover-text" href="/">Home</a></span></li><li class="mx-1"><span class="font-small">›</span></li><li><span class="a-list-item"><span class="text-decoration-none font-color font-small">Incentive Finder</span></span></li></ul></div>
            <section className="Incentive-Finder mt-4">
                <Container fluid className="px-5">
                    <Row>
                        {!hideFilter &&
                        <Col lg={3}>
                            <div className="w-100">
                                <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row maximum-h" />
                            </div>
                        </Col>}
                        <Col lg={hideFilter ? 12 : 9}>
                            <div class="d-flex align-items-center justify-content-between flex-wrap mt-3"><div class="py-3"><p class="font-white font-midium font-black">Search by Zip code and Vehicle for the full list of Manufacturer, Federal, State, Local, and Utility incentives available for your location.</p></div></div>
                            <h5 class="my-3 font-w font-px font-black">Vehicle Incentives</h5>
                            <table width="100%" className="rounded">
                                <thead>
                                    <tr>
                                        <th className="table-header border-left pdg-set" style={{ padding: '16px', width: '30%' }}>Incentive Name</th>
                                        <th className="table-header pdg-set" style={{ padding: '16px', width: '25%' }}>Provider</th>
                                        <th className="table-header pdg-set" style={{ padding: '16px', width: '25%' }}>Type</th>
                                        <th className="table-header pdg-set border-rigth" style={{ padding: '16px', width: '25%' }}>Potential Savings</th>
                                    </tr>
                                    <tr className="border-tr">
                                        <td colSpan={4}>
                                            <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row " />
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                            <div className="my-5">
                                <h5 class="my-3 font-w font-px font-black">Charging Incentives</h5>
                                <table width="100%" className="rounded">
                                    <thead>
                                        <tr>
                                            <th className="table-header border-left pdg-set" style={{ padding: '16px', width: '30%' }}>Incentive Name</th>
                                            <th className="table-header pdg-set" style={{ padding: '16px', width: '25%' }}>Provider</th>
                                            <th className="table-header pdg-set" style={{ padding: '16px', width: '25%' }}>Type</th>
                                            <th className="table-header pdg-set border-rigth" style={{ padding: '16px', width: '25%' }}>Potential Savings</th>
                                        </tr>
                                        <tr className="border-tr">
                                            <td colSpan={4}>
                                                <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row " />
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default withRouter(IDSLoader);