import React, { useState, useMemo, useEffect } from "react";
import * as apiHelper from "../../helper/api_helper";
import { defaultValueIncentiveFinderFilters, defaultValueQuizFilter } from "../../components/EVIncentiveFinderFilters";
import { useSelector, useDispatch } from "react-redux";
// import { IncentiveFinderByVehicle, setIncentiveFinderByVehicleLoading, updateIncentiveFinderByVehicle } from "../../store/action";
import { setEVIncentiveFinderLoading, updateEVIncentiveFinder, updateEVIncentiveFinderFiltersData, setEVIncentiveFinderFiltersLoading } from "../../store/action";
// import styled from "styled-components";
import { CovertEVIncentiveFinderData, CovertEVIncentiveFinderQuizData, ArrayAsc, isEmpty } from "../../helper/project_helper";

import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useMyHistory } from "../../components/hooks/useMyHistory";
import PageLoaderComponent from "../../components/PageLoaderComponent";
import { Helmet } from 'react-helmet-async';

const EVIncentiveFinder = ({ props }) => {

	const { stateEVIncentiveFinderFilters, stateIncentives } = useSelector((state) => ({
		stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
		stateIncentives: state.EVIncentiveFinder,
	}));

	// const [dispBlank, setdispBlank] = useState(true);
	const [first, setFirstLoading] = useState(false);
	const [scrollTop, setScrollTop] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reloadFilter, setReloadFilter] = useState(false);

	const [shortBy, setShortBy] = useState(stateIncentives?.filter?.short_by || '1');
	const [eligible, setEligible] = useState(stateIncentives?.filter?.eligible || false);
	const [is0Apr, setIs0Apr] = useState(stateIncentives?.filter?.is_0_apr || false);

	const [filterState, setFilterState] = useState(stateIncentives?.filter || defaultValueIncentiveFinderFilters());
	const [filter, setFilter] = useState(stateIncentives?.filter || defaultValueIncentiveFinderFilters());

	// eslint-disable-next-line
	// const [quizFilterState, setQuizFilterState] = useState(stateIncentives?.quizFilter || defaultValueQuizFilter());
	const [quizFilter, setQuizFilter] = useState(stateIncentives?.quizFilter || defaultValueQuizFilter());
	const [quizView, setQuizView] = useState(stateIncentives?.quizView || false);
	const [expandedRows, setExpandedRows] = useState(stateIncentives?.expandedRows || {});
	const [compareVehicles, setCompareVehicles] = useState([]);
	const [compareVehiclesChecked, setCompareVehiclesChecked] = useState([]);
	const [compareVehiclesError, setCompareVehiclesError] = useState(false);
	const [changeReset, setchangeReset] = useState(false);
	const [disableFilter, setDisableFilter] = useState(false);
	const [offset, setOffset] = useState(0);

	const [lastUpdatedDate, setLastUpdatedDate] = useState(stateIncentives?.last_updated_date || false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [selectedSegments, setSelectedSegments] = useState(isEmpty(stateIncentives?.filter?.segments) ? [] : stateIncentives?.filter?.segments?.split(","));
	const [selectedModelYear, setSelectedModelYear] = useState(isEmpty(stateIncentives?.filter?.model_year) ? [] : stateIncentives?.filter?.model_year?.split(","));



	const onClose = React.useCallback(() => {

	}, []);

	// useEffect(() => {
	// 	if (firstLoad === true) {
	// 		setTimeout(() => {
	// 			window.scrollTo(0, 0); // Add a small delay
	// 			setFirstLoad(false);
	// 		}, 100);
	// 	}
	// })
	const dispatch = useDispatch();
	const history = useMyHistory();


	// const { stateEVIncentiveFinderFilters, stateIncentives } = useSelector((state) => ({
	// 	stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
	// 	stateIncentives: state.EVIncentiveFinder,
	// }));

	const tooltipText = useMemo(() => {
		if (stateEVIncentiveFinderFilters?.data !== false) {
			if (stateEVIncentiveFinderFilters?.data?.tooltips !== null) {
				return stateEVIncentiveFinderFilters?.data?.tooltips;
			}
		}
		return null;
	}, [stateEVIncentiveFinderFilters]);

	const page_title = useMemo(() => {
		if (stateEVIncentiveFinderFilters?.data !== false) {
			if (stateEVIncentiveFinderFilters?.data?.page_title !== null) {
				return stateEVIncentiveFinderFilters?.data?.page_title;
			}
		}
		return null;
	}, [stateEVIncentiveFinderFilters]);

	const page_meta_description = useMemo(() => {
		if (stateEVIncentiveFinderFilters?.data !== false) {
			if (stateEVIncentiveFinderFilters?.data?.page_meta_description !== null) {
				return stateEVIncentiveFinderFilters?.data?.page_meta_description;
			}
		}
		return null;
	}, [stateEVIncentiveFinderFilters]);



	// useEffect(() => {
	// if (!isEmpty(page_title)) {
	// 	document.title = page_title;
	// }
	// if (!isEmpty(page_meta_description)) {
	// 	document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
	// }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [page_title, page_meta_description]);


	useEffect(() => {
		dispatch(updateEVIncentiveFinder({
			expandedRows: expandedRows
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expandedRows]);

	useEffect(() => {
		const fetchData = async () => {
			if (stateIncentives.loading === false && first === false && stateIncentives.error === '') {
				if (stateIncentives?.filter !== undefined && stateIncentives?.filter !== false) {
					const lastFilter = JSON.parse(JSON.stringify(stateIncentives?.filter));
					const currentFilter = JSON.parse(JSON.stringify(filter));
					const lastQuizFilter = JSON.parse(JSON.stringify(stateIncentives?.quizFilter));
					const currentQuizFilter = JSON.parse(JSON.stringify(quizFilter));

					if (JSON.stringify(ArrayAsc({ ...lastFilter, ...lastQuizFilter })) !== JSON.stringify(ArrayAsc({ ...currentFilter, ...currentQuizFilter }))) {
						setLoading(false);
						const tmp_fil = JSON.parse(JSON.stringify(filter));
						tmp_fil['short_by'] = shortBy;
						tmp_fil['eligible'] = eligible;
						tmp_fil['is_0_apr'] = is0Apr;

						let res = '';
						if (quizView === false) {
							res = await getIncentives(tmp_fil, true);
						} else {
							res = await getQuizData({ ...tmp_fil }, { ...quizFilter }, false, true);
						}
						setNotFound(true);
					}
				} else {
					setLoading(false);
					const tmp_fil = JSON.parse(JSON.stringify(filter));
					tmp_fil['short_by'] = shortBy;
					tmp_fil['eligible'] = eligible;
					tmp_fil['is_0_apr'] = is0Apr;

					if (quizView === false) {
						await getIncentives(tmp_fil, false);
					} else {
						await getQuizData({ ...tmp_fil }, { ...quizFilter }, false, false);
					}
					setNotFound(true);
				}
			}
		};

		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, changeReset]);


	const expandAllRows = (filter, table_data_array) => {
		if (filter !== null && filter !== false && filter !== undefined) {

			// if (!isEmpty(filter?.make) || !isEmpty(filter?.model) || !isEmpty(filter?.model_year) || !isEmpty(filter?.base_msrp) || !isEmpty(filter?.segments) || !isEmpty(filter?.base_msrp) || filter?.potential_savings === true || filter?.eligible === true) {
			let exp = {};
			table_data_array.forEach(async (item_val, item_key) => {
				if (!("maker" in item_val)) {
					const rowId = "hidden_model_row" + item_key;
					const currentExp = { ...exp };
					exp = { ...currentExp, [rowId]: true }
					item_val?.trimData?.forEach(async (trim_item_val, trim_item_key) => {
						const trimRow = "hidden_trim_row" + trim_item_val?.vehicle_id;
						const currentExp = { ...exp };
						exp = { ...currentExp, [trimRow]: true }

					})
				}
			})

			setExpandedRows(exp);

			// }
		}
	};

	const incentives = useMemo(() => {
		if (stateIncentives.data !== false) {
			return stateIncentives.data;
		}
		return null;
	}, [stateIncentives]);
	const quizFilterState = useMemo(() => {
		if (stateIncentives?.quizFilter !== false) {
			return stateIncentives.quizFilter;
		}
		return null;
	}, [stateIncentives]);

	async function getIncentives(params = {}, top = true) {

		let ids = '';
		setLoading(true);

		dispatch(setEVIncentiveFinderLoading(true))
		try {
			const response = await apiHelper.postEVIncentiveFinder(params);
			var table_data_array = [];
			var exp = {};
			if (response.data.status === 1) {
				// table_data_array = CovertEVIncentiveFinderData(response?.data?.body);
				const convert = CovertEVIncentiveFinderData(response?.data?.body?.data);
				table_data_array = convert?.table_data_array;
				exp = convert?.exp;

				dispatch(updateEVIncentiveFinder({
					loading: false,
					status: true,
					data: table_data_array,
					filter: params,
					quizView: quizView,
					quizFilter: defaultValueQuizFilter(),
					last_updated_date: response?.data?.body?.last_updated_date,
					total_without_limit: response?.data?.body?.total_without_limit,
					msg: 'No results found.'
				}));

				setLastUpdatedDate(response?.data?.body?.last_updated_date);

				setTimeout(() => {
					const default_filter = ArrayAsc(defaultValueIncentiveFinderFilters());
					const currentFilter = ArrayAsc(params);


					if (JSON.stringify(default_filter) !== JSON.stringify(currentFilter)) {
						setExpandedRows(exp);
					} else {
						setExpandedRows({});
					}
				}, 10);

				ids = response?.data?.body?.included_vehicle_ids;

			} else {
				dispatch(updateEVIncentiveFinder({
					loading: false,
					status: true,
					error: response?.data,
					expandedRows: null,
					msg: process.env.REACT_APP_SOMTHING_WRONG_MSG
				}));
			}
			setTimeout(() => {
				// expandAllRows(params, table_data_array);
				// if (table_data_array.length === 0) {
				// document.getElementById('scroll_here').scrollIntoView({ behavior: 'smooth', block: 'start' });
				// }

				if (top === true) {
					scrollTopTable();
				} else {
					setScrollTop(true);
				}
			}, 100);


		} catch (error) {
			console.error(error);
			dispatch(updateEVIncentiveFinder({
				loading: false,
				status: false,
				error: error,
				expandedRows: null,
				msg: process.env.REACT_APP_SOMTHING_WRONG_MSG
			}));
		}
		setLoading(false);
		if (first === true) {
			setFirstLoading(false);
		}
		// setdispBlank(false);
		return ids;
	}

	const getCompareValue = (key) => {
		let cc = compareVehiclesChecked?.filter(x => x.checkExist === key);
		if (cc?.length > 0) {
			return cc[0].checked || false;
		}
		return false;
	}
	const scrollTopTable = () => {
		if (windowWidth > 1030) {
			document.getElementById('scroll_here').scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	const handleCompareCheck = (compareData) => {
		if (compareData === false) {
			setCompareVehicles([]);
			setCompareVehiclesChecked([]);
		} else {
			setCompareVehiclesError(false);
			const { value, checked } = compareData.target;
			const compareDataArray = value?.split('|');
			const exists = compareVehicles.some(v => v.checkExist === `${compareDataArray[0]}|${compareDataArray[5]}`);

			let allData = [...compareVehiclesChecked];

			let cc = allData.filter(x => x.checkExist === `${compareDataArray[0]}|${compareDataArray[5]}`);
			if (cc.length > 0) {
				let ext = cc[0].checked || false;
				cc[0].checked = !ext;
			} else {
				allData.push({
					checkExist: `${compareDataArray[0]}|${compareDataArray[5]}`,
					vehicle_id: Number(compareDataArray[0]),
					checked: true,
				});
			}

			if (checked) {
				if (!exists) {
					const pushOrRemoveObj = {
						vehicle_id: compareDataArray[0],
						make: compareDataArray[1],
						model: compareDataArray[2],
						model_year: compareDataArray[3],
						trim: compareDataArray[4],
						checkExist: `${compareDataArray[0]}|${compareDataArray[5]}`,
					}
					if ([...compareVehicles, pushOrRemoveObj].length > 3) {
						setCompareVehiclesError("Select up to 3 vehicles")
						setTimeout(() => {
							setCompareVehiclesError(false);
						}, 5000);
					} else {
						setCompareVehicles([...compareVehicles, pushOrRemoveObj]);
						setCompareVehiclesChecked(allData);
					}
				}
			} else {
				if (exists) {
					setCompareVehicles(compareVehicles.filter(v => v.checkExist !== `${compareDataArray[0]}|${compareDataArray[5]}`));
					setCompareVehiclesChecked(allData);
				}
			}
		}
	}

	const onClickCompareVehicle = () => {
		if (compareVehicles?.length < 2) {
			setCompareVehiclesError("Select at least 2 vehicles");
			setTimeout(() => {
				setCompareVehiclesError(false);
			}, 5000);
			return false;
		}
		const compare_data = JSON.stringify(compareVehicles.map(({ vehicle_id, ...rest }) => ({
			...rest
		})));
		history.push("/comparevehicles/" + compare_data, true);
	};

	const onUpdateQuizFilter = (name, value1) => {
		let value_ = { ...quizFilter };
		value_[name] = value1;
		setQuizFilter(value_);
	};

	const onChangeShort = async (value) => {
		setShortBy(value);
		const tmp_filter = JSON.parse(JSON.stringify(filter));
		tmp_filter['short_by'] = value;
		tmp_filter['eligible'] = eligible;
		tmp_filter['is_0_apr'] = is0Apr;
		if (quizView === false) {
			getIncentives(tmp_filter, true);
		} else {
			const res = await getQuizData({ ...tmp_filter }, { ...quizFilter }, false, true);

		}
		document.getElementById("short-select").blur();
	}

	const onChangeIs0Apr = async (value) => {
		setIs0Apr(value);
		if (value === true) {
			setReloadFilter(true);
		}

		const tmp_filter = JSON.parse(JSON.stringify(filter));
		const tmp_quiz_filter = JSON.parse(JSON.stringify(quizFilter));

		tmp_filter['short_by'] = shortBy;
		tmp_filter['eligible'] = eligible;
		tmp_filter['is_0_apr'] = value;

		if (tmp_quiz_filter?.purchase_type !== 'Finance') {
			tmp_quiz_filter.purchase_type = 'Finance';
			// tmp_quiz_filter.budget = 'any';
			setQuizFilter(tmp_quiz_filter);
		}
		if (quizView === false) {
			setQuizView(true);
		}
		const res = await getQuizData({ ...tmp_filter }, { ...tmp_quiz_filter }, false, true);
	}

	const onChangeEligible = async (value) => {
		setEligible(value);
		if (value === true) {
			setReloadFilter(true);
		}

		const tmp_filter = JSON.parse(JSON.stringify(filter));
		// setFilter(defaultValueIncentiveFinderFilters());
		// setFilterState(defaultValueIncentiveFinderFilters());
		// setShortBy('1');
		tmp_filter['short_by'] = shortBy;
		tmp_filter['eligible'] = value;
		tmp_filter['is_0_apr'] = is0Apr;
		let v_ids = '';
		if (quizView === false) {
			const res = await getIncentives(tmp_filter, true);
			v_ids = { 'included_vehicle_ids': res || '' };
		} else {
			const res = await getQuizData({ ...tmp_filter }, { ...quizFilter }, false, true);
			v_ids = { 'included_vehicle_ids': res || '' };
		}
		// document.getElementById("short-select").blur();
	}

	const handleShowMeOptionClick = async () => {
		setSelectedModelYear([]);
		setSelectedSegments([]);
		setScrollTop(false);
		if (quizFilter?.purchase_type === 'any' && quizFilter?.budget === 'any') {
			setScrollTop(false);
			setQuizView(false);
			setShortBy("1");
			setEligible(false);
			setIs0Apr(false);
			const tmp_fi = { ...defaultValueIncentiveFinderFilters() }
			setFilter(tmp_fi);
			setFilterState(tmp_fi);
			tmp_fi['eligible'] = false;
			tmp_fi['short_by'] = '1';
			tmp_fi['is_0_apr'] = false;
			let res = '';
			if (!quizView) {
				res = await getIncentives(tmp_fi, false);
			}
			const v_ids = { 'included_vehicle_ids': res || '' };
			getIncentiveFinderFilters(v_ids, false);

		} else {

			let shrt = shortBy;
			let newL = eligible;
			if (quizView === false) {
				setQuizView(true);
				setShortBy("1");
				setEligible(false);
				newL = false;
				shrt = "1";
				setCompareVehicles([]);
				setCompareVehiclesChecked([]);
			}

			const tmp_filter = JSON.parse(JSON.stringify(quizFilter));
			if (tmp_filter?.budget === '') {
				tmp_filter.budget = 'any';
			}
			if (tmp_filter?.purchase_type === '') {
				tmp_filter.purchase_type = 'any';
			}
			setQuizFilter(tmp_filter);


			// const lastFilter = ArrayAsc(stateIncentives?.quizFilter || {});
			// const currentFilter = ArrayAsc(tmp_filter);
			// if (JSON.stringify(lastFilter) !== JSON.stringify(currentFilter)) {
			setFilter(defaultValueIncentiveFinderFilters());
			setFilterState(defaultValueIncentiveFinderFilters());
			const paramsData = { ...defaultValueIncentiveFinderFilters() };
			paramsData['short_by'] = '1';
			paramsData['eligible'] = false;
			paramsData['is_0_apr'] = false;
			// if (tmp_filter?.purchase_type === 'Lease') {
			setIs0Apr(false);
			setEligible(false);
			setShortBy('1');
			// }
			const res = await getQuizData(paramsData, { ...tmp_filter }, false, false);

			const v_ids = { 'included_vehicle_ids': res || '' };
			getIncentiveFinderFilters(v_ids);
			if (res === null) {
				setDisableFilter(true);
			} else {
				if (disableFilter === true) {
					setDisableFilter(false);
				}
			}

			// }

		}
	}

	async function getQuizData(filte, params = {}, pagination = false, scroll = true) {

		let ids = '';
		setLoading(true);
		if (pagination === false) {
			setOffset(0);
			params['start'] = 0;
		}

		dispatch(setEVIncentiveFinderLoading(true))
		try {
			const response = await apiHelper.postEVIncentiveFinderFinance({ ...filte, ...params });
			var table_data_array = [];
			var exp = {};
			const temp_quiz_fil = JSON.parse(JSON.stringify(quizFilter));
			if (temp_quiz_fil?.budget === '') {
				temp_quiz_fil.budget = 'any';
			}
			if (temp_quiz_fil?.purchase_type === '') {
				temp_quiz_fil.purchase_type = 'any';
			}
			if (response?.data?.status === 1) {

				const convert = CovertEVIncentiveFinderQuizData(response?.data?.body?.data);

				table_data_array = convert?.table_data_array;
				exp = convert?.exp;

				dispatch(updateEVIncentiveFinder({
					loading: false,
					status: true,
					data: table_data_array,
					filter: filte,
					quizFilter: temp_quiz_fil,
					last_updated_date: response?.data?.body?.last_updated_date,
					included_vehicle_ids: response?.data?.body?.included_vehicle_ids,
					quizView: true,
					total_without_limit: response?.data?.body?.total_without_limit,
					msg: "No results found."
				}));

				setLastUpdatedDate(response?.data?.body?.last_updated_date);

				setTimeout(() => {
					setExpandedRows(exp);
				}, 10);

				ids = response?.data?.body?.included_vehicle_ids;

			} else {
				dispatch(updateEVIncentiveFinder({
					loading: false,
					status: true,
					expandedRows: null,
					data: null,
					filter: filter,
					quizFilter: temp_quiz_fil,
					last_updated_date: '',
					included_vehicle_ids: response?.data?.body?.included_vehicle_ids,
					quizView: true,
					msg: process.env.REACT_APP_SOMTHING_WRONG_MSG
				}));
			}

			setTimeout(() => {
				// expandAllRows(params, table_data_array);
				// if (table_data_array.length === 0) {
				// document.getElementById('scroll_here').scrollIntoView({ behavior: 'smooth', block: 'start' });
				// }
				if (scroll === true) {
					scrollTopTable();
				}
			}, 100);

		} catch (error) {
			console.error(error);
			dispatch(updateEVIncentiveFinder({
				loading: false,
				status: false,
				error: error,
				expandedRows: null,
				data: null,
				msg: process.env.REACT_APP_SOMTHING_WRONG_MSG
			}));
		}
		setLoading(false);
		// setdispBlank(false);
		return ids;
	}


	useEffect(() => {
		if (
			stateEVIncentiveFinderFilters.status === false &&
			stateEVIncentiveFinderFilters.error === ""
		) {
			if (stateEVIncentiveFinderFilters.loading === false) {
				getIncentiveFinderFilters();
			}
		}
	});


	const getIncentiveFinderFilters = async (params = {}) => {
		dispatch(setEVIncentiveFinderFiltersLoading(true));
		try {
			const response = await apiHelper.postGetEVIncentiveFinderFilters(params);
			var filter_data = {};
			if (response.status === 200) {
				filter_data = response.data.body.data;
			}
			dispatch(
				updateEVIncentiveFinderFiltersData({
					loading: false,
					status: true,
					data: filter_data,
				})
			);
		} catch (error) {
			console.error(error);
			dispatch(
				updateEVIncentiveFinderFiltersData({
					loading: false,
					status: false,
					error: error,
				})
			);
		}
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * quizFilter?.limit) % Number(stateIncentives?.total_without_limit);

		const tmp_filter = JSON.parse(JSON.stringify(filter));
		tmp_filter['short_by'] = shortBy;
		tmp_filter['eligible'] = eligible;
		quizFilter['start'] = newOffset;
		tmp_filter['is_0_apr'] = is0Apr;
		if (quizView === false) {
			getIncentives(tmp_filter, true);
		} else {
			const res = getQuizData({ ...tmp_filter }, { ...quizFilter }, true, true);
		}

		setOffset(newOffset);
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		// Cleanup function to remove the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>{page_title || 'Siffra'}</title>
				<meta name="description" content={page_meta_description} />
				<link rel="canonical" href={window.location.href}/>
			</Helmet>
			{/* <PageLoaderComponent loading={stateIncentives?.loading} />	 */}
			<Fragment>
				{windowWidth > 1030 ?
					<DesktopView
						reset={async (dd) => {
							setCompareVehicles([]);
							setCompareVehiclesChecked([]);
							// setQuizFilter(defaultValueQuizFilter());
							// setQuizFilterState(defaultValueQuizFilter());
							dispatch(updateEVIncentiveFinder({
								loading: false,
								status: false,
								data: null,
								expandedRows: null
							}));
							setFilter(dd);
							setFilterState(dd);
							setExpandedRows({});
							// setchangeReset(!changeReset);
							const tmp_fi = { ...defaultValueIncentiveFinderFilters() }
							tmp_fi['eligible'] = eligible;
							tmp_fi['is_0_apr'] = is0Apr;
							tmp_fi['short_by'] = shortBy;
							let res = '';
							if (quizView) {
								res = await getQuizData({ ...tmp_fi }, { ...quizFilter }, false, true);
							} else {
								res = await getIncentives(tmp_fi, true);
							}
						}}
						shortBy={shortBy}
						onChangeShort={onChangeShort}
						setFilter={setFilter}
						setFilterState={setFilterState}
						setFirstLoading={setFirstLoading}
						setExpandedRows={setExpandedRows}
						setNotFound={setNotFound}
						incentives={incentives}
						loading={loading}
						notFound={notFound}
						filterState={filterState}
						expandedRows={expandedRows}
						filter={filter}
						onClickCompareVehicle={onClickCompareVehicle}
						handleCompareCheck={handleCompareCheck}
						compareVehicles={compareVehicles}
						compareVehiclesError={compareVehiclesError}
						//new state cehckbox 
						valueCompareCheck={compareVehiclesChecked}
						onChangeCompareCheck={setCompareVehiclesChecked}
						getCompareValue={getCompareValue}
						tooltipText={tooltipText}
						lastUpdatedDate={lastUpdatedDate}
						quizView={quizView}
						quizFilter={quizFilter}
						quizFilterState={quizFilterState}
						onUpdateQuizFilter={onUpdateQuizFilter}
						handleShowMeOptionClick={handleShowMeOptionClick}
						disableFilter={disableFilter}
						msg={stateIncentives?.msg}
						eligible={eligible}
						onChangeEligible={onChangeEligible}
						is0Apr={is0Apr}
						onChangeIs0Apr={onChangeIs0Apr}
						offset={offset}
						setOffset={setOffset}
						handlePageClick={handlePageClick}
						stateIncentives={stateIncentives}
						selectedModelYear={selectedModelYear}
						setSelectedSegments={setSelectedSegments}
						setSelectedModelYear={setSelectedModelYear}
						selectedSegments={selectedSegments}
						stateEVIncentiveFinderFilters={stateEVIncentiveFinderFilters}
					/>
					:
					<MobileView
						reset={async (dd) => {
							setCompareVehicles([]);
							setCompareVehiclesChecked([]);
							// setQuizFilter(defaultValueQuizFilter());
							// setQuizFilterState(defaultValueQuizFilter());
							dispatch(updateEVIncentiveFinder({
								loading: false,
								status: false,
								data: null,
								expandedRows: null
							}));
							setFilter(dd);
							setFilterState(dd);
							setExpandedRows({});
							// setchangeReset(!changeReset);
							const tmp_fi = { ...defaultValueIncentiveFinderFilters() }
							tmp_fi['eligible'] = eligible;
							tmp_fi['short_by'] = shortBy;
							tmp_fi['is_0_apr'] = is0Apr;
							let res = '';
							if (quizView) {
								res = await getQuizData({ ...tmp_fi }, { ...quizFilter }, false, true);
							} else {
								res = await getIncentives(tmp_fi, true);
							}
						}}
						setFilter={setFilter}
						setFilterState={setFilterState}
						setFirstLoading={setFirstLoading}
						setExpandedRows={setExpandedRows}
						setNotFound={setNotFound}
						incentives={incentives}
						loading={loading}
						notFound={notFound}
						filterState={filterState}
						expandedRows={expandedRows}
						filter={filter}
						onClose={onClose}
						handleCompareCheck={handleCompareCheck}
						compareVehicles={compareVehicles}
						getCompareValue={getCompareValue}
						compareVehiclesError={compareVehiclesError}
						onClickCompareVehicle={onClickCompareVehicle}
						tooltipText={tooltipText}
						lastUpdatedDate={lastUpdatedDate}
						msg={stateIncentives?.msg}
						eligible={eligible}
						onChangeEligible={onChangeEligible}
						is0Apr={is0Apr}
						onChangeIs0Apr={onChangeIs0Apr}
						offset={offset}
						setOffset={setOffset}
						shortBy={shortBy}
						onChangeShort={onChangeShort}
						handlePageClick={handlePageClick}
						stateIncentives={stateIncentives}
						quizView={quizView}
						quizFilter={quizFilter}
						quizFilterState={quizFilterState}
						onUpdateQuizFilter={onUpdateQuizFilter}
						handleShowMeOptionClick={handleShowMeOptionClick}
						disableFilter={disableFilter}
						selectedModelYear={selectedModelYear}
						setSelectedSegments={setSelectedSegments}
						setSelectedModelYear={setSelectedModelYear}
						selectedSegments={selectedSegments}
						stateEVIncentiveFinderFilters={stateEVIncentiveFinderFilters}
					/>}
			</Fragment>
		</Fragment>
	);
}

export default withRouter(EVIncentiveFinder);