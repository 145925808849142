import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent"
import { Row, Col, Container, Form, Button, CloseButton } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';

const LPLoader = () => {
    const loopCount = 20;
    return (
        <Fragment>
            <div className={"table-responsive height-100"}>
                <div className="table-striped w-100 table-hover fixed-table">
                    <div className="s-NewTbody">
                        {[...Array(loopCount)].map((x, i) => {
                            return (
                                <Fragment key={i}>
                                    <SkeletonComponent count={1} className="bottom-border-2x lp-skeleton-row" />
                                </Fragment>
                            );
                        })
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(LPLoader);