import axios from 'axios';
export const getLocalStorageData = (key) => {
    return localStorage.getItem(key);
}

export const setLocalStorageData = (key, values) => {
    localStorage.setItem(key, values);
}

export const removeLocalStorageData = (key) => {
    return localStorage.removeItem(key);
}


const sesstionKeyname = "user_data";
const zipcodeKeyname = "currentZipcode";


export const getLoginUserData = () => {
    let dd = getLocalStorageData(sesstionKeyname) || null;

    if (dd === null || dd === undefined || dd === "") {
        return null;
    } else {

        try {
            dd = JSON.parse(dd);
        }
        catch (err) {
            console.log("json parse error ");
            dd = null;
        }

        return dd;
    }
}

export const setLoginUserData = (data) => {
    setLocalStorageData(sesstionKeyname, JSON.stringify(data));
}

export const clearLoginUserData = () => {
    removeLocalStorageData(sesstionKeyname);
}

export const setUserCurrentZipCode = async () => {
    if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;
                try {
                    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                    const data = response.data;
                    if (data.results[0]) {
                        const addressComponents = data.results[0].address_components;
                        const zip = addressComponents.find(component => component.types.includes('postal_code'));
                        if (zip) {
                            localStorage.setItem("currentZipcode", zip.long_name);
                            resolve(zip.long_name);
                        } else { reject("Zip code not found"); }
                    } else {
                        reject("No results found");
                    }
                } catch (error) {
                    reject(error);
                }
            }, error => { console.error('Error getting location', error); reject(error); });
        });
    } else {
        console.error('Geolocation is not supported by this browser.'); throw new Error('Geolocation is not supported by this browser.');
    }
}

export const getUserCurrentZipCode = () => {

    if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.watchPosition(async position => {
                const { latitude, longitude } = position.coords;

                try {
                    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                    const data = response.data;
                    if (data.results[0]) {
                        const addressComponents = data.results[0].address_components;
                        const zip = addressComponents.find(component => component.types.includes('postal_code'));

                        if (zip) {
                            console.log('user zip', zip?.long_name);
                            if (zip?.long_name?.length === 5) {
                                resolve(zip?.long_name);
                                
                            } else {
                                if (zip?.long_name?.length === 6) {
                                    resolve('77077');
                                }
                                resolve('');
                            }
                            // localStorage.setItem("currentZipcode", zip.long_name);
                        } else {
                            resolve('');
                            // reject(""); 
                        }
                    } else {
                        resolve('');
                        // reject("");
                    }
                } catch (error) {
                    resolve('');
                    console.error(error);
                }
            }, error => {
                console.error('Geolocation error', error);
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.error("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.error("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        console.error("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        console.error("An unknown error occurred.");
                        break;
                }
                resolve('');
            }, {
                enableHighAccuracy: false, // Disable high-accuracy mode
                timeout: 10000,
                maximumAge: 0,
            });
        });
    } else {
        console.error('Geolocation is not supported by this browser.');
        return '';
    }
}