import React, { Fragment, useState, useMemo } from "react";
import { Row, Col, Button, Offcanvas, Container, Form } from 'react-bootstrap';
import NewIncentiveFinderByVehicleMobile from './NewIncentiveFinderByVehicleMobile';
import NewIncentiveFinderByVehicleRequirementsMobile from './NewIncentiveFinderByVehicleRequirementsMobile';
import { EVIncentiveFinderFiltersMobile } from "../../components/EVIncentiveFinderFiltersMobile";
import { IncentiveComparePopup } from "../../components/IncentiveComparePopup";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, DateFormatterLastUpdated } from "../../helper/project_helper";
import Pagination from "../../components/Pagination";
import LPLoader from "../../components/SkeletonLoder/LPLoader";

const MobileView = ({
	setFilter,
	setFilterState,
	setFirstLoading,
	setExpandedRows,
	setNotFound,
	incentives,
	loading,
	notFound,
	filterState,
	expandedRows,
	filter,
	reset,
	onClose,
	handleCompareCheck,
	compareVehicles,
	getCompareValue,
	valueCompareCheck,
	onChangeCompareCheck,
	onClickCompareVehicle,
	compareVehiclesError,
	history,
	tooltipText,
	lastUpdatedDate,
	quizFilter,
	onUpdateQuizFilter,
	handleShowMeOptionClick,
	setOffset,
	offset,
	handlePageClick,
	stateIncentives,
	quizView,
	msg,
	quizFilterState,
	shortBy,
	onChangeShort,
	is0Apr,
	onChangeIs0Apr,
	onChangeEligible,
	eligible,
	selectedModelYear,
	setSelectedSegments,
	setSelectedModelYear,
	selectedSegments
}) => {
	
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { stateEVIncentiveFinderFilters } = useSelector((state) => ({
		stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
	}));
	var isLoading = useMemo(() => {
		return stateEVIncentiveFinderFilters.loading || false;
	}, [stateEVIncentiveFinderFilters]);
	
	const filters = stateEVIncentiveFinderFilters?.data;
	return (
		<Fragment>
			<div className={"mobile-view "}>
				<section className="">
					{/* <div className="Incentive-img">
						<div className="pt-3">
							<h1 className="font-white font-w font-px m-0">EV Incentive Finder</h1>
						</div>
					</div> */}
					<div className="px-3 pt-3 banner-toggle-section">
						<p className="font-large font-color font-w text-center mb-1">Welcome! To get started, simply answer the following questions or browse the electric vehicles of your choice below.</p>
						<div className="pt-4">
							<Row className="align-items-center">
								<Col lg={5}>
									<div className="mb-4">
										<p className="font-midium font-w font-color mb-1">What is your approximate monthly budget?</p>
										<div className="option-select">
											<Form.Check
												inline
												checked={quizFilter?.budget === '1'}
												label="Under $500"
												name="budget"
												type="radio"
												className=""
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}
												value={1}
											/>
											<Form.Check
												inline
												value={2}
												checked={quizFilter?.budget === '2'}
												label="$500-$1,000"
												name="budget"
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}

												type="radio"
											/>
											<Form.Check
												inline
												value={3}
												checked={quizFilter?.budget === '3'}
												label="Above $1,000"
												name="budget"
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}

												type="radio"
											/>
											<Form.Check
												inline
												value="any"
												checked={quizFilter?.budget === 'any'}
												onChange={(x) => onUpdateQuizFilter('budget', x.target.value)}
												label="Any"
												name="budget"
												type="radio"
											/>
										</div>
									</div>
								</Col>
								<Col lg={5}>
									<div className="mb-3">
										<p className="font-midium font-w font-color mb-1">How do you want to purchase your vehicle?</p>
										<div className="option-select">
											<Form.Check
												inline
												label="Finance"
												checked={quizFilter?.purchase_type === 'Finance'}
												onChange={(x) => onUpdateQuizFilter('purchase_type', x.target.value)}
												value={"Finance"}
												name="purchase_type"
												type="radio"
												className=""
											/>
											<Form.Check
												inline
												label="Lease"
												checked={quizFilter?.purchase_type === 'Lease'}
												onChange={(x) => onUpdateQuizFilter('purchase_type', x.target.value)}
												value={"Lease"}
												name="purchase_type"
												type="radio"
											/>
											<Form.Check
												inline
												onChange={(x) => onUpdateQuizFilter('purchase_type', x.target.value)}
												label="Any"
												checked={quizFilter?.purchase_type === 'any'}
												value={"any"}
												name="purchase_type"
												type="radio"
											/>
										</div>
									</div>
								</Col>
								<Col lg={2}>
									<div>
										<button className="text-decoration-none font-color font-large options-btn font-wxl green-hover-text" onClick={handleShowMeOptionClick}>Show Me Options</button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
					<div className="table-body-color" style={{ overflow: "visible" }}>
						<div className="col-lg-2 col-md-12 side-relative">
							<Offcanvas show={show} onHide={handleClose} placement="start">
								<Offcanvas.Header closeButton>
								</Offcanvas.Header>
								<Offcanvas.Body className="py-0">
									<EVIncentiveFinderFiltersMobile
										value={filterState}
										onChange={setFilterState}
										onSearch={(dd) => {
											setFilter(dd);
											setFilterState(dd);
											setFirstLoading(false);
											// handleClose(true)
										}}
										reset={reset}
										lastFilter={filter}
										filterState={filterState}
										loading={loading}
										selectedModelYear={selectedModelYear}
										setSelectedSegments={setSelectedSegments}
										setSelectedModelYear={setSelectedModelYear}
										selectedSegments={selectedSegments}
									/>
								</Offcanvas.Body>
							</Offcanvas>
						</div>
						<Col className="table-body-color right-bar">
							<Container fluid className="position-stick" style={{ background: "#fcfcfc" }}>
								<div className="pb-4">
									<p className="py-4 me-3 font-elvn font-wxl font-color">
										Know which vehicle you want? Search for the full list of incentives with <Link className="text-dark btn-incentive-finder p-0" to="/incentivefinder">
											<span className="font-elvn font-wx font-color text-nowrap">Siffra Incentive Finder</span>
										</Link><span className="font-elvn font-wx font-color" style={{ marginLeft: "0px" }}>.</span>
									</p>
									<div className="d-flex justify-content-between">
										<Button className="btn-learn-more size-change" onClick={handleShow}
											disabled={loading || isLoading}
										>Filters</Button>
										{quizView ?
											<select
												className={"form-select w-50 short-select filter_select overflow-hidden border-w " + (isEmpty(shortBy) ? 'blank-select-color' : 'filter_select_bold')}
												value={shortBy || ''}
												id="short-select"
												style={{ height: "45px" }}
												disabled={loading}
												onChange={(x) => onChangeShort(x.target.value)}
											>
												<option value="1">Most Affordable</option>
												<option value="2">Most Sold</option>
												<option value="3">Range</option>
												<option value="4">Base Price</option>
												<option value="5">Potential Savings</option>
											</select> :
											<select
												className={"form-select w-50 short-select filter_select overflow-hidden border-w " + (isEmpty(shortBy) ? 'blank-select-color' : 'filter_select_bold')}
												value={shortBy || ''}
												id="short-select"
												style={{ height: "45px" }}
												disabled={loading}
												onChange={(x) => onChangeShort(x.target.value)}
											>
												<option value="1">Most Sold</option>
												<option value="2">Range</option>
												<option value="3">Base Price</option>
												<option value="4">Purchase Savings</option>
												<option value="5">Lease Savings</option>
											</select>
										}
									</div>
								</div>
							</Container>
							<Container fluid>
								<Row className="mb-3">
									{filters?.is_fed_tex_credit_available === true &&
									<Col className="mb-3" xs={6}>
										<div>
											<Form className="landing-check">
												<Form.Check
													type="switch"
													id="custom-switch"
													// label="Only show me vehicles eligible for Federal Tax Credit"
													label="Federal Tax Credit"
													className="form-check font-mobile toggle-switch"
													onChange={(x) => onChangeEligible(x.target.checked)}
													checked={eligible}
													disabled={isLoading || loading}
												/>
											</Form>
										</div>
									</Col>
									}
									{filters?.zero_apr === true &&
									<Col className={"mb-3"} xs={6}>
										<div>
											<Form className="landing-check">
												<Form.Check
													type="switch"
													id="custom-switch"
													label="0% APR"
													className="form-check d-flex align-items-center toggle-switch"
													onChange={(x) => onChangeIs0Apr(x.target.checked)}
													checked={is0Apr}
													disabled={isLoading || loading}
												/>
											</Form>
										</div>
									</Col>
									}
								</Row>
							</Container>
							<div style={{ overflowX: "hidden" }}>
								{loading ? <LPLoader /> : (
									incentives?.length > 0 ? (
										!quizView ? (
											<NewIncentiveFinderByVehicleMobile
												incentives={incentives}
												loading={loading}
												notFound={notFound}
												tooltipText={tooltipText}
												shortBy={shortBy}
												onSavingClick={(dd) => {
													setExpandedRows(dd);
												}}
												expandedRows={expandedRows}
												handleCompareCheck={handleCompareCheck}
												compareVehicles={compareVehicles}
												getCompareValue={getCompareValue}
												valueCompareCheck={valueCompareCheck}
												onChangeCompareCheck={onChangeCompareCheck}
											/>
										) : (
											<NewIncentiveFinderByVehicleRequirementsMobile
												incentives={incentives}
												loading={loading}
												notFound={notFound}
												tooltipText={tooltipText}
												onSavingClick={(dd) => {
													setExpandedRows(dd);
												}}
												expandedRows={expandedRows}
												handleCompareCheck={handleCompareCheck}
												compareVehicles={compareVehicles}
												getCompareValue={getCompareValue}
												valueCompareCheck={valueCompareCheck}
												onChangeCompareCheck={onChangeCompareCheck}
												quizFilter={quizFilter}
												quizFilterState={quizFilterState}
												shortBy={shortBy}
												onChangeShort={onChangeShort}
												msg={msg}
												is0Apr={is0Apr}
												onChangeEligible={onChangeEligible}
											/>
										)
									) : (
										<div className="bottom-border" style={{ background: '#fff' }}>
											<div className="d-flex align-items-center justify-content-between px-3 py-3">
												<span className="font-large font-w warning-msg-red">{msg}</span>
											</div>
										</div>
									)
								)}
							</div>
							{stateIncentives?.total_without_limit > 10 && quizView &&
								< div className="pagination mt-2">
									<Pagination
										itemsPerPage={10}
										total={stateIncentives?.total_without_limit}
										offset={offset}
										setOffset={setOffset}
										handlePageClick={handlePageClick}
									/>
								</div>
							}
						</Col>
					</div>
					<Container fluid>
						{/* <Col className="col-12 pt-4 text-center text-lg-start">
							{incentives?.length > 0 && !loading ?
								(<p className="text-font font-wxl font-black">* Not all customers qualify for incentives, check eligibility and redemption details.</p>) :
								notFound && !loading ? (<p>No matches found.</p>) :
									(<p></p>)}
							{incentives?.length > 0 && !loading ? <p className="text-font font-wxl font-black">* Check incentive eligibility and redemption details for additional requirements.</p> : ""}
						</Col> */}
						{!isEmpty(lastUpdatedDate) &&
							<Row className="mt-5 pb-2 px-2 last-updated-date">
								<Col>
									<label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(lastUpdatedDate)}</label>
								</Col>
							</Row>
						}
					</Container>
				</section>
				{/* <Drawer
					isVisible={compareVehicles?.length > 0 ? true : false}
					onClose={() => { }}
					className={`drawer ${isOpen ? "open" : ""}`}
				>
					<IncentiveComparePopup
						compareVehicles={compareVehicles}
						handleCompareCheck={handleCompareCheck}
						onClickCompareVehicle={onClickCompareVehicle}
						compareVehiclesError={compareVehiclesError}
					/>
				</Drawer> */}
				<div className={`drawer ${compareVehicles?.length > 0 ? "open" : "close-drawer"}`}>
					<IncentiveComparePopup
						compareVehicles={compareVehicles}
						handleCompareCheck={handleCompareCheck}
						onClickCompareVehicle={onClickCompareVehicle}
						compareVehiclesError={compareVehiclesError}
					/>
				</div>
			</div>
		</Fragment>
	)
};

export default withRouter(MobileView);