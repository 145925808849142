import { Row, Col, Form, Button, CloseButton } from 'react-bootstrap';
import info from '../../assets/img/infoImg.png'
import React, { useState, useMemo, useCallback, Fragment, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field } from 'formik';
import * as apiHelper from "../../helper/api_helper";
import * as helper from "../../helper/sessionStorage";
import { useSelector, useDispatch } from "react-redux";
import { setUserDetailsLoading, updateUserDetails } from "../../store/action";
import { updateAuthPoupState } from "../../store/action";
import { defaultPopUpState, isEmpty } from '../../helper/project_helper';
import PageLoaderComponent from "../PageLoaderComponent";
import { useMask } from '@react-input/mask';

export const SignUp = ({
    showSignIn,
    sendAuthCode,
    closeAllPopUp,
    invalidZipCode,
    authCodeErrorMsg,
    loginEmail,
    setLoginEmail,
    onChangeZip,
    zipCode,
    emailNotExistMessage,
    setAuthCodeErrorMsg,
    setEmailExistMessage
}) => {

    const [phoneNumber, setPhoneNumber] = useState('');

    const inputRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });
    const inputRefZip = useMask({ mask: '_____', replacement: { _: /\d/ } });
    const [zipError, setZipError] = useState(false);

    const dispatch = useDispatch();

    const { stateUserDetail, statePopUpData } = useSelector((state) => ({
        stateUserDetail: state.UserDetails,
        statePopUpData: state.AuthPopupState,
    }));

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
    }, [statePopUpData]);

    useEffect(() => {
        setAuthCodeErrorMsg(false);
    }, []);

    const validationSchema = Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),  // Ensures the field is required
    });

    const onblureZip = (zip) => {
        if (zipCode.length < 5 || zipCode.length > 5) {
            setZipError(true)
        } else {
            setZipError(false)
        }
    }
    return (
        <Fragment>

            <section className='px-5 py-3 banner-popup-wapper signup-width'>
                <PageLoaderComponent loading={stateUserDetail?.loading} />
                <p className='py-2 font-px font-w text-center'>Create New Account</p>
                <Formik
                    initialValues={{ first_name: '', last_name: '', email: loginEmail }}
                    validationSchema={validationSchema}  // Pass the validation schema
                    onSubmit={(values) => {
                        if (!isEmpty(phoneNumber)) {
                            values.phone_number = phoneNumber.replace(/\D/g, '');
                        }

                        if (zipCode.length < 5 || zipCode.length > 5) {
                            setZipError(true)
                        } else {
                            values.zip_code = zipCode
                            values.is_login = "2";
                            sendAuthCode(values);
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <Form className="mt-3" onSubmit={handleSubmit}>
                            <div className='popup-close pt-1'>
                                <svg style={{ cursor: "pointer" }} onClick={closeAllPopUp} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                            </div>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02" className="mb-2">
                                    <Form.Label className="font-midium font-w m-0">Email</Form.Label>
                                    <Field
                                        type="text"
                                        name="email"
                                        onChange={(e) => {
                                            handleChange(e);
                                            setLoginEmail(e.target.value); // Get value on change
                                        }}
                                        className={errors.email && touched.email ? "error-border border-w input-height form-control form-font-set" : "border-w input-height form-control form-font-set"}
                                    />
                                    {/* {emailNotExistMessage && (
                                        <Fragment>
                                            <span className='d-flex mb-3 warning-msg' style={{ justifyContent: "left" }}>{emailNotExistMessage}</span>
                                        </Fragment>
                                    )} */}
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationCustom01" className="mb-2">
                                    <Form.Label className="font-midium font-w m-0">First name</Form.Label>
                                    <Field
                                        type="text"
                                        name="first_name"
                                        className={errors.first_name && touched.first_name ? "error-border border-w input-height form-control form-font-set" : "border-w input-height form-control form-font-set"}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationCustom02" className="mb-2">
                                    <Form.Label className="font-midium font-w m-0">Last name</Form.Label>
                                    <Field
                                        name="last_name"
                                        type="text"
                                        className={errors.last_name && touched.last_name ? "error-border border-w input-height form-control form-font-set" : "border-w input-height form-control form-font-set"}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs="12" controlId="validationCustom02" className="mb-2">
                                    <Form.Label className="font-midium font-w m-0">Zip Code</Form.Label>
                                    <input
                                        name="zip_code"
                                        onChange={(x) => onChangeZip(x.target.value)}
                                        ref={inputRefZip}
                                        value={zipCode}
                                        type="text"
                                        className={zipError === false ? "border-w input-height form-control form-font-set" : "error-border border-w input-height form-control form-font-set"}
                                        // onBlur={onblureZip}
                                    />
                                    {invalidZipCode &&
                                        <span className='warning-msg-red' style={{ marginLeft: "1%" }}>{invalidZipCode}</span>}
                                </Form.Group>
                                <Form.Group as={Col} xs="12" controlId="validationCustom02" className="mb-2">
                                    <Form.Label className="font-midium font-w m-0">Phone Number</Form.Label>
                                    <input
                                        onChange={(x) => setPhoneNumber(x.target.value)}
                                        ref={inputRef}
                                        name="phone_number"
                                        type="text"
                                        value={phoneNumber}
                                        className="border-w input-height form-control form-font-set"
                                    />
                                </Form.Group>
                            </Row>
                            {authCodeErrorMsg && (
                                <Fragment>
                                    <span className='d-flex mb-3 warning-msg-red' style={{ justifyContent: "center" }}>{authCodeErrorMsg}</span>
                                </Fragment>
                            )}
                            <div className="d-flex flex-column mb-3">
                                <Button type="submit"
                                    disabled={isEmpty(values?.first_name) || isEmpty(values?.last_name) || isEmpty(values?.email) || isEmpty(zipCode) || (!isEmpty(zipCode) && zipCode.length < 5) || errors.email || invalidZipCode}
                                    className="btn-learn-more padding-set"
                                >Sign Up</Button>
                            </div>
                            <p className='font-small font-wxl mb-4 text-center'>By clicking Sign Up, I understand and agree to Siffra's privacy policy, creating a Siffra Account and authorize Siffra to contact me for account management purposes via the contact information I provide. </p>
                            <div className='text-center pb-3'>
                                <Button className='text-decoration-none font-midium text-dark button-set btn-link green-hover-text' onClick={showSignIn}>Already have an account?</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </section>
        </Fragment>
    )
}