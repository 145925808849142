import React, { useState, useMemo, useCallback, Fragment, useEffect } from "react";
import IncentiveDetailSearchDesktop from './IncentiveDetailSearchDesktop'
import IncentiveDetailSearchMobile from './IncentiveDetailSearchMobile'
import { useAuth } from "../../components/hooks/useAuth";
import { useSelector, useDispatch } from "react-redux";
import { defaultPopUpState, isEmpty, isValidateZipcode, createSeoFriendlyUrl, toTitleCase } from '../../helper/project_helper';
import { setLocalStorageData, getLocalStorageData, removeLocalStorageData } from '../../helper/sessionStorage';
import { clearLoginUserData, getUserCurrentZipCode } from "../../helper/sessionStorage";
import * as apiHelper from "../../helper/api_helper";
import { withRouter, useParams, useLocation } from "react-router-dom";
import {
    setIncentiveDetailSearchFiltersLoading,
    updateIncentiveDetailSearchFiltersData,
    setIncentiveDetailSearchLoading,
    updateIncentiveDetailSearchData,
    updateAuthPoupState,
    updateUserDetails
} from "../../store/action";
import PageLoaderComponent from "../../components/PageLoaderComponent";
import * as helper from "../../helper/sessionStorage";
import useIsMobile from '../../components/hooks/useIsMobile';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet-async';
import IDSLoader from "../../components/SkeletonLoder/IDSLoader";
import IDSLoaderMobile from "../../components/SkeletonLoder/IDSLoaderMobile";

export const defaultValueIncentiveDetailSearchFilter = (userData, default_model_year = "", reset = false, permData = false, default_zip_code = '', currentZipCode = '') => {

    let zip_code = '';
    // console.log(default_zip_code);

    if (!isEmpty(default_zip_code)) {
        zip_code = default_zip_code;
    }

    if (!isEmpty(currentZipCode) && isEmpty(zip_code)) {
        zip_code = currentZipCode;
    }
    // console.log('currentZipCode',currentZipCode);

    if (!isEmpty(userData?.zip_code) && isEmpty(zip_code)) {
        zip_code = userData?.zip_code
    }

    if (reset === true) {
        zip_code = '';
        // removeLocalStorageData('user_typed_zip_code');
    }

    return {
        zip_code: zip_code,
        make: !isEmpty(permData?.make) ? permData?.make : '',
        model: !isEmpty(permData?.model) ? permData?.model : '',
        trim: !isEmpty(permData?.trim) ? permData?.trim : '',
        model_year: isEmpty(permData?.model_year) ? permData?.model_year : default_model_year,
        vehicle_condition: "New",
        purchase_type: !isEmpty(permData?.purchase_type) ? permData?.purchase_type.replace(/^./, char => char.toUpperCase()) : 'Cash'
    };
};
export const defaultBlankFilterError = (filter = {}) => {
    return {
        make: isEmpty(filter?.make) && filter?.make !== undefined ? true : false,
        model: isEmpty(filter?.model) && filter?.model !== undefined ? true : false,
        trim: isEmpty(filter?.trim) && filter?.trim !== undefined ? true : false,
        model_year: isEmpty(filter?.model_year) && filter?.model_year !== undefined ? true : false,
    };
};

const IncentiveDetailSearch = ({
    history
}) => {
    let { vehicle_id_hash } = useParams();
    const location = useLocation();
    const [purchase_type, setpurchase_type] = useState('');
    const dispatch = useDispatch();
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [model_year, setmodelYear] = useState('');
    const [success, setSuccess] = useState(false);
    const [toTop, setToTop] = useState(true);
    const [isLogin, userData] = useAuth();
    const [currentZipCode, setCurrentZipCode] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const p_type = queryParams.get('type');
        if (!isEmpty(p_type)) {
            setpurchase_type(toTitleCase(p_type));
        }

    }, [location]);

    useEffect(() => {
        // console.log("call scroll to top")
        window.scrollTo(0, 0); // Add a small delay
    }, [toTop]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    async function GetVehicleData() {
        dispatch(setIncentiveDetailSearchLoading(true));
        try {
            const params = {}
            params.vehicle_id_hash = vehicle_id_hash;
            const buyResponse = await apiHelper.postGetVehicle(params);
            if (buyResponse.status === 200) {
                if (buyResponse.data.status === 1) {
                    const vehicleData = buyResponse.data.body;
                    setModel(vehicleData?.model);
                    setTrim(vehicleData?.trim);
                    setmodelYear(vehicleData?.model_year);
                    setMake(vehicleData?.make);
                    dispatch(setIncentiveDetailSearchLoading(false));
                } else if (buyResponse.data.status === 3) {
                    history.push("/", true)
                    dispatch(setIncentiveDetailSearchLoading(false));
                }
            }

        } catch (error) {
            console.error(error);
            setSuccess(true);
            dispatch(updateIncentiveDetailSearchFiltersData({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    useEffect(() => {
        const asyncFunction = async () => {
            if (!isEmpty(vehicle_id_hash)) {
                await GetVehicleData();
            }
            // setSuccess(true);
            //  else {
            //     // comment when want to start current zipcode
            //     setSuccess(true);
            // }
            // removeLocalStorageData('user_typed_zip_code');

            if (!isLogin && isEmpty(getLocalStorageData('user_typed_zip_code'))) {
                const zip = await getUserCurrentZipCode();
                if (!isEmpty(zip)) {
                    setCurrentZipCode(zip);
                }
                setSuccess(true);
            } else {
                setSuccess(true);
            }
        }
        asyncFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle_id_hash]);

    const { stateIncentiveDetailSearch } = useSelector((state) => ({
        stateIncentiveDetailSearch: state.IncentiveDetailSearch,
    }));

    return (
        <Fragment>
            {/* <PageLoaderComponent loading={stateIncentiveDetailSearch?.loading} /> */}
            {success ?
                <IncentiveDetailSearchChild
                    history={history}
                    purchase_type={purchase_type}
                    make={make}
                    model={model}
                    model_year={model_year}
                    trim={trim}
                    currentZipCode={currentZipCode}
                /> :
                <div className="blank-page">
                    {windowWidth > 1063 ?
                        <IDSLoader
                            className={'max-h'}
                        /> : <IDSLoaderMobile
                            hideFilter={true}
                            className={'max-h'}
                        />}
                </div>
            }
        </Fragment>
    );

}
const IncentiveDetailSearchChild = ({
    history,
    make,
    model,
    model_year,
    trim,
    purchase_type,
    currentZipCode
}) => {
    const permData = {
        purchase_type: purchase_type,
        make: make,
        model: model,
        model_year: model_year,
        trim: trim
    };
    const dispatch = useDispatch();
    const [isLogin, userData] = useAuth();
    const isMobile = useIsMobile();
    const location = useLocation();


    const { stateIncentiveDetailSearchFilters } = useSelector((state) => ({
        stateIncentiveDetailSearchFilters: state.IncentiveDetailSearchFilters,
    }));
    const { stateIncentiveDetailSearch } = useSelector((state) => ({
        stateIncentiveDetailSearch: state.IncentiveDetailSearch,
    }));

    const [expandedRows, setExpandedRows] = useState({});
    const [firstFind, setFirstFind] = useState(true);
    const [firstFilter, setFirstFilter] = useState(isEmpty(make) ? false : true);
    const [firstSearch, setFirstSearch] = useState(true);
    const [onReste, setOnReset] = useState(false);

    const [filterState, setFilterState] = useState(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData, getLocalStorageData('user_typed_zip_code'), currentZipCode));
    const [filter, setFilter] = useState(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData, getLocalStorageData('user_typed_zip_code'), currentZipCode));

    const [zipCodeError, setZipCodeError] = useState(false);
    const [blankFilterError, setBlankFilterError] = useState(defaultBlankFilterError(filter));
    const [lastValidatedZipcode, setlastValidatedZipcode] = useState("");
    const [currentFilterChangeName, setCurrentFilterChangeName] = useState("");
    const [show, setShow] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState
    }));


    const page_title = useMemo(() => {
        if (stateIncentiveDetailSearchFilters?.data !== false) {
            if (stateIncentiveDetailSearchFilters?.data?.page_title !== null) {
                return stateIncentiveDetailSearchFilters?.data?.page_title;
            }
        }
        return null;
    }, [stateIncentiveDetailSearchFilters]);

    const page_meta_description = useMemo(() => {
        if (stateIncentiveDetailSearchFilters?.data !== false) {
            if (stateIncentiveDetailSearchFilters?.data?.page_meta_description !== null) {
                return stateIncentiveDetailSearchFilters?.data?.page_meta_description;
            }
        }
        return null;
    }, [stateIncentiveDetailSearchFilters]);

    useEffect(() => {
        if (isLogin) {
            setFilterState(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData, getLocalStorageData('user_typed_zip_code')));
            setFilter(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData, getLocalStorageData('user_typed_zip_code')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    useEffect(() => {
        if (!isEmpty(make) && firstSearch === true && !isEmpty(String(filterState?.model_year))) {
            setTimeout(() => {
                onSearchClick();
            }, 100);
            setFirstSearch(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState?.model_year]);

    const filters = useMemo(() => {
        if (stateIncentiveDetailSearchFilters.data !== false) {
            return stateIncentiveDetailSearchFilters.data;
        }
        return null;
    }, [stateIncentiveDetailSearchFilters]);

    const incentiveData = useMemo(() => {
        if (stateIncentiveDetailSearch.data !== false) {
            return stateIncentiveDetailSearch.data;
        }
        return null;
    }, [stateIncentiveDetailSearch]);

    const loading = useMemo(() => {
        return stateIncentiveDetailSearch?.loading;
    }, [stateIncentiveDetailSearch]);

    useEffect(() => {

        if (
            stateIncentiveDetailSearch.loading === false &&
            stateIncentiveDetailSearch.error === ""
        ) {
            if (!isMobile && !zipCodeError) {
                onSearch(filterState);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState, filters, zipCodeError]);

    useEffect(() => {

        if (
            stateIncentiveDetailSearchFilters.loading === false &&
            stateIncentiveDetailSearchFilters.error === ""
        ) {

            if (currentFilterChangeName !== "zip_code" && currentFilterChangeName !== "purchase_type" && currentFilterChangeName !== "model_year") {
                getIncentiveFinderFilters();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState?.make, filterState?.model, filterState?.trim, onReste]);

    useEffect(() => {

        if (
            stateIncentiveDetailSearchFilters.loading === false &&
            stateIncentiveDetailSearchFilters.error === ""
        ) {

            if (filters?.model?.length === 1) {
                
                if (filters?.model?.[0] !== filterState?.model && !isEmpty(filters?.model?.[0]) && firstFilter === false) {
                    console.log('sss',filters?.model?.[0], filterState?.model);
                    setTimeout(() => {
                        
                        onChangeFilter("model", filters?.model?.[0], true);
                    }, 100);
                }
            }
            if (filters?.trim?.length === 1) {
                if (filters?.trim?.[0] !== filterState?.trim && !isEmpty(filters?.trim?.[0]) && firstFilter === false && filters?.is_blank_trim_available !== true) {
                    setTimeout(() => {
                        onChangeFilter("trim", filters?.trim?.[0], true);
                    }, 100);
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);



    const getIncentiveFinderFilters = async () => {
        dispatch(setIncentiveDetailSearchFiltersLoading(true));
        try {
            const response = await apiHelper.postIncentiveDetailSearchFilter(filterState);
            var filter_data = {};
            if (response.status === 200) {
                if (response?.data?.status === 1) {
                    filter_data = response?.data?.body;
                    dispatch(
                        updateIncentiveDetailSearchFiltersData({
                            loading: false,
                            status: true,
                            data: filter_data,
                        })
                    );

                    if (filterState?.make !== filter_data?.selected_make && !isEmpty(filterState?.model) && !isEmpty(filter_data?.selected_make)) {
                        onChangeFilter("make", filter_data?.selected_make, true);
                    }

                    setTimeout(() => {

                        if (filter_data?.model_year?.length === 1) {
                            if (String(filter_data?.model_year?.[0]) !== String(filterState?.model_year) && !isEmpty(String(filter_data?.model_year?.[0])) && firstFilter === false) {
                                onChangeFilter("model_year", String(filter_data?.model_year?.[0]), true);
                            }
                        }
                    }, 100);
                    setTimeout(() => {
                        if (String(filterState?.model_year) !== String(filter_data?.model_year?.[0])) {
                            if (firstFilter === true && !isEmpty(make)) {
                                setFirstFilter(false);
                                onChangeFilter("model_year", String(permData?.model_year));
                            }
                        }
                    }, 1);
                } else if (response.data.status === 2) {
                    dispatch(setIncentiveDetailSearchFiltersLoading(false));
                    askLogin();
                } else if (response.data.status === 0) {
                    dispatch(
                        updateIncentiveDetailSearchFiltersData({
                            loading: false,
                            status: false,
                            error: response.data,
                        })
                    );
                }
            }
        } catch (error) {
            console.error(error);
            dispatch(
                updateIncentiveDetailSearchFiltersData({
                    loading: false,
                    status: false,
                    error: error,
                })
            );
        }
    };

    const handleHideShowClick = (rowId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData]);

    const onLogin = (x) => {

    }

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    const onChangeFilter = async (name, value1, setModel = false) => {

        let value = { ...filterState }
        let value_ = { ...value };
        value_[name] = value1;

        if (name === "make" && setModel === false) {
            value_.model = "";
            value_.trim = "";
            value_.model_year = "";
        }
        //  else if (name === "make" && setModel === true) {
        //     value_.trim = "";
        // }
        if (name === "model" && setModel === false) {
            value_.trim = "";
            value_.model_year = "";
        }
        if (name === "trim" && setModel === false) {
            value_.model_year = "";
        }
        setCurrentFilterChangeName(name)
        setFilterState(value_);


        if (name === 'zip_code') {
            if (value1?.length === 5) {
                dispatch(setIncentiveDetailSearchFiltersLoading(true));
                const validZipCode = await isValidateZipcode(value1);
                dispatch(setIncentiveDetailSearchFiltersLoading(false));
                if (validZipCode === false) {
                    setZipCodeError(true);
                    setTimeout(() => {

                        const input = document.querySelector('.zip-focus');

                        // Check if the element exists and has the class
                        if (input && input.classList.contains('zip-focus')) {
                            input.focus(); // Give focus if the class is present
                        }
                    }, 100);
                } else {
                    setZipCodeError(false);
                }
            }
        }

        if (incentiveData !== false && firstFind === false) {
            dispatch(
                updateIncentiveDetailSearchData({
                    loading: false,
                    status: true,
                    data: false
                })
            );
        }


    };

    const onSearchClick = () => {

        let tempErrorState = { ...defaultBlankFilterError() };
        if (filterState?.make?.trim() === "") {
            tempErrorState = { ...tempErrorState, make: true }
        }
        if (filterState?.model?.trim() === "") {
            tempErrorState = { ...tempErrorState, model: true }
        }
        if (filterState?.model_year?.trim() === "") {
            tempErrorState = { ...tempErrorState, model_year: true }
        }

        if (filterState?.zip_code?.length !== 5) {
            tempErrorState = { ...tempErrorState, zip_code: true }
        }

        if (filters?.trim?.length > 0) {

            if (filterState?.trim?.trim() === "") {
                if (filters?.is_blank_trim_available !== true) {
                    tempErrorState = { ...tempErrorState, trim: true }
                }
            }
        }
        // console.log("ffff",filters);


        setBlankFilterError(tempErrorState);
        const hasTrueValue = Object.values(tempErrorState).some(value => value === true);

        if (!hasTrueValue && !zipCodeError) {
            setFilter(filterState);
        }
    };
    const onSearch = async (setFilterData = false) => {
        if (!setFilterData) {
            setFilterData = JSON.parse(JSON.stringify(filterState))
        }
        let tempErrorState = { ...defaultBlankFilterError() };
        if (setFilterData?.make?.trim() === "") {
            tempErrorState = { ...tempErrorState, make: true }
        }
        if (setFilterData?.model?.trim() === "") {
            tempErrorState = { ...tempErrorState, model: true }
        }
        if (String(setFilterData?.model_year)?.trim() === "") {
            tempErrorState = { ...tempErrorState, model_year: true }
        }
        console.log(setFilterData?.zip_code?.length);

        if (setFilterData?.zip_code?.length !== 5) {
            tempErrorState = { ...tempErrorState, zip_code: true }
        }

        if (filters?.trim?.length > 0) {

            if (setFilterData?.trim?.trim() === "") {

                if (filters?.is_blank_trim_available === true && filters?.available_blank_trim_model_years?.length > 0 && !isEmpty(setFilterData?.model_year) && filters?.available_blank_trim_model_years?.includes(setFilterData?.model_year) !== true) {
                    tempErrorState = { ...tempErrorState, trim: true }
                } else if (filters?.is_blank_trim_available === false) {
                    tempErrorState = { ...tempErrorState, trim: true }
                }
            }
        }

        if (setFilterData?.zip_code?.length === 5) {
            dispatch(setIncentiveDetailSearchFiltersLoading(true));
            const validZipCode = await isValidateZipcode(setFilterData?.zip_code);
            dispatch(setIncentiveDetailSearchFiltersLoading(false));
            if (validZipCode === false) {
                tempErrorState = { ...tempErrorState, zip_code: true }
                setZipCodeError(true);
                setTimeout(() => {

                    const input = document.querySelector('.zip-focus');

                    // Check if the element exists and has the class
                    if (input && input.classList.contains('zip-focus')) {
                        input.focus(); // Give focus if the class is present
                    }
                }, 100);
            } else {
                setZipCodeError(false);
            }
        }


        setBlankFilterError(tempErrorState);
        const hasTrueValue = Object.values(tempErrorState).some(value => value === true);
        if (!hasTrueValue && !zipCodeError) {
            setFilter(setFilterData);
        }
    };

    const onResetClick = () => {
        // getIncentiveFinderFilters();
        dispatch(
            updateIncentiveDetailSearchData({
                loading: false,
                status: true,
                data: false,
                filter: false
            })
        );
        // setTimeout(() => {
        setFilter(defaultValueIncentiveDetailSearchFilter(userData, "", true));
        setFilterState(defaultValueIncentiveDetailSearchFilter(userData, "", true));
        setBlankFilterError(defaultBlankFilterError(defaultValueIncentiveDetailSearchFilter(userData, "", true)));
        setOnReset(!onReste);
        setCurrentFilterChangeName("reset");
        setZipCodeError(false);
        setTimeout(() => {
            window.scrollTo(0, 0); // Add a small delay
        }, 100);
        // history.push('/incentivefinder/', null, { shallow: true });
        // onChangeFilter("model_year", String(stateIncentiveDetailSearchFilters?.data?.model_year?.[0]));
        // }, 100);
    }

    //search section

    const onBlurZipCode = () => {
        if (filterState?.zip_code?.length !== 5) {
            setZipCodeError(true);
        }
    }

    async function getIncentiveDetailSearchData(params = {}) {

        // if (
        //     stateIncentiveDetailSearch.filter !== undefined &&
        //     stateIncentiveDetailSearch.filter !== false
        // ) {
        //     const lastFilter = ArrayAsc(stateIncentiveDetailSearch.filter);
        //     const currentFilter = ArrayAsc(filterState);
        //     if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
        //         return false;
        //     }
        // }

        dispatch(setIncentiveDetailSearchLoading(true));
        setExpandedRows({});
        try {
            const response = await apiHelper.postIncentiveDetailSearch(params);
            var res_data = null;

            if (response.data.status === 1) {
                res_data = response.data.body;
                handleClose();
                dispatch(
                    updateIncentiveDetailSearchData({
                        loading: false,
                        status: true,
                        data: res_data,
                        filter: filter,
                    })
                );
                if (firstFind === true) {
                    setFirstFind(false);
                }
                if (res_data?.user_attributes !== undefined && res_data?.user_attributes !== null && res_data?.user_attributes !== undefined) {
                    let tempUserData = { ...userData };
                    tempUserData.user_attributes = res_data?.user_attributes;
                    helper.setLoginUserData(tempUserData)
                }
                setLocalStorageData('user_typed_zip_code', filter?.zip_code);
                let writePath = '/incentivefinder/' + CryptoJS.MD5(res_data?.vehicle_data?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(res_data?.vehicle_data?.make) + "/" + createSeoFriendlyUrl(res_data?.vehicle_data?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(res_data?.vehicle_data?.trim)

                if (location.pathname.includes(writePath) !== true && writePath != '') {
                    writePath = writePath + "?type=" + filter?.purchase_type?.toLowerCase();
                    history.push(writePath);
                }

            } else if (response?.data?.status === 2) {
                askLogin();
            } else {
                dispatch(
                    updateIncentiveDetailSearchData({
                        loading: false,
                        status: true,
                        data: false,
                        filter: false
                    })
                );
            }
        } catch (error) {
            console.error(error);
            dispatch(
                updateIncentiveDetailSearchData({
                    loading: false,
                    status: false,
                    error: error,
                })
            );
        }
    }

    useEffect(() => {
        if (
            stateIncentiveDetailSearch.loading === false &&
            stateIncentiveDetailSearch.error === ""
        ) {
            getIncentiveDetailSearchData(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    // if (first === true) {
    //     if (userData === false || userData === undefined) {
    //         askLogin();
    //     } else if (isEmpty(make)) {
    //         dispatch(
    //             updateIncentiveDetailSearchData({
    //                 loading: false,
    //                 status: true,
    //                 data: false,
    //                 filter: false,
    //             })
    //         );
    //     }
    //     setFirst(false);
    // }
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{page_title}</title>
                <meta name="description" content={page_meta_description} />
                <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}${location.pathname}`} />
            </Helmet>
            {isEmpty(stateIncentiveDetailSearch?.error?.msg) && (
                <Fragment>
                    {windowWidth > 1063 ?
                        <IncentiveDetailSearchDesktop
                            expandedRows={expandedRows}
                            handleHideShowClick={handleHideShowClick}
                            filterList={stateIncentiveDetailSearchFilters?.data}
                            filterloading={stateIncentiveDetailSearchFilters?.loading}
                            onChangeFilter={onChangeFilter}
                            value={filterState}
                            onSearchClick={onSearchClick}
                            onResetClick={onResetClick}
                            zipCodeError={zipCodeError}
                            blankFilterError={blankFilterError}
                            filter={filter}
                            incentiveData={incentiveData}
                            tooltipText={stateIncentiveDetailSearchFilters?.data?.tooltips}
                            loading={stateIncentiveDetailSearch?.loading}
                            onBlurZipCode={onBlurZipCode}
                        /> :
                        <IncentiveDetailSearchMobile
                            loading={stateIncentiveDetailSearchFilters?.loading}
                            expandedRows={expandedRows}
                            handleHideShowClick={handleHideShowClick}
                            filterList={stateIncentiveDetailSearchFilters?.data}
                            filterloading={stateIncentiveDetailSearchFilters?.loading}
                            onChangeFilter={onChangeFilter}
                            value={filterState}
                            filter={filter}
                            onSearchClick={onSearchClick}
                            onResetClick={onResetClick}
                            zipCodeError={zipCodeError}
                            blankFilterError={blankFilterError}
                            incentiveData={incentiveData}
                            tooltipText={stateIncentiveDetailSearchFilters?.data?.tooltips}
                            show={show}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            onBlurZipCode={onBlurZipCode}
                        />}
                </Fragment>
            )}
        </Fragment>
    )
}

export default withRouter(IncentiveDetailSearch);