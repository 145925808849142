import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useRef, useEffect } from 'react';
import { DateFormatterExpirationDate, isEmpty } from "../helper/project_helper";
import { AuthButton } from "./AuthButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


export const IncentiveDetailPopup = ({
    specialIncentivesKey,
    handleHideShowClick,
    IncentiveObject,
    tooltipText
}) => {
    const divincRef = useRef(null);

    const handleClickOutside = (event) => {
        // console.log("hello");
        if (divincRef.current && !divincRef.current.contains(event.target) && !event.target.closest(`.all-incentive`) && !event.target.closest(`.detail-popup`) && !event.target.closest(`.login-back`) && !event.target.closest(`.login-popups`)) {
            handleHideShowClick(specialIncentivesKey);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        // Clean up the event listener when the component unmounts 
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <section className='banner-detail-popup' ref={divincRef}>
            <Row>
                <Col lg={12}>
                    <div className='popup-close pt-1'>

                    </div>
                    <div className='text-end close-popup'>
                        <svg className='me-1 mb-1' onClick={() => handleHideShowClick(specialIncentivesKey)} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className='px-3 pt-3 pb-2'>
                        <div className='justify-content-between flex-wrap pb-3 d-flex'>
                            <h5 className='font-large font-color font-px font-w replace-hyphen'>{IncentiveObject?.incentive_name}</h5>
                            <h5 className='font-large font-wx mb-0 replace-hyphen'>{IncentiveObject?.incentive_amount}</h5>
                        </div>
                        <div className='pb-3'>
                            <p className='me-2 font-midium font-w'>Provider: </p>
                            <p className='font-midium font-w4'>{IncentiveObject?.provider_name}</p>
                        </div>
                        <div className='pb-3'>
                            <p className='me-2 font-midium font-w'>Type: </p>
                            <p className='font-midium font-w4'>{IncentiveObject?.incentive_type}</p>
                        </div>
                        {!isEmpty(IncentiveObject?.end_date) &&
                            <div className='pb-3'>
                                <p className='me-2 font-midium font-w mb-1'>Expiration Date: </p>
                                <p className='font-midium font-w4'>{DateFormatterExpirationDate(IncentiveObject?.end_date)}</p>
                            </div>
                        }
                        {!isEmpty(IncentiveObject?.include_state) &&
                            <div className='pb-3'>
                                <p className='me-2 font-midium font-w mb-1'>Available in:</p>
                                <p className='font-midium font-w4'>{IncentiveObject?.include_state.replace(/,/g, ', ')}</p>
                            </div>
                        }
                        {!isEmpty(IncentiveObject?.exclude_state) &&
                            <div className='pb-3'>
                                <p className='me-2 font-midium font-w mb-1'>Not Available in:</p>
                                <p className='font-midium font-w4'>{IncentiveObject?.exclude_state.replace(/,/g, ', ')}</p>
                            </div>
                        }
                        {!isEmpty(IncentiveObject?.eligibility_requirement_text) &&
                            <div className='pb-3'>
                                <p className='me-2 font-midium font-w mb-1'>Eligibility Requirements: </p>
                                <p className='font-midium font-w4 replace-hyphen'>{IncentiveObject?.eligibility_requirement_text}</p>
                            </div>
                        }
                        <div className='pb-3'>
                            <p className='me-2 font-midium font-w'>Combinable?


                                {!isEmpty(tooltipText?.[34]) &&
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={['hover', 'click']}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip className="custom-tooltip ">
                                                <div className="tc-text">
                                                    {tooltipText?.[34]}
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <svg className="ms-1 me-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z" fill="#21272A" />
                                            <path d="M6.69746 4.941L4.97996 5.15625L4.91846 5.44125L5.25596 5.5035C5.47646 5.556 5.51996 5.6355 5.47196 5.85525L4.91846 8.45625C4.77296 9.129 4.99721 9.4455 5.52446 9.4455C5.93321 9.4455 6.40796 9.2565 6.62321 8.997L6.68921 8.685C6.53921 8.817 6.32021 8.8695 6.17471 8.8695C5.96846 8.8695 5.89346 8.72475 5.94671 8.46975L6.69746 4.941ZM6.74996 3.375C6.74996 3.57391 6.67095 3.76468 6.53029 3.90533C6.38964 4.04598 6.19888 4.125 5.99996 4.125C5.80105 4.125 5.61029 4.04598 5.46963 3.90533C5.32898 3.76468 5.24996 3.57391 5.24996 3.375C5.24996 3.17609 5.32898 2.98532 5.46963 2.84467C5.61029 2.70402 5.80105 2.625 5.99996 2.625C6.19888 2.625 6.38964 2.70402 6.53029 2.84467C6.67095 2.98532 6.74996 3.17609 6.74996 3.375Z" fill="#21272A" />
                                        </svg>
                                    </OverlayTrigger>}
                            </p>
                            <p className='font-midium font-w4'>{IncentiveObject?.is_combinable === true ? "Yes" : "No"}</p>
                        </div>

                        {IncentiveObject?.exclusion_text != null &&
                            <Fragment>
                                <div className='pb-3'>
                                    <p className='me-2 font-midium font-w mb-1'>Exclusions: </p>
                                    <p className='font-midium font-w4 replace-hyphen'>{IncentiveObject?.exclusion_text}</p>
                                </div>
                            </Fragment>
                        }
                        {IncentiveObject?.redemption_text != null &&
                            <Fragment>
                                <div className={(!isEmpty(IncentiveObject?.redemption_link) || !isEmpty(IncentiveObject?.incentive_link)) && 'pb-4'}>
                                    <p className='me-2 font-midium font-w mb-1'>How to Claim? </p>
                                    <p className='font-midium font-w4 replace-hyphen'>{IncentiveObject?.redemption_text}</p>
                                </div>
                            </Fragment>
                        }

                        {/* {!isEmpty(IncentiveObject?.redemption_link) &&
                            <div className="pb-3 pt-1">
                                <a className="btn-learn-more text-center" target="_blank" href={IncentiveObject?.redemption_link} rel="noopener noreferrer" >Learn more
                                    <FontAwesomeIcon icon={faUpRightFromSquare}
                                        className="ms-3"
                                    />
                                </a>
                            </div>
                        } */}
                        {!isEmpty(IncentiveObject?.incentive_link) &&
                            <div className="pb-3 pt-1">
                                <AuthButton className="btn-learn-more text-center mt-3" onLogin={(s) => {

                                    const link = document.getElementById('incentive_link');
                                    if (link) {
                                        link.setAttribute('href', IncentiveObject?.incentive_link);
                                        link.click();
                                    }
                                }}>
                                    Learn more
                                    <FontAwesomeIcon icon={faUpRightFromSquare}
                                        className="ms-3"
                                    /></AuthButton>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </section>
    )
}